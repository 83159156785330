/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";

// Soft UI Dashboard PRO React context
import { setLayout, useSoftUIController } from "../../../softcontext";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, miniSidenavInner } = controller;
  const { pathname, search } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  // Main sidenav hidden routes
  const hiddenSidenavRoutes = [
    '/login',
    '/signup',
    '/forgot-password',
    '/deepdive/:id',
    '/midpoint/:id',
    '/deepdive/:journey_id/:id',
    '/midpoint/:journey_id/:id',
    '/deepdive',
    '/midpoint',
    '/ad/:journey_id',
    '/program/:journey_id',
    '/strategy/:journey_id',
    '/program/:journey_id?token=:token',
    '/strategy/:journey_id?token=:token',
    '/ad/:journey_id?token=:token',
    '/insights/:journey_id',
    '/insights/:journey_id?token=:token',
  ];

  // Routes where inner sidenav should be hidden
  const hiddenInnerSidenavRoutes = [
    '/ad/:journey_id?token=:token',
    '/ad/:journey_id',
    '/journey/advertise',
    '/',
    '/portfolio/overview',
    '/controlpanel'
  ];

  const shouldShowSidenav = !hiddenSidenavRoutes.some(route => {
    const [routePath, routeQuery] = route.split('?');
    const pathPattern = routePath
      .replace(/:[^\s/]+/g, '[^\\s/]+')
      .replace(/\//g, '\\/');
    const queryPattern = routeQuery 
      ? `\\?${routeQuery.replace(/:[^\s/=&]+/g, '[^\\s/=&]+')}` 
      : '';
    const regex = new RegExp(`^${pathPattern}${queryPattern ? `(${queryPattern})?` : ''}$`);
    const fullPath = pathname + (search || '');
    return regex.test(fullPath);
  });

  const shouldHideInnerSidenav = hiddenInnerSidenavRoutes.some(route => {
    const [routePath, routeQuery] = route.split('?');
    const pathPattern = routePath
      .replace(/:[^\s/]+/g, '[^\\s/]+')
      .replace(/\//g, '\\/');
    const queryPattern = routeQuery 
      ? `\\?${routeQuery.replace(/:[^\s/=&]+/g, '[^\\s/=&]+')}` 
      : '';
    const regex = new RegExp(`^${pathPattern}${queryPattern ? `(${queryPattern})?` : ''}$`);
    const fullPath = pathname + (search || '');
    return regex.test(fullPath);
  });
/* console.log('shouldHideInnerSidenav', shouldHideInnerSidenav);
console.log('shouldShowSidenav', shouldShowSidenav);
 */  return (
    <SoftBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        [breakpoints.up("xs")]: {
          marginLeft: !shouldShowSidenav ? (
            shouldHideInnerSidenav ? pxToRem(0) : miniSidenavInner ? pxToRem(96) : 
              pxToRem(250)
          ) : (
            shouldHideInnerSidenav ? (miniSidenav ? pxToRem(100) : pxToRem(250)) : (
              miniSidenav && miniSidenavInner ? pxToRem(180) : 
              miniSidenav ? pxToRem(100) : 
              !miniSidenavInner ? pxToRem(280) : 
              pxToRem(200)
            )
          ),
        },
        [breakpoints.up("xl")]: {
          marginLeft: !shouldShowSidenav ? (
            shouldHideInnerSidenav ? pxToRem(0) : miniSidenavInner ? pxToRem(96) : 
              pxToRem(250)
          ) : (
            shouldHideInnerSidenav ? (miniSidenav ? pxToRem(100) : pxToRem(250)) : (
              miniSidenav && miniSidenavInner ? pxToRem(192) : 
              miniSidenav ? pxToRem(350) : 
              miniSidenavInner ? pxToRem(350) : 
              pxToRem(500)
            )
          ),
        },
      })}
      /* className={classes.root} */
    >
      {children}
    </SoftBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
