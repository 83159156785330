import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

export default function InvitationHandler() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, user, login, authcheck } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleInvitation = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const email = params.get('email');

      // Store invitation params in sessionStorage for after login
      if (token || email) {
        sessionStorage.setItem('invitationParams', JSON.stringify({ token, email }));
      }

      // If not authenticated, trigger login
      if (!isAuthenticated && (token || email)) {
        login({ isSignup: true });
        return;
      }

      // If authenticated and we have invitation params, process them
      if (isAuthenticated && user?.id) {
        const storedParams = sessionStorage.getItem('invitationParams');
        if (storedParams) {
          const { token } = JSON.parse(storedParams);
          setIsProcessing(true);
          
          try {
            // Accept the invitation and update user metadata
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/invitations/accept_invitation`, {
              token,
              user_id: user.id
            });

            if (response.data.valid) {
              // Clear stored params
              sessionStorage.removeItem('invitationParams');
              const auth0Client = window.auth0Client;
              if (auth0Client) {
                try {
                  await auth0Client.getTokenSilently({ cacheMode: 'off' });
                  await authcheck();
                } catch (error) {
                  console.error('Failed to refresh auth session:', error);
                }
              }
              // Navigate to dashboard or company-specific page
              navigate('/');
            } else {
              setError(response.data.message);
            }
          } catch (err) {
            setError(err.response?.data?.message || 'Failed to process invitation');
          } finally {
            setIsProcessing(false);
          }
        }
      }
    };

    handleInvitation();
  }, [isAuthenticated, user, location, login, navigate]);

  if (isProcessing) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        color: 'red' 
      }}>
        {error}
      </div>
    );
  }

  return null; // Component is just handling redirects
} 