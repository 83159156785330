import { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";
// guards
import AuthGuard from "../guards/AuthGuard";
// components

import LoadingScreen from "../components/LoadingScreen";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import ClaimsGuard from "../guards/ClaimsGuard";
import DemoGuard from "../guards/DemoGuard";
import JourneyGuard from "../guards/JourneyGuard";
import useAuth from "../hooks/useAuth";
import Billing from "../layouts/pages/account/billing";
import UserSettings from "../layouts/pages/account/settings";
import CompanyProfile from "../layouts/pages/profile/company-profile";
import ProfileOverview from "../layouts/pages/profile/profile-overview";
import ActivitiesList from "../layouts/products/activities-list";
import ProductPage from "../layouts/products/product-page";
import WorkplaceList from "../layouts/products/workplace-list";
import InvitationHandler from "../components/InvitationHandler.js";
// Add this if you need to access user data

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
// IMPORT COMPONENTS

// Dashboard

//PORTFOLIO
const Home = Loadable(lazy(() => import("../pages/dashboard/intro/Home")));
const WpHome = Loadable(lazy(() => import("../pages/dashboard/intro/WpHome")));
const Admin = Loadable(lazy(() => import("../pages/admin/Admin.js")));

// POTENTIAL

// DISCOVER
const DeepDiveControlPanel = Loadable(lazy(() => import("../pages/dashboard/discover/DeepDiveControlPanel.js")));
const DeepDive = Loadable(lazy(() => import("../pages/dashboard/discover/DeepDive.js")));
const DeepDiveSu = Loadable(lazy(() => import("../pages/dashboard/discover/DeepDiveSu.js")));
const DeepDiveSuStudent = Loadable(lazy(() => import("../pages/dashboard/discover/DeepDiveSuStudent.js")));
const Midpoint = Loadable(lazy(() => import("../pages/dashboard/discover/Midpoint.js")));
const WorkplaceUnderstand = Loadable(lazy(() => import("../pages/dashboard/discover/WorkplaceUnderstand.js")));

// STRATEGY
const JourneyStrategy = Loadable(lazy(() => import("../pages/dashboard/strategy/JourneyStrategy.js")));

// PROGRAM
const JourneyProgram = Loadable(lazy(() => import("../pages/dashboard/program/JourneyProgram.js")));

// SEARCH
const SearchAd = Loadable(lazy(() => import("../pages/dashboard/search/SearchAd.js")));
const Solution = Loadable(lazy(() => import("../pages/dashboard/search/Solution.js")));
const PremisesSuggestions = Loadable(lazy(() => import("../pages/dashboard/search/PremisesSuggestions")));
const Search = Loadable(lazy(() => import("../pages/dashboard/search/Search")));

// SHARING
const SharedAd = Loadable(lazy(() => import("../pages/dashboard/program/SharedAd.js")));
const SharedProgram = Loadable(lazy(() => import("../pages/dashboard/program/SharedProgram.js")));
const SharedStrategy = Loadable(lazy(() => import("../pages/dashboard/program/SharedStrategy.js")));
const SharedWow = Loadable(lazy(() => import("../pages/dashboard/program/SharedWow.js")));

const Invite = Loadable(lazy(() => import("../pages/dashboard/search/Invite.js")));
// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

const FirstJourney = Loadable(lazy(() => import("../pages/dashboard/intro/FirstJourney")));

export default function Router({ showFirstJourney }) {
  // Add this hook to access user data
  const { user } = useAuth();
  {/* <Route path="/invite" element={<InvitationHandler />} /> */}

  return useRoutes([
    {
      path: "demo/*",
      element: (
        <DemoGuard>
          <DashboardNavbar />
          <DashboardLayout />
        </DemoGuard>
      ),
      children: [
        { element: <Navigate to="/demo/overview" replace /> },
        { element: <Home /> },
        { path: "", element: <Home /> },
        { element: <Navigate to="/" replace /> },
        { path: "controlpanel", element: <WpHome /> },
        {
          path: "overview", element: (
            /* <MgmtGuard> */
            <Home />
            /*  </MgmtGuard> */
          )
        },
        {
          path: "profile",
          children: [
            { element: <Navigate to="/dashboard/profile/overview" replace /> },
            { path: "overview", element: <ProfileOverview /> },
            { path: "company", element: <CompanyProfile /> },
            { path: "settings", element: <UserSettings /> },
          ],
        },
        {
          path: "portfolio",
          children: [
            { path: "overview", element: <Home /> },
          ]
        },
        {
          path: 'survey',
          children: [
            {
              path: "survey", element: (
                <JourneyGuard>
                  <DeepDiveControlPanel />
                </JourneyGuard>
              )
            },
          ]
        },
        {
          path: 'journey',
          children: [
            {
              path: "discover", element: (
                <JourneyGuard>
                  <WorkplaceUnderstand />
                </JourneyGuard>
              )
            },
            {
              path: "define", element: (
                <JourneyGuard>
                  <JourneyStrategy />
                </JourneyGuard>
              )
            },
            {
              path: "design", element: (
                <JourneyGuard>
                  <JourneyProgram />
                </JourneyGuard>
              )
            },
            {
              path: "decide", element: (
                <JourneyGuard>
                  <Solution />
                </JourneyGuard>
              )
            },
            {
              path: "marketplacer", element: (
                <JourneyGuard>
                  <PremisesSuggestions />
                </JourneyGuard>
              )
            },
            {
              path: "search", element: (
                <JourneyGuard>
                  <Search />
                </JourneyGuard>
              )
            },
            {
              path: "advertise", element: (
                <JourneyGuard>
                  <SearchAd />
                </JourneyGuard>
              )
            }
          ]
        },
      ]
    },

    {
      path: "deepdive/:journey_id",
      element: <DeepDive />
    },
    {
      path: "su/workplace",
      element: <DeepDiveSu />
    },

    {
      path: "su/workplace/:deepdive_id",
      element: <DeepDiveSu />
    },
    {
      path: "su/student",
      element: <DeepDiveSuStudent />
    },

    {
      path: "su/student/:deepdive_id",
      element: <DeepDiveSuStudent />
    },
    {
      path: "su/student",
      element: <DeepDiveSu />
    },
    {
      path: "deepdive/:journey_id/:deepdive_id",
      element: <DeepDive />
    },
    {
      path: "midpoint/:journey_id",
      element: <Midpoint />
    },
    {
      path: "midpoint/:journey_id/:midpoint_id",
      element: <Midpoint />
    },
    {
      path: "ad/:journey_id",
      element: <SharedAd />
    },
    {
      path: "program/:journey_id",
      element: <SharedProgram />
    },

    {
      path: "strategy/:journey_id",
      element: <SharedStrategy />
    },

    {
      path: "insights/:journey_id",
      element: <SharedWow />
    },
    
    {
      path: "invite",
      element: <InvitationHandler />
    },
    /* 
        {
          path: "profile",
          element: (
    
            <AuthGuard>
              <IntroGuard>
                <ClaimsGuard>
                  <DashboardLayout />
                </ClaimsGuard>
              </IntroGuard>
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to="/dashboard/profile/overview" replace /> },
            { path: "overview", element: <ProfileOverview /> },
            { path: "company", element: <CompanyProfile /> },
            { path: "settings", element: <UserSettings /> },
            { path: "billing", element: <Billing /> },
          ],
        }, */
    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <ClaimsGuard>
            {!user?.companyHubspot ? (
              <Navigate to="/demo" replace />
            ) : (
              <>
                <DashboardNavbar />
                <DashboardLayout />
              </>
            )}
          </ClaimsGuard>
        </AuthGuard>
      ),
      children: [
        { 
          element: <WpHome /> 
        },
        { 
          path: "", 
          element: /* showFirstJourney ? <FirstJourney /> : */ <WpHome /> 
        },
        { element: <Navigate to="/" replace /> },
        { path: "controlpanel", element: <WpHome /> },
        { path: "admin", element: <Admin /> },
        {
          path: "overview", element: (
            /* <MgmtGuard> */
            <Home />
            /*  </MgmtGuard> */
          )
        },
        {
          path: "profile",
          children: [
            { element: <Navigate to="/dashboard/profile/overview" replace /> },
            { path: "overview", element: <ProfileOverview /> },
            { path: "company", element: <CompanyProfile /> },
            { path: "settings", element: <UserSettings /> },
            { path: "billing", element: <Billing /> },
          ],
        },
        {
          path: "dashboard",
          children: [
            { element: <Navigate to="/dashboard/overview" replace /> },
            { path: "overview", element: <ProductPage /> },

          ],
        },
        {
          path: "portfolio",
          children: [
            { path: "overview", element: <Home /> },
          ]
        },
        {
          path: 'survey',
          children: [
            {
              path: "survey", element: (
                <JourneyGuard>
                  <DeepDiveControlPanel />
                </JourneyGuard>
              )
            },
          ]
        },
        {
          path: 'journey',
          children: [
            {
              path: "discover", element: (
                <JourneyGuard>
                  <WorkplaceUnderstand />
                </JourneyGuard>
              )
            },
            {
              path: "define", element: (
                <JourneyGuard>
                  <JourneyStrategy />
                </JourneyGuard>
              )
            },
            {
              path: "design", element: (
                <JourneyGuard>
                  <JourneyProgram />
                </JourneyGuard>
              )
            },
            {
              path: "decide", element: (
                <JourneyGuard>
                  <Solution />
                </JourneyGuard>
              )
            },
            {
              path: "marketplacer", element: (
                <JourneyGuard>
                  <PremisesSuggestions />
                </JourneyGuard>
              )
            },
            {
              path: "search", element: (
                <JourneyGuard>
                  <Search />
                </JourneyGuard>
              )
            },
            {
              path: "advertise", element: (
                <JourneyGuard>
                  <SearchAd />
                </JourneyGuard>
              )
            }
          ]
        },/* 
        {
          path: "ai",
          children: [

            { element: <Navigate to="/ai/assistant" replace /> },
            { path: "surveyindividual", element: <DeepDive /> },
          ],
        }, */
        {
          path: "workplaces",
          children: [
            { element: <Navigate to="/dashboard/workplaces/list" replace /> },
            { path: "list", element: <WorkplaceList /> },
            { path: ":id", element: <ProductPage /> },
            { path: "page", element: <ProductPage /> },
          ],
        },
        {
          path: "activities",
          children: [
            { element: <Navigate to="/dashboard/activities/list" replace /> },
            { path: "list", element: <ActivitiesList /> },
            { path: ":id", element: <ProductPage /> },
            { path: "page", element: <ProductPage /> },
          ],
        },/* 
        {
          path: "projects",
          children: [
            { element: <Navigate to="/projects/projects" replace /> },
            { path: "projects", element: <AllProjects /> },
            { path: "new", element: <NewProject /> },
            { path: "newscenario", element: <NewScenario /> },
            { path: "dashboard", element: <Dashboard /> },
            { path: "edit/:id", element: <EditProject /> },
          ],
        }, */
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: "/",
      element: <MainLayout />,
      children: [{ element: <LandingPage /> }],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
