import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';

const loadTranslations = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/translations`);
    return {
      en: { translations: response.data.en },
      se: { translations: response.data.se }
    };
  } catch (error) {
    console.error('Failed to load translations:', error);
    // Fallback to empty translations if API fails
    return {
      en: { translations: {} },
      se: { translations: {} }
    };
  }
};

const initializeI18n = async () => {
  const resources = await loadTranslations();

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: localStorage.getItem('i18nextLng') || 'en',
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',
      ns: ['translations'],
      defaultNS: 'translations',
      interpolation: {
        escapeValue: false
      }
    });
};

// Initialize i18n
initializeI18n();

// Export for reloading translations after updates in admin
export const reloadTranslations = async () => {
  const resources = await loadTranslations();
  Object.keys(resources).forEach(lng => {
    Object.keys(resources[lng]).forEach(ns => {
      i18n.addResourceBundle(lng, ns, resources[lng][ns], true, true);
    });
  });
};

export default i18n;
