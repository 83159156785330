/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
export function sidenavInviteDialog(theme) {
  const { palette, functions, typography } = theme;
  const { pxToRem } = functions;

  return {
    dialog: {
      backdropFilter: `saturate(200%) blur(30px)`,
      '& .MuiDialog-paper': {
        borderRadius: pxToRem(8),
        minWidth: pxToRem(500)
      }
    },

    dialogContent: {
      p: 2,
    },

    inviteInput: {
      '& .MuiInputBase-root': {
        borderRadius: pxToRem(8),
      }
    },

    errorText: {
      mt: 1,
      display: 'block',
      ...typography.caption,
      color: palette.error.main
    },

    successBox: {
      bgcolor: palette.success.light,
      p: 2,
      borderRadius: pxToRem(8),
      display: 'flex',
      alignItems: 'center',
      gap: 1
    },

    teamList: {
      mt: 3,
    },

    teamMemberItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      p: 2,
      borderRadius: pxToRem(8),
      bgcolor: palette.grey[100],
      mb: 1,
      '&:last-child': {
        mb: 0
      }
    }
  };
}

export default function sidenavLogoLabel(theme, ownerState) {
  const { functions, transitions, typography, breakpoints, palette } = theme;
  const { miniSidenav } = ownerState;

  const { pxToRem } = functions;
  const { fontWeightMedium } = typography;

  return {
    ml: 0.5,
    fontWeight: fontWeightMedium,
    wordSpacing: pxToRem(-1),
    color: palette.white.main,
    transition: transitions.create("opacity", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav ? 0 : 1,
    },
  };
}
