import { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Button,
  MobileStepper,
  IconButton,
  useTheme,
  Stack,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, Close, Favorite, Person, Work } from '@mui/icons-material';
import axios from 'axios';
import routes from '../../routez';
import SoftButton from '../SoftButton';
import SoftInput from '../SoftInput';
import { AuthContext } from '../../contexts/Auth0Context';

function IntroDialog({ open, onClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const { user, authcheck } = useContext(AuthContext);
  const [userProfileData, setUserProfileData] = useState({
    firstName: user?.firstName || user?.customName || '',
    role: user?.role || ''
  });
  const [showProfileStep, setShowProfileStep] = useState(false);
  const [allSteps, setAllSteps] = useState([]);

  // Filter out only the direct type routes that have descriptions
  const introSteps = routes.filter(
    (route) => route.type === "direct" && route.description
  );

  // Check if user profile is incomplete
  useEffect(() => {
    const needsProfileInfo = user?.id && ((!user.firstName && !user.customName) || !user.role);
    setShowProfileStep(needsProfileInfo);
    
    // Update all steps based on whether we need to show the profile step
    if (needsProfileInfo) {
      setAllSteps([...introSteps, {
        name: "Complete Your Profile",
        description: "Please provide some additional information to complete your profile",
        image: "/static/new/profile.png" // Use an appropriate image or fallback to default
      }]);
    } else {
      setAllSteps(introSteps);
    }
    
    // Update profile data when user changes
    setUserProfileData({
      firstName: user?.firstName || user?.customName || '',
      role: user?.role || ''
    });
  }, [user]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleProfileChange = (event) => {
    const { name, value } = event.target;
    setUserProfileData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleComplete = async () => {
    try {
      // Update user profile with hasCompletedIntro flag and profile data if on profile step
      const payload = {
        user_id: user.id,
        hasCompletedIntro: true
      };
      
      // If we're on the profile step, include the profile data
      if (showProfileStep && activeStep === allSteps.length - 1) {
        payload.firstName = userProfileData.firstName;
        payload.role = userProfileData.role;
      } else {
        // Otherwise, just pass through existing values
        payload.firstName = user.firstName || user.customName;
        payload.role = user.role;
      }
      
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/companies/updateuser`, payload);

      if (!response.data.success) {
        console.error('Failed to update user profile:', response.data.error);
        return;
      }

      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
          onClose();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }
    } catch (error) {
      console.error('Failed to complete intro:', error.response?.data || error);
    }
  };

  const handleSkip = async () => {
    try {
      // Update user profile with hasCompletedIntro flag
      // If profile is incomplete, we still need to collect that info
      if (showProfileStep) {
        // If profile is incomplete, don't allow skipping
        setActiveStep(allSteps.length - 1);
        return;
      }
      
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/companies/updateuser`, {
        user_id: user.id,
        hasCompletedIntro: true,
        firstName: user.firstName || user.customName,
        role: user.role
      });

      if (!response.data.success) {
        console.error('Failed to update user profile:', response.data.error);
        return;
      }

      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
          onClose();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }
    } catch (error) {
      console.error('Failed to skip intro:', error.response?.data || error);
    }
  };

  // Render profile step content
  const renderProfileStep = () => {
    return (
      <>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: '100%',
              height: 280,
              backgroundImage: `url(${allSteps[activeStep]?.image || '/static/new/lohow.png'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box 
              sx={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100px', 
                height: '100px', 
                borderRadius: '50%', 
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
              }}
            >
              <Favorite fontSize="large" color="primary" />
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: 4 }}>
          <Typography 
            variant="h4" 
            fontWeight="bold" 
            mb={1}
            textAlign="center"
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            Tell us a little bit more about yourself
          </Typography>

          <Typography variant="body1" color="text.secondary" sx={{ mb: 4, lineHeight: 1.8, textAlign: 'center' }}>
            Please provide some additional information to complete your profile
          </Typography>

          <Stack spacing={3} sx={{ mb: 4 }}>
            {/* First Name - Editable */}
            <SoftInput
              placeholder="First Name"
              name="firstName"
              value={userProfileData.firstName}
              onChange={handleProfileChange}
              required
              icon={{
                component: <Person />,
                direction: "left"
              }}
            />
            
            {/* Role - Editable */}
            <SoftInput
              placeholder="Your Role (e.g. Facility Manager, HR Director)"
              name="role"
              value={userProfileData.role}
              onChange={handleProfileChange}
              icon={{
                component: <Work />,
                direction: "left"
              }}
            />
            
            {/* Email - Locked */}
            <SoftInput
              placeholder="Email"
              value={user?.email || ''}
              disabled
              icon={{
                component: 'email',
                direction: "left"
              }}
            />
            
            {/* Company - Locked */}
            <SoftInput
              placeholder="Company"
              value={user?.companyName || ''}
              disabled
              icon={{
                component: 'business',
                direction: "left"
              }}
            />
          </Stack>
        </Box>
      </>
    );
  };

  // If no steps are available yet, render nothing
  if (!allSteps.length) return null;

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          overflow: 'hidden',
        }
      }}
    >
      <Box sx={{ position: 'absolute', right: 8, top: 8, zIndex: 1 }}>
        {/* Only show Skip button if not on profile step or if profile is complete */}
        {(!showProfileStep || activeStep !== allSteps.length - 1) && (
          <SoftButton
            color="secondary"
            size="small"
            onClick={handleSkip}
            sx={{
              color: 'white',
              mr: 1,
              textTransform: 'none',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              borderRadius: '8px',
              px: 2,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
              }
            }}
          >
            Skip Intro
          </SoftButton>
        )}
        <IconButton 
          onClick={onClose} 
          size="small" 
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
            '& .MuiSvgIcon-root': {
              color: 'white',
            }
          }}
        >
          <Close color="white" />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
        {/* Render profile step if we're on the last step and showProfileStep is true */}
        {showProfileStep && activeStep === allSteps.length - 1 ? (
          renderProfileStep()
        ) : (
          <>
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  width: '100%',
                  height: 280,
                  backgroundImage: `url(${allSteps[activeStep]?.image || '/static/new/lohow.png'})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  position: 'relative'
                }}
              />
            </Box>

            <Box sx={{ p: 4 }}>
              <Typography 
                variant="h4" 
                fontWeight="bold" 
                mb={1}
                sx={{
                  color: theme.palette.text.primary,
                }}
              >
                {allSteps[activeStep]?.name}
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ mb: 4, lineHeight: 1.8 }}>
                {allSteps[activeStep]?.description}
              </Typography>
            </Box>
          </>
        )}

        <Box sx={{ p: 4, pt: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <MobileStepper
              steps={allSteps.length}
              position="static"
              activeStep={activeStep}
              sx={{
                background: 'transparent',
                width: 'auto',
                flexGrow: 1,
                '.MuiMobileStepper-dot': {
                  width: 8,
                  height: 8,
                  mx: 0.5,
                },
                '.MuiMobileStepper-dotActive': {
                  background: theme.palette.primary.main,
                }
              }}
              nextButton={
                activeStep === allSteps.length - 1 ? (
                  <SoftButton
                    variant="gradient"
                    color="primary"
                    onClick={handleComplete}
                    disabled={showProfileStep && !userProfileData.firstName.trim()}
                  >
                    Let's Go!
                  </SoftButton>
                ) : (
                  <Button
                    size="small"
                    onClick={handleNext}
                    endIcon={<KeyboardArrowRight />}
                  >
                    Next
                  </Button>
                )
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  startIcon={<KeyboardArrowLeft />}
                >
                  Back
                </Button>
              }
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default IntroDialog; 