import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';
//import logo from './logo_indivd.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const { themeMode } = useSettings();

  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: 180, ...sx }}>
      {themeMode === 'light' ? <img src={"/static/lohow.png"} alt="Logo" /> : <img src={"/static/lohow.png"} alt="Logo" />}
    </Box>
  );
}
