import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorIllustration from '../assets/images/errore.svg'; // You'll need to add this illustration
import SoftButton from './SoftButton';

const ErrorScreen = ({ error, resetError }) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    resetError();
    navigate('/');
  };

  const handleGoBack = () => {
    resetError();
    navigate(-1);
  };

  const handleRefresh = () => {
    resetError();
    window.location.reload();
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          py: 5,
          gap: 3
        }}
      >
        <Box
          component="img"
          src={ErrorIllustration}
          alt="Error Illustration"
          sx={{
            width: '100%',
            maxWidth: 400,
            height: 'auto',
            mb: 4
          }}
        />

        <Typography 
          variant="h3" 
          sx={{ 
            fontWeight: 700,
            background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            mb: 2
          }}
        >
          Oops! Something went wrong
        </Typography>

        <Typography 
          variant="body1" 
          color="text.secondary" 
          sx={{ 
            maxWidth: 600,
            mb: 4 
          }}
        >
          We apologize for the inconvenience. An unexpected error has occurred.
          {error.message && (
            <Box component="span" sx={{ display: 'block', mt: 1, fontSize: '0.9em', color: 'text.disabled' }}>
              Error details: {error.message}
            </Box>
          )}
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <SoftButton
            variant="gradient"
            color="primary"
            onClick={handleGoHome}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600
            }}
          >
            Go to Homepage
          </SoftButton>
          
          <SoftButton
            variant="contained"
            color="primary"
            onClick={handleRefresh}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600
            }}
          >
            Refresh Page
          </SoftButton>

        </Box>
      </Box>
    </Container>
  );
};

export default ErrorScreen; 