import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/Auth0Context';
import IntroDialog from './index';

function IntroWrapper() {
  const [showIntro, setShowIntro] = useState(false);
  const { user, isAuthenticated, isInitialized } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated && isInitialized && user && !user.hasCompletedIntro) {
      setShowIntro(true);
    }
  }, [isAuthenticated, isInitialized, user]);

  const handleClose = () => {
    setShowIntro(false);
  };

  if (!isAuthenticated || !isInitialized) {
    return null;
  }

  return (
    <IntroDialog
      open={showIntro}
      onClose={handleClose}
    />
  );
}

export default IntroWrapper; 