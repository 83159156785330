import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fullyErgonomicDesksRatio: 0.8,
  smallRoomsRatio: 0.15,
  largeRoomsRatio: 0.05,
  totalSeatsRatio: 2,
  noSurveys: false,
  deepDive: null,
  midpoint: null,
  deepdives: [],
  midpoints: [],
  allMidpoints: [],
  allDeepDives: []
};

const slice = createSlice({
  name: "workplace",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    

    // GET POST INFINITE
    getDeepDiveSuccess(state, action) {
      state.isLoading = false;
      state.deepDive = action.payload;
    },
    getDeepDivesSuccess(state, action) {
      state.isLoading = false;
      const deepdives = action.payload?.filter((d) => d.isCompleted);
      state.deepdives = deepdives;
    },

    getAllMidpointsSuccess(state, action) {
      state.isLoading = false;
      state.allMidpoints = action.payload.filter((d) => d.isCompleted);
    },
    getAllDeepDivesSuccess(state, action) {
      state.isLoading = false;
      state.allDeepDives = action.payload.filter((d) => d.isCompleted);
    },

    getMidpointsSuccess(state, action) {
      state.isLoading = false;
      const midpoints = action.payload?.filter((d) => d.isCompleted);
      state.midpoints = midpoints;
    },
    getMidpointSuccess(state, action) {
      state.isLoading = false;
      state.midpoint = action.payload;
    },
   
  },
});

// Reducer
export default slice.reducer;
// Actions
// ----------------------------------------------------------------------

export function getDeepDive(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/deepdives/deepdive/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getDeepDiveSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyDeepDives(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/deepdives/journeyindex/${id}`
      );
      console.log("avt journeydeepdives,", response);
      dispatch(slice.actions.getDeepDivesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyMidpoints(id) {
  console.log("loading journey midpoints");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/midpoints/journeyindex/${id}`
      );
      console.log("avt midpoints,", response);
      dispatch(slice.actions.getMidpointsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllMidpoints() {
  console.log("loading all midpoints");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/midpoints/index`
      );
      console.log("avt midpoints,", response);
      dispatch(slice.actions.getAllMidpointsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllDeepDives() {
  console.log("loading all deepdives");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/deepdives/index`
      );
      console.log("avt deepdives,", response);
      dispatch(slice.actions.getAllDeepDivesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMidpoint(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/midpoints/midpoint/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getMidpointSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getWorkplaceprofiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/workplaceprofiles/index`
      );
      dispatch(slice.actions.getWorkplaceprofilesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/workplaces/workplace/${id}`,
        {
          params: { id },
        }
      );
      dispatch(slice.actions.getWorkplaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
