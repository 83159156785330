import { Button, Dialog, DialogContent, Divider, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SoftBox from "../../../../components/SoftBox";
import SoftInput from "../../../../components/SoftInput/index.js";
import SoftTypography from "../../../../components/SoftTypography";
import CompanyForm from "../../../../components/_dashboard/survey/CompanyForm";
import Footer from "../../../../examples/Footer";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import Departments from "../../../../examples/Lists/Departments";
import ProfilesList from "../../../../examples/Lists/ProfilesList";
import useAuth from "../../../../hooks/useAuth";
import { getDepartments, getTeamMembers } from "../../../../redux/slices/company";
import { getJourney, getJourneys } from "../../../../redux/slices/site";
import { dispatch } from "../../../../redux/store";
import Header from "../../components/Header";
import PlatformSettings from "./components/PlatformSettings";

function Overview() {
  const { user, authcheck } = useAuth();
  const { journey, contracts } = useSelector((state) => state.site);
  const navigate = useNavigate();
  const { company, departments, teamMembers } = useSelector((state) => state.company);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (!user.companyHubspot || departments.length) return;
    console.log('get departments');
    dispatch(getDepartments(user.companyHubspot));
  }, [user.companyHubspot, departments.length]);

  useEffect(() => {
    if (user.id && user?.companyHubspot && (!teamMembers || teamMembers.length === 0)) {
      console.log('Getting team members in profile component');
      dispatch(getTeamMembers(company.id));
    }
  }, [user, dispatch, teamMembers]);


  useEffect(() => {
    console.log('Profile component - teamMembers:', teamMembers);
  }, [teamMembers]);

  // Handler for deleting a team member
  const handleDeleteTeamMember = async (userId) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/invitations/removefromteam`, {
        user_id: userId
      });

      // Refresh team members list
      dispatch(getTeamMembers(company.id));

      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }
    } catch (error) {
      console.error('Error removing team member:', error);
    }
  };

  // Handler for updating a team member's role
  const handleRoleChange = async (userId, newRole) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/invitations/updaterole`, {
        user_id: userId,
        internalrole: newRole
      });

      // Refresh team members list
      dispatch(getTeamMembers(company.id));

      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }
    } catch (error) {
      console.error('Error updating team member role:', error);
    }
  };

  // Handler for updating a team member's site access
  const handleSiteChange = async (userId, siteId) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/invitations/updatesite`, {
        user_id: userId,
        site_id: siteId
      });

      // Refresh team members list
      dispatch(getTeamMembers(company.id));

      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }
    } catch (error) {
      console.error('Error updating team member site access:', error);
    }
  };

  async function createJourney(id, name) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/journeys/create`;
    try {
      const body = {
        company_id: user.companyHubspot,
        version: 2
      };
      const response = await axios.post(url, { company_id: id });
      console.log(response.data); // Assuming you want to log each successful response
      dispatch(getJourneys());
      dispatch(getJourney(response.data.id));
      setOpen(true);
      console.log('open');
      //updateUser(response.data.company.id, revisedCompany.name);
      //onCompany(company);
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  function updateJourney(par) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`;
    axios.put(url, par)
      .then(() => {
        console.log('UPDATED JoURNEY');
        dispatch(getJourney(journey.id));
      })
      .catch((error) => console.log(error.message));
  }

  const handleSaveName = () => {
    console.log('save name');
    setOpen(false);
    updateJourney({ label: name });
    navigate(`/`);
  }
  const handleInput = ({ currentTarget }) => setName(currentTarget.value)
  console.log('teamMembers list', teamMembers);
  return (

    <DashboardLayout>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>

          <SoftBox>
            <SoftTypography variant="h6" fontWeight="medium">
              Name your organization
            </SoftTypography>
            <SoftInput onChange={handleInput} />
            <Button onClick={handleSaveName}>Let's go</Button>
          </SoftBox>
        </DialogContent>
      </Dialog>
      <Header />

      <SoftBox mt={3} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={6}>
            <PlatformSettings />
            <CompanyForm />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <Departments title="Departments" deps={company?.companydepartments} />
            <Divider />
            <ProfilesList
              title="Organization"
              teamMembers={teamMembers}
              sites={contracts}
              onDelete={handleDeleteTeamMember}
              onRoleChange={handleRoleChange}
              onSiteChange={handleSiteChange}
            />
          </Grid>
        </Grid>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
