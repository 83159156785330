import { DeleteOutline } from "@mui/icons-material";
import { Box, Card, Grid, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftInput from "../../../components/SoftInput";
import SoftTypography from "../../../components/SoftTypography";
import { updateCompany } from "../../../redux/slices/company";

export default function BusinessContext() {
  const { company } = useSelector(state => state.company);
  const dispatch = useDispatch();
  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <SoftTypography variant="h3" fontWeight="bold" textGradient color='primary'>
            Business Context
          </SoftTypography>
          {/* Add workplace strategy content here */}
        </Card>

        <Card sx={{ p: 3, mt: 3 }}>
          <SoftTypography variant="h4" fontWeight="bold" textGradient color='primary' mb={3}>
            Update Business Context
          </SoftTypography>

          <Grid container spacing={3}>
            {/* Mission & Vision */}
            <Grid item xs={12} md={6}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Mission Statement
                </SoftTypography>
                <SoftInput
                  multiline
                  rows={3}
                  placeholder="Enter company mission..."
                  value={company?.mission || ''}
                  onChange={(e) => dispatch(updateCompany(company.id, { mission: e.target.value }))}
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Vision Statement
                </SoftTypography>
                <SoftInput
                  multiline
                  rows={3}
                  placeholder="Enter company vision..."
                  value={company?.vision || ''}
                  onChange={(e) => dispatch(updateCompany(company.id, { vision: e.target.value }))}
                />
              </SoftBox>
            </Grid>

            {/* Services & Functions */}
            <Grid item xs={12} md={6}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Key Services (comma-separated)
                </SoftTypography>
                <SoftInput
                  placeholder="Enter services..."
                  value={company?.services?.join(', ') || ''}
                  onChange={(e) => dispatch(updateCompany(company.id, {
                    services: e.target.value.split(',').map(s => s.trim()).filter(s => s)
                  }))}
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Key Functions (comma-separated)
                </SoftTypography>
                <SoftInput
                  placeholder="Enter functions..."
                  value={company?.keyFunctions?.join(', ') || ''}
                  onChange={(e) => dispatch(updateCompany(company.id, {
                    keyFunctions: e.target.value.split(',').map(s => s.trim()).filter(s => s)
                  }))}
                />
              </SoftBox>
            </Grid>

            {/* Strategy */}
            <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Business Strategy
                </SoftTypography>
                <SoftInput
                  multiline
                  rows={4}
                  placeholder="Enter business strategy..."
                  value={company?.strategy || ''}
                  onChange={(e) => dispatch(updateCompany(company.id, { strategy: e.target.value }))}
                />
              </SoftBox>
            </Grid>

            {/* Timeline */}
            <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Strategic Timeline
                </SoftTypography>
                {(company?.strategicTimeline || []).map((milestone, index) => (
                  <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
                    <SoftInput
                      placeholder="Date"
                      value={milestone.date}
                      onChange={(e) => {
                        const newTimeline = [...(company?.strategicTimeline || [])];
                        newTimeline[index] = { ...milestone, date: e.target.value };
                        dispatch(updateCompany(company.id, { strategicTimeline: newTimeline }));
                      }}
                      sx={{ width: '20%' }}
                    />
                    <SoftInput
                      placeholder="Title"
                      value={milestone.title}
                      onChange={(e) => {
                        const newTimeline = [...(company?.strategicTimeline || [])];
                        newTimeline[index] = { ...milestone, title: e.target.value };
                        dispatch(updateCompany(company.id, { strategicTimeline: newTimeline }));
                      }}
                      sx={{ width: '30%' }}
                    />
                    <SoftInput
                      placeholder="Description"
                      value={milestone.description}
                      onChange={(e) => {
                        const newTimeline = [...(company?.strategicTimeline || [])];
                        newTimeline[index] = { ...milestone, description: e.target.value };
                        dispatch(updateCompany(company.id, { strategicTimeline: newTimeline }));
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                      onClick={() => {
                        const newTimeline = company?.strategicTimeline.filter((_, i) => i !== index);
                        dispatch(updateCompany(company.id, { strategicTimeline: newTimeline }));
                      }}
                      color="error"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                ))}
                <SoftButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const newTimeline = [...(company?.strategicTimeline || []), { date: '', title: '', description: '', completed: false }];
                    dispatch(updateCompany(company.id, { strategicTimeline: newTimeline }));
                  }}
                >
                  Add Milestone
                </SoftButton>
              </SoftBox>
            </Grid>

            {/* Local Factors */}
            <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftTypography variant="subtitle2" fontWeight="medium" mb={1}>
                  Local Market Factors
                </SoftTypography>
                {(company?.localFactors || []).map((factor, index) => (
                  <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
                    <SoftInput
                      placeholder="Factor Name"
                      value={factor.name}
                      onChange={(e) => {
                        const newFactors = [...(company?.localFactors || [])];
                        newFactors[index] = { ...factor, name: e.target.value };
                        dispatch(updateCompany(company.id, { localFactors: newFactors }));
                      }}
                      sx={{ width: '30%' }}
                    />
                    <SoftInput
                      placeholder="Description"
                      value={factor.description}
                      onChange={(e) => {
                        const newFactors = [...(company?.localFactors || [])];
                        newFactors[index] = { ...factor, description: e.target.value };
                        dispatch(updateCompany(company.id, { localFactors: newFactors }));
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                    <SoftInput
                      type="number"
                      placeholder="Score (1-5)"
                      value={factor.score}
                      onChange={(e) => {
                        const newFactors = [...(company?.localFactors || [])];
                        newFactors[index] = { ...factor, score: Math.min(5, Math.max(1, parseInt(e.target.value) || 1)) };
                        dispatch(updateCompany(company.id, { localFactors: newFactors }));
                      }}
                      sx={{ width: '15%' }}
                    />
                    <IconButton
                      onClick={() => {
                        const newFactors = company?.localFactors.filter((_, i) => i !== index);
                        dispatch(updateCompany(company.id, { localFactors: newFactors }));
                      }}
                      color="error"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                ))}
                <SoftButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const newFactors = [...(company?.localFactors || []), { name: '', description: '', score: 1 }];
                    dispatch(updateCompany(company.id, { localFactors: newFactors }));
                  }}
                >
                  Add Factor
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  )
}   