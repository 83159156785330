/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PathSelectionDialog from "../../../components/PathSelectionDialog";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftInput from "../../../components/SoftInput";
import SoftSelect from "../../../components/SoftSelect";
import SoftTypography from "../../../components/SoftTypography";
import SoftSnackbar from "../../../components/SoftSnackbar";
import useAuth from "../../../hooks/useAuth";
import AccountPopover from "../../../layouts/dashboard/AccountPopover";
import BusinessContext from "../../../pages/dashboard/intro/BusinessContext";
import { getCompany } from "../../../redux/slices/company";
import { getCompanySites, updateJourney } from "../../../redux/slices/site";
import { getJourneySpacebricks } from "../../../redux/slices/workplace";
import {
  setMiniSidenav,
  setTransparentNavbar, useSoftUIController
} from "../../../softcontext";
import NotificationItem from "../../Items/NotificationItem";
import {
  navbar,
  navbarContainer, navbarDesktopMenu, navbarRow
} from "./styles";
import SiteDialog from '../../../components/SiteDialog';

function DashboardNavbar({ absolute, light, isMini, name, onCompanyChange }) {
  const { user, authcheck } = useAuth();
  const dispatchredux = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { company, companies } = useSelector((state) => state.company);
  const { journey, contract, contracts, sites, site } = useSelector((state) => state.site);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Add this mapping for steps
  const pathToStep = {
    'discover': 0,
    'define': 1,
    'design': 2,
    'direction': 3,
    'decide': 4
  };

  // Get the current step based on the path
  const currentStep = pathToStep[route[route.length - 1]] || 0;

  // Reverse mapping for navigation
  const stepToPath = {
    0: 'journey/discover',
    1: 'journey/define',
    2: 'journey/design',
    3: 'journey/direction',
    4: 'journey/decide'
  };

  const handleStepClick = (index) => {
    const path = stepToPath[index];
    if (path) {
      navigate(`/${path}`);
    }
  };

  useEffect(() => {
    if (journey) {
      if (!journey.path && company?.hasCompletedIntro) {
        setOpenPathDialog(true);
      }
    }
  }, [journey]);

  useEffect(() => {
    // Setting the navbar type
    /*   if (fixedNavbar) {
        setNavbarType("sticky");
      } else {
        setNavbarType("static");
      } */

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [notifications, setNotifications] = useState([]);

  const getDaysUntil = (date) => {
    if (!date) return null;
    const today = new Date();
    const targetDate = new Date(date);
    return Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const expiringContracts = contracts.filter(contract => {
      const daysUntilExpiry = getDaysUntil(contract.through);
      const daysUntilNotice = getDaysUntil(contract.notificationDeadline);

      // Only include contracts with valid data
      if (!contract.name && !contract.label) return false;

      // Exclude contracts with null dates
      if (daysUntilExpiry === null && daysUntilNotice === null) return false;

      // Notify if contract expires in 30 days or less or if notice deadline is critical
      return (daysUntilExpiry !== null && daysUntilExpiry <= 30) ||
        (daysUntilNotice !== null && daysUntilNotice <= 30);
    });
    setNotifications(expiringContracts);
  }, [contracts]);

  async function updateUser(userData) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companies/updateuser`;
    try {
      const response = await axios.put(url, {
        user_id: user.id,
        ...userData
      });

      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }

      return response.data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  const handleCompanyChange = async (event) => {
    console.log('event', event);
    const selectedCompany = companies.find(c => c.id === event.value);
    try {
      await updateUser({
        id: selectedCompany.id,
        name: selectedCompany.name,
        journey_id: journey?.id
      });

      // Update redux state
      await dispatchredux(getCompany(selectedCompany.id));
      dispatchredux({ type: 'workplace/clearSpacebricks' });

      // Fetch new data for the new company if journey exists
      if (journey?.id) {
        await dispatchredux(getJourneySpacebricks({ journey_id: journey.id }));
      }
    } catch (error) {
      console.error('Failed to change company:', error);
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications.length > 0 ? (
        notifications.map((contract, index) => {
          const daysUntilExpiry = getDaysUntil(contract.through);
          const daysUntilNotice = getDaysUntil(contract.notificationDeadline);
          const isExpiring = daysUntilExpiry <= 30;

          return (
            <NotificationItem
              key={contract.id || index}
              image={<Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>warning</Icon>}
              title={[
                `Contract: ${contract.name || contract.label}`,
                `Status: ${isExpiring ? 'Expiring Soon' : 'Critical Notice'}`
              ]}
              date={`${isExpiring ? daysUntilExpiry : daysUntilNotice} days remaining`}
              onClick={handleCloseMenu}
            />
          );
        })
      ) : (
        <Box sx={{ py: 2, px: 2.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
            <Icon fontSize="medium" sx={{ color: 'text.secondary', mr: 1 }}>notifications_none</Icon>
            <SoftTypography variant="subtitle2" fontWeight="medium">
              No New Notifications
            </SoftTypography>
          </Box>
          <SoftTypography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
            You're all caught up! No pending contract notifications.
          </SoftTypography>
        </Box>
      )}
    </Menu>
  );

  // Add new state for dialog
  const [openPathDialog, setOpenPathDialog] = useState(false);

  // Add handler for path selection
  const handlePathSelection = async ({ path, mode }) => {
    try {
      // Update user settings
      dispatchredux(updateJourney(journey.id, {
        path: path,
        mode: mode
      }));
      // Close dialog
      setOpenPathDialog(false);
    } catch (error) {
      console.error('Error updating path settings:', error);
    }
  };

  // Add this function to check if we're in a journey path
  const isJourneyPath = () => {
    return route[0] === 'journey' && ['discover', 'define', 'design', 'direction', 'decide'].includes(route[1]);
  };
  const isSearchPath = () => {
    const searchPaths = ['explore', 'select', 'inquire', 'evaluate', 'sign'];
    return route[1] === 'search' && searchPaths.includes(route[2]);
  };

  const [openBusinessContext, setOpenBusinessContext] = useState(false);

  const handleOpenBusinessContext = () => {
    setOpenBusinessContext(true);
  };

  const handleCloseBusinessContext = () => {
    setOpenBusinessContext(false);
  };

  // Add these state variables for site management
  const [openNewSite, setOpenNewSite] = useState(false);
  const [openBatchAdd, setOpenBatchAdd] = useState(false);
  const [newSite, setNewSite] = useState({
    label: '',
    address: '',
    city: '',
    postal_code: '',
    country: '',
    employees_to: '',
    sqm: '',
    annual_rent: ''
  });
  const [batchSites, setBatchSites] = useState([]);
  const [batchFile, setBatchFile] = useState(null);

  // Add these state variables with the other useState declarations
  const [headerMappingOpen, setHeaderMappingOpen] = useState(false);
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [targetHeaders, setTargetHeaders] = useState([]);
  const [suggestedMapping, setSuggestedMapping] = useState({});
  const [customMapping, setCustomMapping] = useState({});
  const [mappingInProgress, setMappingInProgress] = useState(false);
  const [sampleData, setSampleData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Define the expected headers
  const EXPECTED_HEADERS = ["label", "address", "city", "country", "zip_code", "employees", "square_footage", "annual_rent"];

  // Add these handler functions for site management
  const handleNewSiteChange = (field) => (event) => {
    setNewSite({
      ...newSite,
      [field]: event.target.value,
    });
  };

  // Add this function to parse CSV properly
  const parseCSV = (csvContent) => {
    const lines = csvContent.split(/\r?\n/);
    const result = [];

    // Parse headers
    const headers = parseCSVLine(lines[0]);

    // Parse data rows
    for (let i = 1; i < lines.length; i++) {
      if (lines[i].trim() === '') continue;

      const values = parseCSVLine(lines[i]);
      const row = {};

      headers.forEach((header, index) => {
        row[header] = index < values.length ? values[index] : '';
      });

      result.push(row);
    }

    return { headers, data: result };
  };

  // Helper function to parse a CSV line, handling quoted values with commas
  const parseCSVLine = (line) => {
    const result = [];
    let inQuotes = false;
    let currentValue = '';

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === '"') {
        // Toggle quote state
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        // End of field
        result.push(currentValue.trim());
        currentValue = '';
      } else {
        // Add character to current field
        currentValue += char;
      }
    }

    // Add the last field
    result.push(currentValue.trim());

    return result;
  };

  const handleBatchFileUpload = (event) => {
    const file = event.target.files[0];
    setBatchFile(file);

    if (file) {
      // Show loading state
      setIsUploading(true);
      setUploadProgress(10);

      // First, check if we need to analyze headers
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          setUploadProgress(30);
          const csv = e.target.result;
          const { headers, data } = parseCSV(csv);

          console.log('CSV Headers:', headers);
          console.log('CSV Data Sample:', data.slice(0, 3));
          console.log('Expected Headers:', EXPECTED_HEADERS);

          setUploadProgress(50);

          // Check if all expected headers are present (regardless of order)
          const allHeadersPresent = EXPECTED_HEADERS.every(header =>
            headers.some(h => h.toLowerCase() === header.toLowerCase())
          );

          console.log('All headers present?', allHeadersPresent);

          if (allHeadersPresent) {
            // All headers are present, just need to map them correctly
            const headerMapping = {};
            headers.forEach(header => {
              const matchedHeader = EXPECTED_HEADERS.find(
                h => h.toLowerCase() === header.toLowerCase()
              );
              if (matchedHeader) {
                headerMapping[header] = matchedHeader;
              } else {
                headerMapping[header] = null; // No match found
              }
            });

            console.log('Auto-generated header mapping:', headerMapping);

            setUploadProgress(80);
            // Process the CSV with the correct mapping
            processCSVWithMapping(csv, headerMapping);
            setUploadProgress(100);
            setIsUploading(false);
          } else {
            // Headers don't match, need to analyze and get suggestions
            setMappingInProgress(true);
            setUploadProgress(70);

            try {
              // Create a FormData object to send the file
              const formData = new FormData();
              formData.append('file', file);

              console.log('Sending file for header analysis:', file.name);

              // Call the API to analyze headers
              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/openai/analyze_csv_headers`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    // Calculate upload progress percentage
                    const progress = Math.round(
                      (progressEvent.loaded / progressEvent.total) * 20 + 70
                    );
                    setUploadProgress(Math.min(progress, 90));
                  }
                }
              );

              setUploadProgress(95);
              console.log('API Response:', response.data);

              // Store the mapping suggestion
              setOriginalHeaders(response.data.original_headers);
              setTargetHeaders(response.data.target_headers);
              setSuggestedMapping(response.data.suggested_mapping);
              setCustomMapping(response.data.suggested_mapping); // Initialize custom mapping with suggestions
              setSampleData(response.data.sample_data);

              console.log('Original Headers:', response.data.original_headers);
              console.log('Target Headers:', response.data.target_headers);
              console.log('Suggested Mapping:', response.data.suggested_mapping);
              console.log('Sample Data:', response.data.sample_data);

              setUploadProgress(100);
              // Open the mapping dialog
              setHeaderMappingOpen(true);
            } catch (error) {
              console.error('Error analyzing CSV headers:', error);
              console.error('Error details:', error.response?.data || error.message);
              // Show error message
              alert('Error analyzing CSV headers. Please check the file format and try again.');
            } finally {
              setMappingInProgress(false);
              setIsUploading(false);
            }
          }
        } catch (error) {
          console.error('Error processing CSV file:', error);
          alert('Error processing CSV file. Please check the file format and try again.');
          setIsUploading(false);
        }
      };

      reader.onerror = () => {
        console.error('Error reading file');
        alert('Error reading file. Please try again.');
        setIsUploading(false);
      };

      reader.readAsText(file);
    }
  };

  // Update the processCSVWithMapping function to use the new CSV parser
  const processCSVWithMapping = (csvContent, headerMapping) => {
    const { headers, data } = parseCSV(csvContent);

    console.log('Processing CSV with mapping:', headerMapping);
    console.log('CSV Headers for processing:', headers);

    const sites = data.map(row => {
      const site = {};

      headers.forEach(header => {
        // Use the mapped header name if available, otherwise use the original
        const mappedHeader = headerMapping[header] || header;
        site[mappedHeader] = row[header];
      });

      return site;
    });

    console.log('Processed sites:', sites);

    setBatchSites(sites);
  };

  // Add this function to handle the confirmation of custom mapping
  const handleConfirmMapping = () => {
    console.log('Confirming mapping:', customMapping);

    // Create a FormData object to send the file and mapping
    const formData = new FormData();
    formData.append('file', batchFile);
    formData.append('mapping', JSON.stringify(customMapping));

    setMappingInProgress(true);

    // Call the API to transform the CSV with the mapping
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/openai/transform_csv_with_mapping`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(response => {
        console.log('Transform API response:', response.data);

        // Process the transformed data
        if (response.data.success) {
          // Fetch the transformed CSV
          return axios.get(response.data.download_url, { responseType: 'text' });
        } else {
          throw new Error('Failed to transform CSV');
        }
      })
      .then(response => {
        console.log('Downloaded CSV content:', response.data.substring(0, 200) + '...');

        // Process the CSV content
        const csvContent = response.data;
        processCSVWithMapping(csvContent, {});  // Empty mapping since headers are already correct

        // Close the mapping dialog
        setHeaderMappingOpen(false);
      })
      .catch(error => {
        console.error('Error transforming CSV:', error);
        console.error('Error details:', error.response?.data || error.message);
        alert('Error transforming CSV. Please try again.');
      })
      .finally(() => {
        setMappingInProgress(false);
      });
  };

  // Add this function to handle changes to the custom mapping
  const handleMappingChange = (originalHeader, targetHeader) => {
    console.log('Mapping change:', originalHeader, '->', targetHeader);

    setCustomMapping(prev => ({
      ...prev,
      [originalHeader]: targetHeader
    }));
  };

  // Snackbar state
  const [openGeocodingSnackbar, setOpenGeocodingSnackbar] = useState(false);
  const [geocodingSnackbarMessage, setGeocodingSnackbarMessage] = useState("");
  const [geocodingSnackbarColor, setGeocodingSnackbarColor] = useState("success");

  const handleCreateNewSite = async (siteData) => {
    try {
      // Create the new contract/site
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sites`, siteData);

      setOpenNewSite(false);
      setNewSite({
        label: '',
        address: '',
        city: '',
        postal_code: '',
        country: '',
        employees_to: '',
        sqm: '',
        annual_rent: ''
      });

      // Show success snackbar with geocoding info
      if (siteData.latitude && siteData.longitude) {
        setGeocodingSnackbarMessage(`Site created with geocoded location: ${siteData.latitude.toFixed(6)}, ${siteData.longitude.toFixed(6)}`);
        setGeocodingSnackbarColor("success");
      } else {
        setGeocodingSnackbarMessage("Site created but geocoding was not successful");
        setGeocodingSnackbarColor("warning");
      }
      setOpenGeocodingSnackbar(true);

      // Refresh data only for the current company
      dispatchredux(getCompany(company?.id));
    } catch (error) {
      console.error('Error creating site:', error);
      setGeocodingSnackbarMessage("Error creating site");
      setGeocodingSnackbarColor("error");
      setOpenGeocodingSnackbar(true);
    }
  };

  const handleCompleteBatch = async () => {
    try {
      for (const site of batchSites) {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contracts`, {
          company_id: company?.id,
          ...site,
          type: 'site'
        });
      }
      setOpenBatchAdd(false);
      setBatchSites([]);
      setBatchFile(null);
      dispatchredux(getCompany(company?.id));
    } catch (error) {
      console.error('Error adding batch sites:', error);
    }
  };

  // Add this helper function to format cell values for display
  const formatCellValue = (value) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') return JSON.stringify(value);
    return String(value);
  };

  // Add state for sites menu
  const [sitesMenuAnchor, setSitesMenuAnchor] = useState(null);
  const openSitesMenu = Boolean(sitesMenuAnchor);

  // Add this function to handle opening the sites menu
  const handleOpenSitesMenu = (event) => {
    setSitesMenuAnchor(event.currentTarget);
  };

  // Add this function to handle closing the sites menu
  const handleCloseSitesMenu = () => {
    setSitesMenuAnchor(null);
  };

  // Add this function to handle site selection
  const handleSiteSelect = async (site) => {
    try {
      console.log('Selected site:', site);
      // Check if site has journeys
      if (site.journeys && site.journeys.length > 0) {
        // Get the first journey of the site
        const siteJourney = site.journeys[0];
        console.log('Setting journey to:', siteJourney);

        // Update company with the selected site's ID
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/companies/update/${company.id}`, {
          last_site_id: site.id
        });

        // Refresh company data to get updated last_site_id
        dispatchredux(getCompany(company.id));

        // Update journey in redux
        dispatchredux({ type: 'site/getJourneySuccess', payload: siteJourney });

        // Close menu
        handleCloseSitesMenu();
      } else {
        console.log('Site has no journeys');
        // Show a message that this site doesn't have any journeys
        alert('This site does not have any associated projects');
      }
    } catch (error) {
      console.error('Error selecting site:', error);
    }
  };

  // Add useEffect to load sites when component mounts or company changes
  useEffect(() => {
    if (company?.id) {
      dispatchredux(getCompanySites(company));
    }
  }, [dispatchredux, company?.id]);
  console.log('sites', sites);
  console.log('site', site);
  console.log('journey', journey);
  return (
    <>
      <AppBar
        position={"sticky"}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => ({
          ...navbarContainer(theme),
          position: 'relative'
        })}>
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => ({
              ...navbarRow(theme, { isMini }),
              position: 'relative',
              left: miniSidenav ? '-10.5rem' : 0,
              transition: 'left 200ms ease-in-out',
              display: 'flex',
              alignItems: 'center'
            })}
          >
            <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
              {miniSidenav ? "menu" : "menu_open"}
            </Icon>
            <SoftBox pr={1}>
              {journey?.id && (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
                    borderRadius: 2,
                    px: 2,
                    py: 0.75,
                    border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                      transform: 'translateY(-1px)',
                      boxShadow: 1
                    }
                  }}
                >
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Tooltip title="Project Settings">
                      <IconButton
                        size="small"
                        onClick={() => setOpenPathDialog(true)}
                        sx={{
                          color: 'primary.main',
                          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                          '&:hover': {
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2)
                          }
                        }}
                      >
                        <Icon fontSize="small">settings</Icon>
                      </IconButton>
                    </Tooltip>
                    <Stack>
                      <SoftTypography
                        variant="caption"
                        color="text.secondary"
                        sx={{ lineHeight: 1 }}
                      >
                        Active Site {site ?
                          `· ${site.label}` : ''}
                      </SoftTypography>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      icon={
                        <Icon sx={{ fontSize: '1.1rem !important' }}>
                          {journey?.path === 'plus' ? 'speed' : 'bolt'}
                        </Icon>
                      }
                      label={journey?.path === 'plus' ? 'Elevate' : 'Express'}
                      size="small"
                      sx={{
                        backgroundColor: (theme) => journey?.path === 'plus'
                          ? alpha(theme.palette.info.main, 0.1)
                          : journey?.path === 'light' ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.primary.main, 0.1),
                        color: journey?.path === 'plus' ? 'info.main' : journey?.path === 'light' ? 'success.main' : 'primary.main',
                        '& .MuiChip-icon': {
                          color: 'inherit'
                        }
                      }}
                    />

                    {/* Sites Dropdown */}
                    <Tooltip title="Switch between sites and their projects">
                      <IconButton
                        size="small"
                        onClick={handleOpenSitesMenu}
                        sx={{
                          color: 'secondary.main',
                          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1),
                          '&:hover': {
                            backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.2)
                          }
                        }}
                      >
                        <Icon fontSize="small">arrow_drop_down</Icon>
                      </IconButton>
                    </Tooltip>
                  </Stack>

                </Stack>
              )}
            </SoftBox>
            <Popover
              open={openSitesMenu}
              anchorEl={sitesMenuAnchor}
              onClose={handleCloseSitesMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 320,
                  maxHeight: 450,
                  overflowY: 'auto',
                  '& .MuiMenuItem-root': {
                    px: 1,
                    py: 1,
                    borderRadius: 0.75,
                    typography: 'body2',
                  },
                  backgroundColor: (theme) => theme.palette.background.paper,
                  boxShadow: (theme) => theme.shadows[8],
                  border: (theme) => `1px solid ${alpha(theme.palette.grey[500], 0.12)}`,
                },
              }}
            >
              <Box sx={{ p: 2, pb: 1 }}>
                <SoftTypography variant="subtitle1" fontWeight="bold">
                  Sites
                </SoftTypography>
                <SoftTypography variant="body2" color="text.secondary">
                  Select a site to change active project
                </SoftTypography>
              </Box>
              <Divider sx={{ borderStyle: 'dashed' }} />
              {sites && sites.length > 0 ? (
                sites.map((site) => (
                  <MenuItem
                    key={site.id}
                    onClick={() => handleSiteSelect(site)}
                    sx={{
                      my: 0.5,
                      ...(company?.last_site_id === site.id && {
                        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                      }),
                    }}
                  >
                    <ListItemIcon>
                      <Icon fontSize="small" color={company?.last_site_id === site.id ? 'primary' : 'inherit'}>
                        {company?.last_site_id === site.id ? 'check_circle' : 'location_on'}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <SoftTypography variant="body2" fontWeight={company?.last_site_id === site.id ? 'bold' : 'medium'}>
                          {site.label || 'Unnamed Site'}
                        </SoftTypography>
                      }
                      secondary={
                        <SoftTypography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            display: 'block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '180px'
                          }}
                        >
                          {site.address ? `${site.address.slice(0, 35)}${site.address.length > 35 ? '...' : ''}${site.city ? `, ${site.city.slice(0, 15)}` : ''}` : 'No address'}
                        </SoftTypography>
                      }
                    />
                    <Typography variant="caption" color="text.secondary">
                      {site.journeys?.length || 0} project{site.journeys?.length !== 1 ? 's' : ''}
                    </Typography>
                  </MenuItem>
                ))
              ) : (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <SoftTypography variant="body2" color="text.secondary">
                    No sites available
                  </SoftTypography>
                </Box>
              )}
            </Popover>
          </SoftBox>
          {isMini ? null : (
            <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
              <Box sx={{ 
                display: { xs: 'none', lg: 'block' },
                position: 'absolute',
                right: '320px',
                top: '50%',
                transform: 'translateY(-50%)'
              }}>
                {isJourneyPath() && (
                  <Stepper
                    activeStep={currentStep}
                    alternativeLabel
                    sx={{
                      my: 0,
                      mt: 1.5,
                      height: 'fit-content',
                      alignItems: 'center',
                      minHeight: 'unset',
                      minWidth: '550px',
                      '& .MuiStepIcon-root': {
                        '&.Mui-active': {
                          color: '#DE63A1',
                          '& .MuiStepIcon-text': {
                            fill: '#fff'
                          }
                        },
                        '&.Mui-completed': {
                          color: '#5E064F'
                        },
                        color: alpha('#DE63A1', 0.4)
                      },
                      '& .MuiStepConnector-root': {
                        '& .MuiStepConnector-line': {
                          borderColor: alpha('#DE63A1', 0.3)
                        },
                        '&.Mui-active, &.Mui-completed': {
                          '& .MuiStepConnector-line': {
                            borderColor: '#DE63A1'
                          }
                        }
                      }
                    }}
                  >
                    {journey?.path === 'plus' ? ["Discover", "Define", "Design", "Direction", "Decide"].map((label, index) => (
                      <Step
                        key={label}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleStepClick(index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    )) : ["Discover", "Define", "Design"].map((label, index) => (
                      <Step
                        key={label}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleStepClick(index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
                {isSearchPath() && (
                  <Stepper
                    activeStep={currentStep}
                    alternativeLabel
                    sx={{
                      my: 0,
                      height: 'fit-content',
                      alignItems: 'center',
                      minHeight: 'unset',
                      minWidth: '490px',
                      '& .MuiStepIcon-root': {
                        '&.Mui-active': {
                          color: '#DE63A1',
                          '& .MuiStepIcon-text': {
                            fill: '#fff'
                          }
                        },
                        '&.Mui-completed': {
                          color: '#5E064F'
                        },
                        color: alpha('#DE63A1', 0.4)
                      },
                      '& .MuiStepConnector-root': {
                        '& .MuiStepConnector-line': {
                          borderColor: alpha('#DE63A1', 0.3)
                        },
                        '&.Mui-active, &.Mui-completed': {
                          '& .MuiStepConnector-line': {
                            borderColor: '#DE63A1'
                          }
                        }
                      }
                    }}
                  >
                    {["Explore", "Select", "Inquire", "Evaluate & Compare", "Sign"].map((label, index) => (
                      <Step
                        key={label}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleStepClick(index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </Box>

              <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5, height: '100%' }}>
               {/*  {!isJourneyPath() && (
                  <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
                    <SoftButton
                      variant="gradient"
                      color="primary"
                      sx={{ flexGrow: 1 }}
                      onClick={() => setOpenNewSite(true)}
                    >
                      Add Site
                    </SoftButton>
                    <SoftButton
                      disabled={!user?.isAdmin}
                      variant="gradient"
                      color="secondary"
                      sx={{ flexGrow: 1 }}
                      onClick={() => setOpenBatchAdd(true)}
                    >
                      Batch Add
                    </SoftButton>
                  </Stack>
                )} */}
                {user?.isAdmin ? (
                  <SoftSelect
                    value={company?.id ? { label: company.name, value: company.id } : ''}
                    onChange={handleCompanyChange}
                    options={companies?.map((comp) => ({
                      label: comp.name,
                      value: comp.id
                    }))}
                    sx={{ minWidth: 200 }}
                  />
                ) : (
                  <Stack sx={{ display: 'flex', height: '100%' }}>
                    <SoftTypography
                      variant="button"
                      fontWeight="medium"
                    >
                      {user?.displayName || user?.customName}
                    </SoftTypography>

                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {user?.companyName}
                    </Typography>
                  </Stack>

                )}
                <AccountPopover company={company} />
                <IconButton onClick={handleOpenMenu}>
                  <Badge badgeContent={notifications.length} color="error">
                    <Icon>notifications</Icon>
                  </Badge>
                </IconButton>
                {renderMenu()}
              </Stack>
            </SoftBox>
          )}
        </Toolbar>
      </AppBar>

      {/* Add Site Dialog */}
      <SiteDialog
        open={openNewSite}
        onClose={() => setOpenNewSite(false)}
        onSubmit={handleCreateNewSite}
        initialData={newSite}
        companyId={company?.id}
        title="Create a New Site"
        submitButtonText="Create Site"
      />

      {/* Geocoding Snackbar */}
      <SoftSnackbar
        color={geocodingSnackbarColor}
        icon="location_on"
        title="Site Creation"
        content={geocodingSnackbarMessage}
        dateTime="now"
        open={openGeocodingSnackbar}
        close={() => setOpenGeocodingSnackbar(false)}
      />

      {/* Batch Add Dialog */}
      <Dialog open={openBatchAdd} onClose={() => setOpenBatchAdd(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          <SoftBox display="flex" alignItems="center">
            <Icon fontSize="large" sx={{ color: "primary.main", mr: 1 }}>upload_file</Icon>
            <SoftTypography variant="h4" fontWeight="medium">Batch Import Sites</SoftTypography>
          </SoftBox>
          <SoftTypography variant="body2" color="text" sx={{ mt: 1 }}>
            Quickly add multiple sites by uploading a CSV file. Streamline your portfolio setup in seconds.
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <SoftBox
            sx={{
              mb: 3,
              p: 3,
              border: '1px dashed',
              borderColor: 'grey.300',
              borderRadius: 2,
              textAlign: 'center',
              backgroundColor: 'grey.50'
            }}
          >
            <SoftButton
              variant="gradient"
              color="secondary"
              component="label"
              startIcon={isUploading ? <CircularProgress size={20} color="inherit" /> : <Icon>cloud_upload</Icon>}
              disabled={isUploading}
            >
              {isUploading ? 'Processing CSV...' : 'Select CSV File'}
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={handleBatchFileUpload}
                disabled={isUploading}
              />
            </SoftButton>
            {isUploading && (
              <SoftBox mt={2}>
                <SoftBox sx={{ width: '100%', mt: 1 }}>
                  <SoftBox
                    sx={{
                      height: 4,
                      width: '100%',
                      bgcolor: 'grey.200',
                      borderRadius: 2,
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    <SoftBox
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '100%',
                        bgcolor: 'info.main',
                        width: `${uploadProgress}%`,
                        animation: 'progress 1.5s ease-in-out infinite',
                        '@keyframes progress': {
                          '0%': {
                            width: '0%',
                            left: '0%'
                          },
                          '50%': {
                            width: '70%',
                            left: '30%'
                          },
                          '100%': {
                            width: '0%',
                            left: '100%'
                          }
                        }
                      }}
                    />
                  </SoftBox>
                  <SoftTypography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
                    {uploadProgress < 100
                      ? `Analyzing CSV file...`
                      : 'Analysis complete!'}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            {batchFile && !isUploading ? (
              <SoftBox mt={2}>
                <SoftTypography variant="body2" fontWeight="medium" color="success.main">
                  <Icon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }}>check_circle</Icon>
                  {batchFile.name} successfully loaded
                </SoftTypography>
              </SoftBox>
            ) : !isUploading && (
              <SoftTypography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                Upload a CSV file with headers: name, address, city, country, zip_code, employees, square_footage, annual_rent
              </SoftTypography>
            )}
          </SoftBox>

          {batchSites.length > 0 && (
            <SoftBox>
              <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                Preview: {batchSites.length} Sites Ready to Import
              </SoftTypography>
              <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Object.keys(batchSites[0]).map((header) => (
                        <TableCell key={header}>
                          <SoftTypography variant="caption" fontWeight="bold">
                            {header}
                          </SoftTypography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {batchSites.map((site, index) => (
                      <TableRow key={index}>
                        {Object.values(site).map((value, i) => (
                          <TableCell key={i}>
                            <SoftTypography variant="caption">
                              {value}
                            </SoftTypography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </SoftBox>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, justifyContent: "space-between" }}>
          <SoftButton variant="text" color="secondary" onClick={() => setOpenBatchAdd(false)}>
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="success"
            onClick={handleCompleteBatch}
            disabled={batchSites.length === 0}
            startIcon={<Icon>rocket_launch</Icon>}
          >
            Import {batchSites.length} Sites
          </SoftButton>
        </DialogActions>
      </Dialog>

      <PathSelectionDialog
        open={openPathDialog}
        onClose={() => setOpenPathDialog(false)}
        onSave={handlePathSelection}
        journey={journey}
      />

      {/* Header Mapping Dialog */}
      <Dialog
        open={headerMappingOpen}
        onClose={() => !mappingInProgress && setHeaderMappingOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          <SoftTypography variant="h5" fontWeight="medium">
            CSV Header Mapping
          </SoftTypography>
          <SoftTypography variant="body2" color="text">
            The headers in your CSV file don't match the expected format. Please review and confirm the mapping below.
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          {mappingInProgress ? (
            <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={3}>
              <CircularProgress size={40} />
              <SoftTypography variant="button" fontWeight="regular" color="text" mt={2}>
                Processing your file...
              </SoftTypography>
              <SoftBox sx={{ width: '50%', mt: 2 }}>
                <SoftBox
                  sx={{
                    height: 4,
                    width: '100%',
                    bgcolor: 'grey.200',
                    borderRadius: 2,
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  <SoftBox
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      height: '100%',
                      bgcolor: 'info.main',
                      width: '60%',
                      animation: 'progress 1.5s ease-in-out infinite',
                      '@keyframes progress': {
                        '0%': {
                          width: '0%',
                          left: '0%'
                        },
                        '50%': {
                          width: '70%',
                          left: '30%'
                        },
                        '100%': {
                          width: '0%',
                          left: '100%'
                        }
                      }
                    }}
                  />
                </SoftBox>
                <SoftTypography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
                  This may take a moment as we analyze your data...
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          ) : (
            <SoftBox>
              {/* Sample Data Preview */}
              {sampleData.length > 0 && (
                <SoftBox mb={3}>
                  <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                    Sample Data Preview
                  </SoftTypography>
                  <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto' }}>
                    <Table size="small" stickyHeader sx={{ tableLayout: 'fixed', width: '100%', minWidth: '100%' }}>
                      <colgroup>
                        {originalHeaders.map((header, index) => (
                          <col key={`col-${index}`} style={{ width: `${100 / originalHeaders.length}%` }} />
                        ))}
                      </colgroup>
                      <TableHead sx={{ width: "100%", display: "table-header-group" }}>
                        <TableRow>
                          {originalHeaders.map((header) => (
                            <TableCell
                              key={header}
                              sx={{
                                bgcolor: 'background.paper',
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                p: 1
                              }}
                            >
                              <SoftTypography variant="caption" fontWeight="bold">
                                {header}
                              </SoftTypography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sampleData.map((row, index) => (
                          <TableRow key={index}>
                            {originalHeaders.map((header) => (
                              <TableCell key={`${index}-${header}`}>
                                <SoftTypography variant="caption">
                                  {formatCellValue(row[header])}
                                </SoftTypography>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SoftBox>
              )}

              {/* Header Mapping Table */}
              <SoftBox>
                <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                  Header Mapping
                </SoftTypography>
                <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
                  <Table size="small" sx={{ tableLayout: 'fixed', width: '100%', minWidth: '100%' }}>
                    <colgroup>
                      <col style={{ width: '40%' }} />
                      <col style={{ width: '40%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <TableHead sx={{ width: "100%", display: "table-header-group" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            bgcolor: (theme) => theme.palette.grey[100],
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                          }}
                        >
                          <SoftTypography variant="caption" fontWeight="bold">
                            CSV Header
                          </SoftTypography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            bgcolor: (theme) => theme.palette.grey[100],
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                          }}
                        >
                          <SoftTypography variant="caption" fontWeight="bold">
                            Maps To
                          </SoftTypography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            bgcolor: (theme) => theme.palette.grey[100],
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                          }}
                        >
                          <SoftTypography variant="caption" fontWeight="bold">
                            Status
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {originalHeaders.map((header, idx) => (
                        <TableRow
                          key={header}
                          sx={{
                            backgroundColor: idx % 2 === 0 ? 'transparent' : (theme) => theme.palette.grey[50],
                            '&:hover': { backgroundColor: (theme) => theme.palette.grey[100] }
                          }}
                        >
                          <TableCell>
                            <SoftTypography variant="body2" fontWeight="medium">
                              {header}
                            </SoftTypography>
                          </TableCell>
                          <TableCell>
                            <SoftSelect
                              value={customMapping[header] || ''}
                              onChange={(e) => handleMappingChange(header, e.target.value)}
                              options={[
                                { value: '', name: 'Ignore this column' },
                                ...targetHeaders.map(th => ({ value: th, name: th }))
                              ]}
                              placeholder="Select target field"
                              sx={{ minWidth: '100%' }}
                            />
                          </TableCell>
                          <TableCell>
                            {customMapping[header] ? (
                              <Chip
                                size="small"
                                label="Mapped"
                                color="success"
                                variant="outlined"
                                sx={{ fontWeight: 'medium' }}
                              />
                            ) : (
                              <Chip
                                size="small"
                                label="Ignored"
                                color="default"
                                variant="outlined"
                                sx={{ fontWeight: 'medium' }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </SoftBox>

              {/* Target Headers Status */}
              <SoftBox mt={3} p={2} border="1px solid" borderColor="grey.200" borderRadius={1} bgcolor="grey.50">
                <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                  Target Headers Status
                </SoftTypography>
                <SoftBox display="flex" flexWrap="wrap" gap={1} mb={2}>
                  {targetHeaders.map(targetHeader => {
                    const isMapped = Object.values(customMapping).includes(targetHeader);
                    return (
                      <Chip
                        key={targetHeader}
                        label={targetHeader}
                        color={isMapped ? "success" : "warning"}
                        variant={isMapped ? "filled" : "outlined"}
                        size="small"
                        sx={{
                          mb: 1,
                          fontWeight: 'medium',
                          '& .MuiChip-label': { px: 1 }
                        }}
                        icon={isMapped ? <Icon fontSize="small">check_circle</Icon> : <Icon fontSize="small">warning</Icon>}
                      />
                    );
                  })}
                </SoftBox>
                {targetHeaders.some(th => !Object.values(customMapping).includes(th)) && (
                  <SoftBox
                    p={1.5}
                    bgcolor="warning.light"
                    borderRadius={1}
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Icon color="warning">warning</Icon>
                    <SoftTypography variant="body2" color="warning.dark" fontWeight="medium">
                      Warning: Some target headers are not mapped. These columns will be empty in the imported data.
                    </SoftTypography>
                  </SoftBox>
                )}
              </SoftBox>
            </SoftBox>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, justifyContent: "space-between" }}>
          <SoftButton
            variant="text"
            color="secondary"
            onClick={() => setHeaderMappingOpen(false)}
            disabled={mappingInProgress}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={handleConfirmMapping}
            disabled={mappingInProgress}
            startIcon={mappingInProgress ? <CircularProgress size={20} /> : <Icon>check_circle</Icon>}
          >
            Confirm Mapping
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  name: '',
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  name: PropTypes.string,
  onCompanyChange: PropTypes.func,
};

export default DashboardNavbar;
