import { Google, LinkedIn } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Button, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import team3 from "../../../../assets/images/team-3.jpg";
import SoftBox from "../../../../components/SoftBox";
import SoftInput from "../../../../components/SoftInput/index.js";
import SoftTypography from "../../../../components/SoftTypography";
import AnnouncementCard from "../../../../examples/Cards/AnnouncementCard";
import ProfileInfoCard from "../../../../examples/Cards/InfoCards/ProfileInfoCard";
import AnimatedStatisticsCard from "../../../../examples/Cards/StatisticsCards/AnimatedStatisticsCard";
import MiniStatisticsCard from "../../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "../../../../examples/Footer";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import ProfilesList from "../../../../examples/Lists/ProfilesList";
import useAuth from "../../../../hooks/useAuth";
import { getJourney, getJourneys } from "../../../../redux/slices/site";
import { updateUser } from "../../../../redux/slices/user";
import { dispatch } from "../../../../redux/store";
import Header from "../../components/Header";
import ScenarioList from "../../projects/general/components/ScenarioList";
import TodoList from "../../projects/general/components/TodoList";
import PlatformSettings from "./components/PlatformSettings";
import profilesListData from "./data/profilesListData";

function Overview() {
  const { user } = useAuth();
  const { journeys, journey } = useSelector((state) => state.site);
  const { userProfile } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { workplaces } = useSelector((state) => state.workplace);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const previousCost = 397800;
  const currentCost = 320000;
  const savings = 67800;
  const fees = 3800;
  
  // New state for editable fields - initialize from userProfile if available
  const [role, setRole] = useState(userProfile?.role || user?.role || '');
  const [customName, setCustomName] = useState(userProfile?.customName || user?.customName || '');

  // Update local state when userProfile changes
  useEffect(() => {
    if (userProfile) {
      if (userProfile.role) setRole(userProfile.role);
      if (userProfile.customName) setCustomName(userProfile.customName);
    }
  }, [userProfile]);

  // Split user's display name into first and last name
  const firstName = user?.displayName ? user.displayName.split(' ')[0] : '';
  const lastName = user?.displayName ? user.displayName.split(' ').slice(1).join(' ') : '';

  const handleSelect = (newValue) => {
    setTabValue(newValue);
  }
  
  const handleSelectJourney = (id) => {
    console.log('id', id);
    dispatch(getJourney(id));
    //dispatch(getJourneyMgmtSubmissions(user.companyHubspot, id, workplaces));
  }

  async function createJourney(id, name) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/journeys/create`;
    try {
      const body = {
        company_id: user.companyHubspot,
        version: 2
      };
      const response = await axios.post(url, { company_id: id });
      console.log(response.data); // Assuming you want to log each successful response
      dispatch(getJourneys());
      dispatch(getJourney(response.data.id));
      setOpen(true);
      console.log('open');
      //updateUser(response.data.company.id, revisedCompany.name);
      //onCompany(company);
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  function updateJourney(par) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`;
    axios.put(url, par)
      .then(() => {
        console.log('UPDATED JoURNEY');
        dispatch(getJourney(journey.id));
      })
      .catch((error) => console.log(error.message));
  }

  const handleNewProject = () => {
    console.log('new project');
    createJourney(user?.companyHubspot, user?.company_name);
  }
  const handleSaveName = () => {
    console.log('save name');
    setOpen(false);
    updateJourney({ label: name });
    navigate(`/`);
  }
  const handleInput = ({ currentTarget }) => setName(currentTarget.value)
  
  // New handlers for user profile fields
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  }
  
  const handleCustomNameChange = (event) => {
    setCustomName(event.target.value);
  }
  
  const handleSaveProfile = () => {
    // Update user profile with new role and custom name
    dispatch(updateUser({ role, customName }));
  }
  
  return (
    <DashboardLayout>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <SoftBox>
            <SoftTypography variant="h6" fontWeight="medium">
              Name your project
            </SoftTypography>
            <SoftInput onChange={handleInput} />
            <Button onClick={handleSaveName}>Let's go</Button>
          </SoftBox>
        </DialogContent>
      </Dialog>
      <Header onSelect={handleSelect} />
     
      {tabValue === 0 && (
        <>
          <SoftBox mt={3} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={4}>
                <PlatformSettings />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <ProfileInfoCard
                  title="profile information"
                  info={{
                    firstName: firstName,
                    lastName: lastName,
                    email: user?.email,
                    image: user?.photoURL || team3,
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/",
                      icon: <Google />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/",
                      icon: <LinkedIn />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
                
                {/* New editable fields section */}
                <SoftBox mt={3}>
                  <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                    Additional Information
                  </SoftTypography>
                  
                  <SoftBox mb={2}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                      Role
                    </SoftTypography>
                    <TextField
                      fullWidth
                      value={role}
                      onChange={handleRoleChange}
                      placeholder="Your role"
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </SoftBox>
                  
                  <SoftBox mb={2}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                      Custom Name
                    </SoftTypography>
                    <TextField
                      fullWidth
                      value={customName}
                      onChange={handleCustomNameChange}
                      placeholder="Custom name"
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </SoftBox>
                  
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSaveProfile}
                    sx={{ mt: 2 }}
                  >
                    Save Changes
                  </Button>
                </SoftBox>
              </Grid>
              <Grid item xs={12} xl={4}>
                <ProfilesList title="workplace projects" profiles={profilesListData} />
              </Grid>
            </Grid>
          </SoftBox>
        </>
      )}
      {tabValue === 2 && (
        <SoftBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <AnimatedStatisticsCard
                    title="customers"
                    count="5"
                    color="success"
                    percentage={{
                      color: "dark",
                      label: `${Math.round(savings / previousCost * 100)}% of goal`,
                    }}
                    action={{
                      type: "internal",
                      route: "/pages/projects/general",
                      label: "view more",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <AnimatedStatisticsCard
                    title="leads"
                    count="15"
                    color="warning"
                    percentage={{
                      color: "dark",
                      label: `${Math.round(fees / savings * 100)}% of goal`,
                    }}
                    action={{
                      type: "internal",
                      route: "/pages/projects/general",
                      label: "view more",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SoftBox mb={2}>
                    <MiniStatisticsCard
                      title={{ fontWeight: "medium", text: "revenue" }}
                      count="$320,000"
                      percentage={{ color: "error", text: "-17%" }}
                      icon={{ color: "dark", component: "local_atm" }}
                      direction="left"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <MiniStatisticsCard
                      title={{ fontWeight: "medium", text: "ACV" }}
                      count="1,500"
                      percentage={{ color: "error", text: "-25%" }}
                      icon={{ color: "dark", component: "local_atm" }}
                      direction="left"
                    />
                  </SoftBox>
                  <MiniStatisticsCard
                    title={{ fontWeight: "medium", text: "ACL" }}
                    count="180"
                    percentage={{ color: "success", text: "+25%" }}
                    icon={{ color: "dark", component: "emoji_events" }}
                    direction="left"
                  />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <SoftBox my={2}>
                  <TodoList />
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox my={2}>
                  "Followers"
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox my={2}>
                  "Analytics"
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox my={2}>
                  <ScenarioList />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AnnouncementCard
                    by={{ image: team3, name: "Jennica Lenning", date: "2h ago" }}
                    badge={{ color: "info", label: "recommendation" }}
                    title="Ericsson"
                    description="Utilization of unit 3 has dropped significantly and the office space might be considered as empty by many."
                    value={{ type: "$", amount: "3,000", method: "YCV" }}
                    action={{ type: "internal", route: "/pages/projects/general", label: "follow up" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AnnouncementCard
                    by={{ image: team3, name: "Jesper Cronsioe", date: "2h ago" }}
                    badge={{ color: "info", label: "recommendation" }}
                    title="Marketing plan"
                    description="Utilization of unit 3 has dropped significantly and the office space might be considered as empty by many."
                    value={{ type: "$", amount: "N/A", method: "YCV" }}
                    action={{ type: "internal", route: "/pages/projects/general", label: "follow up" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <ProgressLineChart
                    icon="date_range"
                    title="Tasks"
                    count={480}
                    progress={60}
                    chart={progressLineChartData}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  {/* <ProgressDoughnutChart
                    icon="workspace_premium"
                    title="projects"
                    count={115}
                    chart={progressDoughnutChartData}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
