/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import { Icon, IconButton, Stack, Menu, MenuItem, Collapse, Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from "@mui/material";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import SoftAvatar from "../../../components/SoftAvatar";
import SoftBox from "../../../components/SoftBox";
import SoftInput from "../../../components/SoftInput";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import { useSelector } from "react-redux";
import { dispatch } from "../../../redux/store";
import { updateDepartment } from "../../../redux/slices/company";
import { CircularProgress } from "@mui/material";
import React from "react";

function Departments({ title, deps, onChange, isFirstJourney }) {
  const { company } = useSelector((state) => state.company);
  const [departments, setDepartments] = useState(deps || []);
  const [newDepartment, setNewDepartment] = useState('');
  const [parentDepartment, setParentDepartment] = useState(null);
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [successStates, setSuccessStates] = useState({});
  const [expandedDepartments, setExpandedDepartments] = useState({});
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  
  // New state for upload functionality
  const [uploadedDepartments, setUploadedDepartments] = useState([]);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [processingUpload, setProcessingUpload] = useState(false);
  const [uploadSuggestions, setUploadSuggestions] = useState([]);
  const [showSuggestionsDialog, setShowSuggestionsDialog] = useState(false);
  const fileInputRef = useRef(null);

  // Track departments that need employee count updates
  const [departmentsToUpdate, setDepartmentsToUpdate] = useState({});
  const [employeeSums, setEmployeeSums] = useState({});
  const updateTimeoutsRef = useRef({});

  useEffect(() => {
    console.log('deps', deps);
    setDepartments(deps || []);
  }, [deps]);

  useEffect(() => {
    console.log('deps', deps);
    //onChange(departments || []);
  }, [departments]);

  // Effect to update parent department employee counts
  useEffect(() => {
    // Calculate employee sums for all departments
    const newSums = {};
    
    // First pass: calculate sums for leaf departments
    departments.forEach(dept => {
      if (!departments.some(d => d.parent_id === dept.id)) {
        // This is a leaf department
        newSums[dept.id] = parseInt(dept.employees) || 0;
      }
    });
    
    // Second pass: calculate sums for parent departments (bottom-up)
    // Sort departments by level in descending order to ensure we process children before parents
    const sortedDepts = [...departments].sort((a, b) => b.level - a.level);
    
    sortedDepts.forEach(dept => {
      if (departments.some(d => d.parent_id === dept.id)) {
        // This is a parent department
        const childDepts = departments.filter(d => d.parent_id === dept.id);
        const sum = childDepts.reduce((total, child) => total + (newSums[child.id] || 0), 0);
        newSums[dept.id] = sum;
        
        // If the sum has changed, queue an update
        if (sum.toString() !== dept.employees) {
          // Clear any existing timeout for this department
          if (updateTimeoutsRef.current[dept.id]) {
            clearTimeout(updateTimeoutsRef.current[dept.id]);
          }
          
          // Set a new timeout to update the department
          updateTimeoutsRef.current[dept.id] = setTimeout(() => {
            updateCompanyDepartment(dept.id, { employees: sum.toString() });
            
            // Update local state
            setDepartments(prev => prev.map(d => 
              d.id === dept.id ? { ...d, employees: sum.toString() } : d
            ));
            
            // Remove the timeout reference
            delete updateTimeoutsRef.current[dept.id];
          }, 500);
        }
      }
    });
    
    setEmployeeSums(newSums);
  }, [departments]);
  
  // Clean up timeouts on unmount
  useEffect(() => {
    return () => {
      Object.values(updateTimeoutsRef.current).forEach(timeout => clearTimeout(timeout));
    };
  }, []);

  const handleAdd = () => {
    console.log('add', newDepartment);
    createDepartment(newDepartment, parentDepartment);
  }

  const handleMenuOpen = (event, department) => {
    setMenuAnchor(event.currentTarget);
    setSelectedDepartment(department);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedDepartment(null);
  };

  const toggleExpand = (departmentId) => {
    setExpandedDepartments(prev => ({
      ...prev,
      [departmentId]: !prev[departmentId]
    }));
  };

  const handleAddSubdepartment = () => {
    setParentDepartment(selectedDepartment.id);
    handleMenuClose();
  };

  const handleMoveUp = async () => {
    if (selectedDepartment && selectedDepartment.parent_id) {
      // Find the parent's parent
      const parentDepartment = departments.find(d => d.id === selectedDepartment.parent_id);
      const grandparentId = parentDepartment ? parentDepartment.parent_id : null;
      
      // Update the department to have the same parent as its current parent
      await updateCompanyDepartment(selectedDepartment.id, { 
        parent_id: grandparentId,
        level: grandparentId ? departments.find(d => d.id === grandparentId)?.level + 1 || 1 : 0
      });
      
      // Refresh departments after the update
      const updatedDepartments = departments.map(d => {
        if (d.id === selectedDepartment.id) {
          return { ...d, parent_id: grandparentId, level: grandparentId ? departments.find(dep => dep.id === grandparentId)?.level + 1 || 1 : 0 };
        }
        return d;
      });
      
      setDepartments(updatedDepartments);
    }
    handleMenuClose();
  };

  const handleMoveDown = async (newParentId) => {
    if (selectedDepartment) {
      // Update the department to have the selected department as parent
      await updateCompanyDepartment(selectedDepartment.id, { 
        parent_id: newParentId,
        level: departments.find(d => d.id === newParentId)?.level + 1 || 1
      });
      
      // Refresh departments after the update
      const updatedDepartments = departments.map(d => {
        if (d.id === selectedDepartment.id) {
          return { ...d, parent_id: newParentId, level: departments.find(dep => dep.id === newParentId)?.level + 1 || 1 };
        }
        return d;
      });
      
      setDepartments(updatedDepartments);
    }
    handleMenuClose();
  };

  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setProcessingUpload(true);
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const csvData = e.target.result;
        console.log("File content sample:", csvData.substring(0, 200)); // Log a sample of the file content
        const parsedData = parseCSV(csvData);
        setUploadedDepartments(parsedData);
        processDepartmentData(parsedData);
      } catch (error) {
        console.error("Error processing file:", error);
        // Show error to user
        alert(`Error processing file: ${error.message}`);
      } finally {
        setProcessingUpload(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };
    
    reader.readAsText(file);
  };

  // Parse CSV data
  const parseCSV = (csvText) => {
    const lines = csvText.split('\n').filter(line => line.trim());
    if (lines.length === 0) {
      throw new Error("File is empty or contains no valid data");
    }
    
    const firstLine = lines[0];
    console.log("First line:", firstLine);
    
    // Try different delimiters and see which one gives the most columns
    const delimiters = ['\t', ',', ';', ' '];
    let bestDelimiter = '\t';
    let maxColumns = 0;
    
    delimiters.forEach(delimiter => {
      const columnCount = firstLine.split(delimiter).filter(col => col.trim()).length;
      console.log(`Delimiter "${delimiter}" gives ${columnCount} columns`);
      if (columnCount > maxColumns) {
        maxColumns = columnCount;
        bestDelimiter = delimiter;
      }
    });
    
    console.log(`Selected delimiter: "${bestDelimiter}" with ${maxColumns} columns`);
    
    if (maxColumns < 2) {
      // If no good delimiter found, try to be smart about it
      // Check if the data might be fixed-width format
      if (firstLine.includes('level') && firstLine.includes('count')) {
        return parseFixedWidthFormat(lines);
      }
      throw new Error(`Could not detect a valid delimiter. File must have at least 2 columns separated by tab, comma, semicolon, or space.`);
    }
    
    // Split headers using the detected delimiter
    const headers = firstLine.split(bestDelimiter).filter(h => h.trim());
    
    // The last column is always the count, all other columns are department levels
    const countIndex = headers.length - 1;
    
    const result = [];
    for (let i = 1; i < lines.length; i++) {
      // Split values using the detected delimiter and filter out empty entries
      const values = lines[i].split(bestDelimiter).filter(v => v.trim());
      
      // Skip rows that don't have enough columns
      if (values.length < 2) {
        console.log(`Skipping row ${i+1}: insufficient columns`);
        continue;
      }
      
      // Create a department entry with dynamic levels
      const entry = {
        levels: [],
        count: parseInt(values[values.length - 1], 10) || 0
      };
      
      // Store each level value (all except the last column which is the count)
      for (let level = 0; level < values.length - 1; level++) {
        entry.levels.push(values[level].trim());
      }
      
      result.push(entry);
    }
    
    if (result.length === 0) {
      throw new Error("No valid data rows found in the file");
    }
    
    console.log(`Successfully parsed ${result.length} department entries`);
    return result;
  };
  
  // Parse fixed-width format (as a fallback)
  const parseFixedWidthFormat = (lines) => {
    console.log("Attempting to parse as fixed-width format");
    
    // Find column positions by analyzing the header line
    const headerLine = lines[0];
    const columnPositions = [];
    
    // Find positions of "level" and "count" columns
    let levelRegex = /level\s*\d+/g;
    let countRegex = /count/i;
    let match;
    
    // Find all level columns
    while ((match = levelRegex.exec(headerLine)) !== null) {
      columnPositions.push({
        name: match[0].trim(),
        start: match.index,
        end: match.index + match[0].length
      });
    }
    
    // Find count column
    match = countRegex.exec(headerLine);
    if (match) {
      columnPositions.push({
        name: 'count',
        start: match.index,
        end: match.index + match[0].length
      });
    }
    
    // Sort by start position
    columnPositions.sort((a, b) => a.start - b.start);
    
    // Calculate column widths
    for (let i = 0; i < columnPositions.length - 1; i++) {
      columnPositions[i].end = columnPositions[i + 1].start;
    }
    
    console.log("Detected columns:", columnPositions);
    
    if (columnPositions.length < 2) {
      throw new Error("Could not detect enough columns in fixed-width format");
    }
    
    const result = [];
    // Parse data rows
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      if (line.length < columnPositions[columnPositions.length - 1].start) {
        console.log(`Skipping row ${i+1}: insufficient length`);
        continue;
      }
      
      const entry = {
        levels: [],
        count: 0
      };
      
      // Extract values for each column
      for (let j = 0; j < columnPositions.length; j++) {
        const col = columnPositions[j];
        const value = line.substring(col.start, col.end).trim();
        
        if (col.name === 'count') {
          entry.count = parseInt(value, 10) || 0;
        } else {
          entry.levels.push(value);
        }
      }
      
      result.push(entry);
    }
    
    if (result.length === 0) {
      throw new Error("No valid data rows found in the file");
    }
    
    console.log(`Successfully parsed ${result.length} department entries using fixed-width format`);
    return result;
  };

  // Process department data and generate suggestions
  const processDepartmentData = (uploadedData) => {
    // Convert the flat uploaded data into a hierarchical structure
    const hierarchicalData = buildHierarchicalStructure(uploadedData);
    
    // Generate suggestions based on the hierarchical structure
    const suggestions = generateSuggestionsFromHierarchy(hierarchicalData);
    
    setUploadSuggestions(suggestions);
    if (suggestions.length > 0) {
      setShowSuggestionsDialog(true);
    } else {
      alert("No changes needed. All departments already exist with the correct structure and employee counts.");
    }
  };
  
  // Build a hierarchical structure from the flat uploaded data
  const buildHierarchicalStructure = (uploadedData) => {
    // Create a tree structure to represent the department hierarchy
    const tree = {
      children: new Map(), // Map of department name to its node at level 0
    };
    
    // Process each row in the uploaded data
    uploadedData.forEach(item => {
      let currentNode = tree;
      
      // Process each level in the hierarchy
      for (let level = 0; level < item.levels.length; level++) {
        const deptName = item.levels[level];
        if (!deptName) continue; // Skip empty department names
        
        // If this department doesn't exist in the current node's children, add it
        if (!currentNode.children.has(deptName)) {
          currentNode.children.set(deptName, {
            name: deptName,
            level,
            children: new Map(),
            employees: level === item.levels.length - 1 ? item.count : 0
          });
        } else if (level === item.levels.length - 1) {
          // If this is the lowest level and the department already exists,
          // update the employee count to the maximum value
          const existingNode = currentNode.children.get(deptName);
          const currentCount = parseInt(existingNode.employees) || 0;
          const newCount = parseInt(item.count) || 0;
          existingNode.employees = Math.max(currentCount, newCount);
        }
        
        // Move to the next level in the hierarchy
        currentNode = currentNode.children.get(deptName);
      }
    });
    
    return tree;
  };
  
  // Generate suggestions from the hierarchical structure
  const generateSuggestionsFromHierarchy = (hierarchyTree) => {
    const suggestions = [];
    const suggestedDepartments = new Map(); // Track suggested departments to avoid duplicates
    
    // Recursive function to traverse the hierarchy and generate suggestions
    const traverseHierarchy = (node, parentId = null, parentName = null, path = []) => {
      if (!node.children) return;
      
      // Process each department at the current level
      for (const [deptName, deptNode] of node.children.entries()) {
        const level = deptNode.level;
        const deptKey = `${deptName}-${level}`;
        
        // Find if this department already exists at this level with the correct parent
        let existingDept = departments.find(d => 
          d.name === deptName && 
          d.level === level && 
          (level === 0 || (parentId ? d.parent_id === parentId : true))
        );
        
        // Check if we've already suggested this department
        const alreadySuggested = suggestedDepartments.has(deptKey);
        
        if (!existingDept && !alreadySuggested) {
          // Department doesn't exist and hasn't been suggested yet, suggest adding it
          const newSuggestion = {
            type: 'add',
            name: deptName,
            level,
            parent_id: parentId,
            parent_name: parentName,
            path: [...path, deptName], // Store the full path for display in the tree view
            employees: deptNode.children.size === 0 ? deptNode.employees.toString() : '',
            status: 'pending',
            selected: true, // Auto-select new suggestions
            children: [] // For building the tree view
          };
          
          suggestions.push(newSuggestion);
          suggestedDepartments.set(deptKey, newSuggestion);
          
          // Recursively process children with this suggestion as the parent
          traverseHierarchy(deptNode, null, deptName, [...path, deptName]);
        } else if (existingDept && deptNode.children.size === 0) {
          // This is a leaf department (no children)
          if (existingDept.employees !== deptNode.employees.toString()) {
            // If count is different, suggest update (only if not already suggested)
            const updateKey = `update-${deptKey}`;
            if (!suggestedDepartments.has(updateKey)) {
              const updateSuggestion = {
                type: 'update',
                id: existingDept.id,
                name: deptName,
                level,
                path: [...path, deptName], // Store the full path for display in the tree view
                current_employees: existingDept.employees,
                new_employees: deptNode.employees.toString(),
                status: 'pending',
                selected: true // Auto-select update suggestions
              };
              
              suggestions.push(updateSuggestion);
              suggestedDepartments.set(updateKey, updateSuggestion);
            }
          }
          
          // No need to traverse further for existing departments
        } else {
          // Department exists, continue traversing with this as the parent
          traverseHierarchy(deptNode, existingDept ? existingDept.id : null, deptName, [...path, deptName]);
        }
      }
    };
    
    // Start traversal from the root
    traverseHierarchy(hierarchyTree);
    
    // Build parent-child relationships for the tree view
    buildSuggestionTree(suggestions);
    
    return suggestions;
  };
  
  // Build a tree structure from the flat suggestions list for display
  const buildSuggestionTree = (suggestions) => {
    // First, create a map of path string to suggestion
    const pathMap = new Map();
    suggestions.forEach(suggestion => {
      if (suggestion.path) {
        pathMap.set(suggestion.path.join('/'), suggestion);
      }
    });
    
    // Then, build the parent-child relationships
    suggestions.forEach(suggestion => {
      if (suggestion.path && suggestion.path.length > 1) {
        // This suggestion has a parent
        const parentPath = suggestion.path.slice(0, -1).join('/');
        const parentSuggestion = pathMap.get(parentPath);
        
        if (parentSuggestion && parentSuggestion.children) {
          // Add this suggestion as a child of its parent
          parentSuggestion.children.push(suggestion);
        }
      }
    });
  };

  // Apply all selected suggestions
  const applySelectedSuggestions = async () => {
    const selectedSuggestions = uploadSuggestions.filter(s => s.selected && s.status === 'pending');
    
    // Sort suggestions by level to ensure parents are created before children
    const sortedSuggestions = [...selectedSuggestions].sort((a, b) => a.level - b.level);
    
    // Group suggestions by type (updates can be processed in any order)
    const addSuggestions = sortedSuggestions.filter(s => s.type === 'add');
    const updateSuggestions = sortedSuggestions.filter(s => s.type === 'update');
    
    // Process add suggestions first, level by level
    const createdDepartments = new Map(); // Track newly created departments
    
    // Process adds by level
    const maxLevel = Math.max(...addSuggestions.map(s => s.level), 0);
    for (let level = 0; level <= maxLevel; level++) {
      const levelSuggestions = addSuggestions.filter(s => s.level === level);
      
      for (let i = 0; i < levelSuggestions.length; i++) {
        const suggestion = levelSuggestions[i];
        const suggestionIndex = uploadSuggestions.findIndex(s => 
          s.name === suggestion.name && 
          s.level === suggestion.level && 
          (s.path ? s.path.join('/') === suggestion.path.join('/') : true)
        );
        
        if (suggestionIndex !== -1) {
          try {
            // If parent was just created in a previous step, use the new ID
            let parentId = suggestion.parent_id;
            
            // If this suggestion has a parent path, try to find the parent
            if (suggestion.level > 0 && suggestion.path) {
              const parentPath = suggestion.path.slice(0, -1).join('/');
              
              // First check if we've created this parent in a previous step
              if (createdDepartments.has(parentPath)) {
                parentId = createdDepartments.get(parentPath);
                console.log(`Found parent ID ${parentId} for ${suggestion.name} from created departments map`);
              } else {
                // Otherwise, look for an existing parent in the database
                const parentName = suggestion.path[suggestion.path.length - 2];
                const existingParent = departments.find(d => 
                  d.name === parentName && 
                  d.level === suggestion.level - 1
                );
                if (existingParent) {
                  parentId = existingParent.id;
                  console.log(`Found parent ID ${parentId} for ${suggestion.name} from existing departments`);
                }
              }
            }
            
            // Create the department with the correct parent_id
            const newDept = await createDepartmentAndReturn(suggestion.name, parentId, suggestion.employees);
            
            // Store the new department ID for potential child departments
            if (newDept && newDept.id) {
              createdDepartments.set(suggestion.path.join('/'), newDept.id);
            }
            
            // Update suggestion status
            const updatedSuggestions = [...uploadSuggestions];
            updatedSuggestions[suggestionIndex] = { ...suggestion, status: 'applied' };
            setUploadSuggestions(updatedSuggestions);
          } catch (error) {
            console.error("Error creating department:", error);
            // Update suggestion status to failed
            const updatedSuggestions = [...uploadSuggestions];
            updatedSuggestions[suggestionIndex] = { ...suggestion, status: 'failed' };
            setUploadSuggestions(updatedSuggestions);
          }
        }
      }
    }
    
    // Process update suggestions
    for (let i = 0; i < updateSuggestions.length; i++) {
      const suggestion = updateSuggestions[i];
      const suggestionIndex = uploadSuggestions.findIndex(s => 
        s.id === suggestion.id
      );
      
      if (suggestionIndex !== -1) {
        try {
          // Update existing department
          await updateCompanyDepartment(suggestion.id, { employees: suggestion.new_employees });
          
          // Update suggestion status
          const updatedSuggestions = [...uploadSuggestions];
          updatedSuggestions[suggestionIndex] = { ...suggestion, status: 'applied' };
          setUploadSuggestions(updatedSuggestions);
        } catch (error) {
          console.error("Error applying update suggestion:", error);
          
          // Update suggestion status to failed
          const updatedSuggestions = [...uploadSuggestions];
          updatedSuggestions[suggestionIndex] = { ...suggestion, status: 'failed' };
          setUploadSuggestions(updatedSuggestions);
        }
      }
    }
    
    // Check if all suggestions have been processed
    const allProcessed = uploadSuggestions.every(s => 
      !s.selected || s.status === 'applied' || s.status === 'failed'
    );
    
    // If all selected suggestions have been processed, close the dialog
    if (allProcessed) {
      setTimeout(() => {
        setShowSuggestionsDialog(false);
      }, 1000); // Give user a moment to see the results before closing
    }
  };

  // Render a suggestion in the tree view
  const renderSuggestionNode = (suggestion, depth = 0) => {
    const hasChildren = suggestion.children && suggestion.children.length > 0;
    
    return (
      <SoftBox key={`${suggestion.name}-${suggestion.level}-${depth}`} ml={depth * 3}>
        <SoftBox component="li" display="flex" alignItems="center" py={1} mb={1}>
          <Checkbox 
            checked={!!suggestion.selected}
            onChange={() => {
              const index = uploadSuggestions.findIndex(s => 
                s.name === suggestion.name && 
                s.level === suggestion.level && 
                (s.path ? s.path.join('/') === suggestion.path.join('/') : true)
              );
              if (index !== -1) {
                toggleSuggestionSelection(index);
              }
            }}
            disabled={suggestion.status !== 'pending'}
            sx={{ ml: 1 }}
          />
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow={1}
            ml={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <SoftTypography variant="button" fontWeight="medium">
                {suggestion.name}
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                {suggestion.type === 'add' ? '(New)' : '(Update)'}
              </SoftTypography>
            </Stack>
          </SoftBox>
          {suggestion.type === 'add' && (
            <SoftBox width="100px" mr={2}>
              <SoftTypography variant="caption">
                Employees: {suggestion.employees || '0'}
              </SoftTypography>
            </SoftBox>
          )}
          {suggestion.type === 'update' && (
            <SoftBox width="200px" mr={2}>
              <SoftTypography variant="caption">
                Employees: {suggestion.current_employees || '0'} → {suggestion.new_employees || '0'}
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox width="80px" textAlign="right">
            {suggestion.status === 'applied' ? (
              <Icon sx={{ color: 'success.main' }}>check_circle</Icon>
            ) : suggestion.status === 'failed' ? (
              <Icon sx={{ color: 'error.main' }}>error</Icon>
            ) : (
              <SoftTypography variant="caption">Pending</SoftTypography>
            )}
          </SoftBox>
        </SoftBox>
        
        {hasChildren && (
          <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0} pl={3}>
            {suggestion.children.map(child => renderSuggestionNode(child, depth + 1))}
          </SoftBox>
        )}
      </SoftBox>
    );
  };

  // Get root-level suggestions for the tree view
  const getRootSuggestions = () => {
    return uploadSuggestions.filter(s => s.level === 0 || !s.path || s.path.length === 1);
  };

  // Toggle selection of a suggestion
  const toggleSuggestionSelection = (index) => {
    const updatedSuggestions = [...uploadSuggestions];
    updatedSuggestions[index] = { 
      ...updatedSuggestions[index], 
      selected: !updatedSuggestions[index].selected 
    };
    setUploadSuggestions(updatedSuggestions);
  };

  // Modified createDepartment that returns the created department
  async function createDepartmentAndReturn(name, parentId = null, employees = '') {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companydepartments/create`;
    try {
      // Calculate the level based on parent
      const level = parentId ? (departments.find(d => d.id === parentId)?.level + 1 || 1) : 0;
      
      console.log(`Creating department: ${name}, parent_id: ${parentId}, level: ${level}`);
      
      const response = await axios.post(url, { 
        company_id: company.id, 
        name,
        parent_id: parentId,
        level,
        employees
      });
      
      const newDept = response.data;
      // Update the departments state with the new department
      setDepartments(prev => [...prev, newDept]);
      return newDept;
    } catch (error) {
      console.error(`Error creating department ${name}:`, error.message);
      throw error;
    }
  }

  // Modified createDepartment to accept employees count
  async function createDepartment(name, parentId = null, employees = '') {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companydepartments/create`;
    try {
      // Calculate the level based on parent
      const level = parentId ? (departments.find(d => d.id === parentId)?.level + 1 || 1) : 0;
      
      console.log(`Creating department: ${name}, parent_id: ${parentId}, level: ${level}`);
      
      await axios.post(url, { 
        company_id: company.id, 
        name,
        parent_id: parentId,
        level,
        employees
      })
        .then((res) => {
          console.log(res.data);
          setDepartments((prev) => [...prev, res.data]);
          setNewDepartment('');
          setParentDepartment(null);
        });
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
      throw error;
    }
  }

  async function updateCompanyDepartment(id, data) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companydepartments/update/${id}`;
    try {
      await axios.put(url, { company_id: company.id, ...data })
        .then((res) => {
          console.log(res.data);
          // Update the local state with the updated department
          setDepartments(prev => prev.map(dept => 
            dept.id === id ? { ...dept, ...data } : dept
          ));
        });
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }

  async function deleteDepartment(id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/companydepartments/delete/${id}`;
    await axios.delete(url)
      .then((res) => {
        console.log(res.data);
        // Remove the department and all its children
        const childrenIds = getAllChildrenIds(id);
        setDepartments((prev) => prev.filter((dep) => !childrenIds.includes(dep.id) && dep.id !== id));
      });
  }

  // Helper function to get all children IDs of a department
  const getAllChildrenIds = (departmentId) => {
    const childrenIds = [];
    const findChildren = (parentId) => {
      const children = departments.filter(d => d.parent_id === parentId);
      children.forEach(child => {
        childrenIds.push(child.id);
        findChildren(child.id);
      });
    };
    findChildren(departmentId);
    return childrenIds;
  };

  // Organize departments into a hierarchical structure
  const organizedDepartments = () => {
    // Get top-level departments (level 0 or null parent_id)
    const topLevel = departments.filter(d => d.level === 0 || !d.parent_id);
    
    // Recursive function to build the department tree
    const buildDepartmentTree = (parentId = null, level = 0) => {
      return departments
        .filter(d => d.parent_id === parentId && d.name !== 'All')
        .map(department => ({
          ...department,
          children: buildDepartmentTree(department.id, level + 1)
        }));
    };
    
    return buildDepartmentTree(null, 0);
  };

  // Recursive component to render department hierarchy
  const renderDepartment = (department, depth = 0) => {
    const hasChildren = departments.some(d => d.parent_id === department.id);
    const isExpanded = expandedDepartments[department.id];
    
    // Get the employee count to display (either from our calculated sums or from the department itself)
    const employeeCount = hasChildren ? (employeeSums[department.id] || 0).toString() : (department.employees || '0');
    
    return (
      <SoftBox key={department.id} ml={depth * 3}>
        <SoftBox component="li" display="flex" alignItems="center" py={1} mb={1}>
          <SoftBox mr={2}>
            <SoftAvatar icon={<Icon>account_circle</Icon>} alt="something here" variant="rounded" shadow="md" />
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow={1}
            mr={3}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {hasChildren && (
                <IconButton size="small" onClick={() => toggleExpand(department.id)}>
                  <Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
              )}
              <SoftTypography variant="button" fontWeight="medium">
                {department.name}
              </SoftTypography>
            </Stack>
          </SoftBox>
          <SoftBox width="120px" mr={2} sx={{ textAlign: 'right' }}>
            {hasChildren ? (
              // For parent departments, show a fixed number (sum of children)
              <SoftTypography variant="button" fontWeight="regular" sx={{ textAlign: 'right', width: '100%', display: 'block' }}>
                {employeeCount}
              </SoftTypography>
            ) : (
              // For leaf departments, show an editable input
            <SoftInput
              value={department.employees || ''}
                sx={{ 
                  '& input': { 
                    textAlign: 'right',
                    paddingRight: '8px'
                  }
                }}
              onChange={(e) => {
                console.log('onChange', e.target.value);
                const newValue = e.target.value;
                setDepartments(prev => prev.map(d =>
                  d.id === department.id ? { ...d, employees: newValue } : d
                ));

                if (updateTimeout) clearTimeout(updateTimeout);

                setLoadingStates(prev => ({ ...prev, [department.id]: true }));
                setSuccessStates(prev => ({ ...prev, [department.id]: false }));

                const timeoutId = setTimeout(async () => {
                  try {
                    await updateCompanyDepartment(department.id, { employees: newValue });

                    setLoadingStates(prev => ({ ...prev, [department.id]: false }));
                    setSuccessStates(prev => ({ ...prev, [department.id]: true }));

                    setTimeout(() => {
                      setSuccessStates(prev => ({ ...prev, [department.id]: false }));
                    }, 2000);
                  } catch (error) {
                    setLoadingStates(prev => ({ ...prev, [department.id]: false }));
                    // Optionally handle error state here
                  }
                }, 500);

                setUpdateTimeout(timeoutId);
              }}
            />
            )}
          </SoftBox>
          <IconButton onClick={(e) => handleMenuOpen(e, department)}>
            <Icon>more_vert</Icon>
          </IconButton>
          {loadingStates[department.id] ? (
            <IconButton disabled>
              <CircularProgress size={20} />
            </IconButton>
          ) : successStates[department.id] ? (
            <IconButton disabled>
              <Icon sx={{ color: 'success.main' }}>check_circle</Icon>
            </IconButton>
          ) : (
            <IconButton variant="gradient" color="error" onClick={() => deleteDepartment(department.id)}>
              <Icon>delete</Icon>
            </IconButton>
          )}
        </SoftBox>
        
        {hasChildren && isExpanded && (
          <Collapse in={isExpanded}>
            <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0} pl={3}>
              {departments
                .filter(d => d.parent_id === department.id)
                .map(childDept => renderDepartment(childDept, depth + 1))}
            </SoftBox>
          </Collapse>
        )}
      </SoftBox>
    );
  };

  // Get potential parent departments for the selected department
  const getPotentialParents = () => {
    if (!selectedDepartment) return [];
    
    // A department can't be its own parent or a child of its children
    const childrenIds = getAllChildrenIds(selectedDepartment.id);
    return departments.filter(d => 
      d.id !== selectedDepartment.id && 
      !childrenIds.includes(d.id) &&
      d.name !== 'All'
    );
  };

  return (
    <>
      <Card>
        <SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SoftTypography>
          <SoftButton 
            variant="gradient" 
            color="info" 
            size="small"
            onClick={() => setShowUploadDialog(true)}
          >
            <Icon>upload_file</Icon>&nbsp;
            Upload Departments
          </SoftButton>
        </SoftBox>
        <SoftBox p={2} pt={0}>
          <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {organizedDepartments().map(department => renderDepartment(department))}
          </SoftBox>
        </SoftBox>
        <SoftBox p={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SoftInput
              placeholder="Department Name"
              value={newDepartment}
              onChange={(e) => setNewDepartment(e.target.value)}
            />
            {parentDepartment && (
              <>
                <SoftTypography variant="button">
                  Parent: {departments.find(d => d.id === parentDepartment)?.name}
                </SoftTypography>
                <IconButton size="small" onClick={() => setParentDepartment(null)}>
                  <Icon>close</Icon>
                </IconButton>
              </>
            )}
            <IconButton variant="gradient" color="success" onClick={handleAdd}>
              <Icon>add</Icon>
            </IconButton>
          </Stack>
        </SoftBox>
      </Card>

      {/* Department Actions Menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAddSubdepartment}>
          <Icon sx={{ mr: 1 }}>add</Icon> Add Subdepartment
        </MenuItem>
        {selectedDepartment && selectedDepartment.parent_id && (
          <MenuItem onClick={handleMoveUp}>
            <Icon sx={{ mr: 1 }}>arrow_upward</Icon> Move Up One Level
          </MenuItem>
        )}
        <MenuItem disabled={!getPotentialParents().length} onClick={() => {
          setMenuAnchor(null);
        }}>
          <Icon sx={{ mr: 1 }}>arrow_downward</Icon> Move Under...
        </MenuItem>
        {getPotentialParents().length > 0 && (
          <SoftBox p={1}>
            {getPotentialParents().map(dept => (
              <MenuItem key={dept.id} onClick={() => handleMoveDown(dept.id)}>
                {dept.name}
              </MenuItem>
            ))}
          </SoftBox>
        )}
      </Menu>

      {/* Upload Dialog */}
      <Dialog 
        open={showUploadDialog} 
        onClose={() => setShowUploadDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Department List</DialogTitle>
        <DialogContent>
          <SoftBox mb={2}>
            <SoftTypography variant="body2" color="text">
              Upload a file with the following structure:
            </SoftTypography>
            <SoftBox mt={1} mb={1}>
              <ul style={{ paddingLeft: '20px' }}>
                <li>Each column (except the last) represents a department level in the hierarchy</li>
                <li>First column: Level 0 (top level)</li>
                <li>Second column: Level 1 (child of Level 0)</li>
                <li>And so on for any number of levels...</li>
                <li>Last column: Employee Count</li>
              </ul>
            </SoftBox>
            <SoftTypography variant="body2" color="text">
              The column names don't matter, only their position. You can have any number of department levels.
              The system will automatically detect whether your file is tab-separated, comma-separated, 
              semicolon-separated, or space-separated.
            </SoftTypography>
          </SoftBox>
          <SoftBox>
            <input
              ref={fileInputRef}
              type="file"
              accept=".csv,.tsv,.txt"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
            <SoftButton
              variant="gradient"
              color="info"
              onClick={() => fileInputRef.current.click()}
              disabled={processingUpload}
            >
              {processingUpload ? <CircularProgress size={20} /> : <Icon>upload_file</Icon>}
              &nbsp;Select File
            </SoftButton>
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <SoftButton onClick={() => setShowUploadDialog(false)} color="secondary">
            Cancel
          </SoftButton>
        </DialogActions>
      </Dialog>

      {/* Suggestions Dialog */}
      <Dialog 
        open={showSuggestionsDialog} 
        onClose={() => {
          // Only allow closing if no operations are in progress
          const operationsInProgress = uploadSuggestions.some(s => 
            s.selected && s.status === 'pending'
          );
          if (!operationsInProgress) {
            setShowSuggestionsDialog(false);
          }
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Department Update Suggestions</DialogTitle>
        <DialogContent>
          <SoftBox mb={2}>
            <SoftTypography variant="body2" color="text">
              Below is a preview of the department structure that will be created or updated.
              Select or deselect items to control which changes will be applied.
            </SoftTypography>
          </SoftBox>
          
          <SoftBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <SoftButton 
              variant="outlined" 
              color="info" 
              size="small"
              onClick={() => {
                const newSuggestions = uploadSuggestions.map(s => ({
                  ...s,
                  selected: s.status === 'pending'
                }));
                setUploadSuggestions(newSuggestions);
              }}
            >
              Select All Pending
            </SoftButton>
            <SoftButton 
              variant="outlined" 
              color="info" 
              size="small"
              onClick={() => {
                const newSuggestions = uploadSuggestions.map(s => ({
                  ...s,
                  selected: false
                }));
                setUploadSuggestions(newSuggestions);
              }}
            >
              Deselect All
            </SoftButton>
          </SoftBox>
          
          <Paper sx={{ maxHeight: 400, overflow: 'auto', p: 2 }}>
            <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {getRootSuggestions().map(suggestion => renderSuggestionNode(suggestion))}
            </SoftBox>
          </Paper>
        </DialogContent>
        <DialogActions>
          <SoftButton 
            onClick={() => {
              // Only allow closing if no operations are in progress
              const operationsInProgress = uploadSuggestions.some(s => 
                s.selected && s.status === 'pending'
              );
              if (!operationsInProgress) {
                setShowSuggestionsDialog(false);
              }
            }} 
            color="secondary"
          >
            Close
          </SoftButton>
          <SoftButton 
            onClick={applySelectedSuggestions} 
            color="success"
            disabled={!uploadSuggestions.some(s => s.selected && s.status === 'pending')}
          >
            Apply Selected
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Typechecking props for the ProfilesList
Departments.propTypes = {
  title: PropTypes.string.isRequired,
  deps: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  isFirstJourney: PropTypes.bool,
};

export default Departments;
