export const callOpenAI = async (prompt) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/openai/support_agent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          openai: {
            prompt,
            model: 'gpt-4o-mini',  // optional
            max_tokens: 1000,              // optional
          }
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return JSON.parse(data.response); // Parse the JSON string response
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };