import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
} from '@mui/material';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftInput from '../../components/SoftInput';
import SoftButton from '../../components/SoftButton';
import Geocode from 'react-geocode';
import { geoConfig } from '../../config';

// Set Google Maps Geocoding API key
Geocode.setApiKey(geoConfig);

function SiteDialog({ 
  open, 
  onClose, 
  onSubmit, 
  initialData = {}, 
  title = "Create a New Site",
  submitButtonText = "Create Site",
  companyId = null
}) {
  const [siteData, setSiteData] = useState({
    label: '',
    address: '',
    city: '',
    postal_code: '',
    country: '',
    employees: '',
    sqm: '',
    annual_rent: '',
    is_active: true,
    company_id: companyId || '',
    ...initialData
  });
  const [isGeocoding, setIsGeocoding] = useState(false);

  // Reset form when dialog opens or closes
  useEffect(() => {
    if (open) {
      setSiteData({
        label: '',
        address: '',
        city: '',
        postal_code: '',
        country: '',
        employees: '',
        sqm: '',
        annual_rent: '',
        is_active: true,
        company_id: companyId || '',
        ...initialData
      });
    }
  }, [open]); // Only depend on open state

  // Update companyId when it changes
  useEffect(() => {
    if (open) {
      setSiteData(prev => ({
        ...prev,
        company_id: companyId || prev.company_id
      }));
    }
  }, [companyId, open]);

  const handleChange = (field) => (e) => {
    setSiteData({
      ...siteData,
      [field]: e.target.value,
    });
  };

  const geocodeAddress = async () => {
    if (!siteData.address) return siteData;
    
    setIsGeocoding(true);
    
    let searchAddress = '';
    if (siteData.address && siteData.city) {
      searchAddress = `${siteData.address}, ${siteData.city}`;
    } else if (siteData.address) {
      searchAddress = siteData.address;
    }

    if (!searchAddress) {
      setIsGeocoding(false);
      return siteData;
    }

    try {
      const response = await Geocode.fromAddress(searchAddress);
      const { lat, lng } = response.results[0].geometry.location;
      
      // Extract postal code from geocoding result
      let postal_code = '';
      const addressComponents = response.results[0].address_components;
      const postalCodeComponent = addressComponents.find(
        component => component.types.includes('postal_code')
      );
      
      if (postalCodeComponent) {
        postal_code = postalCodeComponent.long_name;
      }
      
      setIsGeocoding(false);
      return { 
        ...siteData, 
        latitude: lat, 
        longitude: lng,
        postal_code: postal_code || siteData.postal_code
      };
    } catch (error) {
      console.error('Geocoding error:', error);
      setIsGeocoding(false);
      return siteData;
    }
  };

  const handleSubmit = async () => {
    // Geocode the address to get latitude and longitude
    const geocodedData = await geocodeAddress();
    
    // Ensure company_id is set if provided
    const dataToSubmit = {
      ...geocodedData,
      company_id: companyId || geocodedData.company_id
    };
    
    onSubmit(dataToSubmit);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>
        <SoftBox display="flex" alignItems="center">
          <Icon fontSize="large" sx={{ color: "primary.main", mr: 1 }}>add_location_alt</Icon>
          <SoftTypography variant="h4" fontWeight="medium">{title}</SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" color="text" sx={{ mt: 1 }}>
          Add a new location to your portfolio and start unlocking optimization opportunities.
        </SoftTypography>
      </DialogTitle>
      <DialogContent>
        <SoftBox component="form" role="form" sx={{ mt: 1 }}>
          {/* Site Label */}
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Site Label
              </SoftTypography>
            </SoftBox>
            <SoftInput
              placeholder="Enter site label"
              value={siteData.label || ''}
              onChange={handleChange('label')}
              fullWidth
            />
          </SoftBox>
          
          {/* Address */}
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Address
              </SoftTypography>
            </SoftBox>
            <SoftInput
              placeholder="Enter address"
              value={siteData.address || ''}
              onChange={handleChange('address')}
              fullWidth
            />
          </SoftBox>
          
          {/* City */}
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                City
              </SoftTypography>
            </SoftBox>
            <SoftInput
              placeholder="Enter city"
              value={siteData.city || ''}
              onChange={handleChange('city')}
              fullWidth
            />
          </SoftBox>
          
          {/* Country */}
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Country
              </SoftTypography>
            </SoftBox>
            <SoftInput
              placeholder="Enter country"
              value={siteData.country || ''}
              onChange={handleChange('country')}
              fullWidth
            />
          </SoftBox>
          
          {/* Employees and Square Meters */}
          <SoftBox display="flex" gap={2}>
            <SoftBox mb={2} flex={1}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Number of Employees
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="number"
                placeholder="Enter number of employees"
                value={siteData.employees || ''}
                onChange={handleChange('employees')}
                fullWidth
              />
            </SoftBox>
            <SoftBox mb={2} flex={1}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Square Meters
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="number"
                placeholder="Enter square meters"
                value={siteData.sqm || ''}
                onChange={handleChange('sqm')}
                fullWidth
              />
            </SoftBox>
          </SoftBox>
          
          {/* Annual Rent */}
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Annual Rent (€)
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="number"
              placeholder="Enter annual rent"
              value={siteData.annual_rent || ''}
              onChange={handleChange('annual_rent')}
              fullWidth
            />
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, justifyContent: "space-between" }}>
        <SoftButton variant="text" color="secondary" onClick={onClose}>
          Cancel
        </SoftButton>
        <SoftButton 
          variant="gradient" 
          color="primary" 
          onClick={handleSubmit} 
          startIcon={<Icon>add_circle</Icon>}
          disabled={isGeocoding}
        >
          {isGeocoding ? 'Processing...' : submitButtonText}
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
}

export default SiteDialog; 