import { Dialog, DialogContent, DialogTitle, DialogActions, Icon, IconButton, List, ListItem, ListItemText, Snackbar, Stack, Alert, Switch, FormControlLabel, Checkbox, Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import SoftBox from './SoftBox';
import SoftButton from './SoftButton';
import SoftTypography from './SoftTypography';
import SoftInput from './SoftInput';
import SoftAvatar from './SoftAvatar';

function DepartmentSelector({ open, onClose, journeyId, onSuccess, withFuture = false }) {
  const { company } = useSelector((state) => state.company);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
  const [departmentEmployees, setDepartmentEmployees] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [journeyDepartments, setJourneyDepartments] = useState([]);
  const [expandedDepartments, setExpandedDepartments] = useState({});
  
  // Fetch existing departments for this journey
  useEffect(() => {
    if (journeyId && open) {
      const API_URL = process.env.REACT_APP_BACKEND_URL;
      axios.get(`${API_URL}/journeys/${journeyId}`)
        .then(response => {
          const journey = response.data;
          if (journey.departments) {
            // Initialize with already selected departments
            const activeDepartments = journey.departments.filter(dept => dept.is_active);
            setJourneyDepartments(activeDepartments);
            
            // Set selected department IDs
            const departmentIds = activeDepartments.map(dept => dept.companydepartment_id);
            setSelectedDepartmentIds(departmentIds);
            
            // Set employee counts
            const employeeCounts = {};
            activeDepartments.forEach(dept => {
              if (dept.headcount) {
                employeeCounts[dept.companydepartment_id] = dept.headcount;
              }
            });
            setDepartmentEmployees(employeeCounts);
            
            // Auto-expand parent departments that have selected children
            if (departmentIds.length > 0 && company?.companydepartments) {
              const parentsToExpand = {};
              
              // Find all selected departments that have parents
              departmentIds.forEach(id => {
                const dept = company.companydepartments.find(d => d.id === id);
                if (dept && dept.parent_id) {
                  parentsToExpand[dept.parent_id] = true;
                }
              });
              
              // Expand those parents
              setExpandedDepartments(parentsToExpand);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching journey data:', error);
          setSnackMessage('Error loading department data');
          setSnackSeverity('error');
          setOpenSnack(true);
        });
    }
  }, [journeyId, open, company?.companydepartments]);

  const toggleExpand = (departmentId) => {
    setExpandedDepartments(prev => ({
      ...prev,
      [departmentId]: !prev[departmentId]
    }));
  };

  const handleToggleDepartment = (departmentId) => {
    // First determine if we're selecting or deselecting
    const isCurrentlySelected = selectedDepartmentIds.includes(departmentId);
    const department = company?.companydepartments?.find(d => d.id === departmentId);
    const isParent = department && !department.parent_id;
    
    // If we're selecting a parent department, expand it
    if (!isCurrentlySelected && isParent) {
      setExpandedDepartments(prev => ({
        ...prev,
        [departmentId]: true
      }));
    }
    
    // If we're selecting a child department, expand its parent
    if (!isCurrentlySelected && department && department.parent_id) {
      setExpandedDepartments(prev => ({
        ...prev,
        [department.parent_id]: true
      }));
    }

    // Helper function to set employee count for a department
    const setEmployeeCount = (deptId) => {
      const dept = company?.companydepartments?.find(d => d.id === deptId);
      if (dept && dept.employees) {
        setDepartmentEmployees(prev => ({
          ...prev,
          [deptId]: dept.employees
        }));
      }
    };
    
    setSelectedDepartmentIds(prev => {
      // If toggling a parent department
      if (isParent) {
        const childDepartmentIds = company?.companydepartments
          ?.filter(d => d.parent_id === departmentId)
          .map(d => d.id) || [];
        
        if (prev.includes(departmentId)) {
          // If deselecting parent, also deselect all children
          return prev.filter(id => id !== departmentId && !childDepartmentIds.includes(id));
        } else {
          // If selecting parent, also select all children and set their employee counts
          setEmployeeCount(departmentId);
          childDepartmentIds.forEach(childId => setEmployeeCount(childId));
          return [...prev.filter(id => !childDepartmentIds.includes(id)), departmentId, ...childDepartmentIds];
        }
      } else {
        // If toggling a child department
        if (prev.includes(departmentId)) {
          // If deselecting child, just remove the child (don't affect parent)
          return prev.filter(id => id !== departmentId);
        } else {
          // If selecting child, just add it and set its employee count
          setEmployeeCount(departmentId);
          return [...prev, departmentId];
        }
      }
    });
  };

  const handleSelectAllDepartments = () => {
    const allDepartmentIds = company?.companydepartments
      ?.filter(dept => dept.name !== 'All')
      .map(dept => dept.id) || [];
    
    // Check if all departments are already selected
    const allSelected = allDepartmentIds.length > 0 && 
      allDepartmentIds.every(id => selectedDepartmentIds.includes(id));
    
    if (allSelected) {
      // If all are selected, deselect all
      setSelectedDepartmentIds([]);
      setDepartmentEmployees({}); // Clear all employee counts
    } else {
      // Otherwise, select all and set their employee counts
      setSelectedDepartmentIds(allDepartmentIds);
      
      // Set employee counts for all departments
      const newEmployeeCounts = {};
      company?.companydepartments
        ?.filter(dept => dept.name !== 'All')
        .forEach(dept => {
          if (dept.employees) {
            newEmployeeCounts[dept.id] = dept.employees;
          }
        });
      setDepartmentEmployees(newEmployeeCounts);
      
      // Expand all parent departments
      const parentDepartmentIds = company?.companydepartments
        ?.filter(dept => dept.name !== 'All' && (!dept.parent_id || dept.level === 0))
        .map(dept => dept.id) || [];
      
      const newExpandedState = {};
      parentDepartmentIds.forEach(id => {
        newExpandedState[id] = true;
      });
      
      setExpandedDepartments(prev => ({
        ...prev,
        ...newExpandedState
      }));
    }
  };

  const handleEmployeeCountChange = (departmentId, value) => {
    setDepartmentEmployees(prev => ({
      ...prev,
      [departmentId]: value
    }));
  };

  const handleSaveSelectedDepartments = () => {
    // Get existing departments from journey
    const existingDepartments = journeyDepartments || [];
    
    // Process each department in the company
    const departmentPromises = [];
    const API_URL = process.env.REACT_APP_BACKEND_URL;
    
    company?.companydepartments?.forEach(dept => {
      const isSelected = selectedDepartmentIds.includes(dept.id);
      const existingDept = existingDepartments.find(d => d.companydepartment_id === dept.id);
      const employeeCount = departmentEmployees[dept.id] || dept.employees || ''; // First try user-set count, then fall back to department's total
      
      if (isSelected) {
        if (existingDept) {
          // Department exists and is selected - ensure it's active and update employee count
          if (!existingDept.is_active || existingDept.headcount !== employeeCount) {
            departmentPromises.push(
              axios.put(`${API_URL}/departments/update/${existingDept.id}`, {
                is_active: true,
                headcount: employeeCount
              })
            );
          }
        } else {
          // Department doesn't exist but is selected - create it
          departmentPromises.push(
            axios.post(`${API_URL}/departments/create`, {
              companydepartment_id: dept.id,
              name: dept.name,
              is_active: true,
              journey_id: journeyId,
              company_id: company.id,
              headcount: employeeCount
            })
          );
        }
      } else if (existingDept && existingDept.is_active) {
        // Department exists, is active, but is not selected - mark as inactive
        departmentPromises.push(
          axios.put(`${API_URL}/departments/update/${existingDept.id}`, {
            is_active: false
          })
        );
      }
    });
    
    // Wait for all department operations to complete
    Promise.all(departmentPromises)
      .then(() => {
        // Close the dialog first to improve perceived performance
        onClose();
        
        // Show success message
        setSnackMessage('Departments updated successfully');
        setSnackSeverity('success');
        setOpenSnack(true);
        
        // Call onSuccess callback if provided
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
        }
      })
      .catch(error => {
        console.error('Error updating departments:', error);
        // Show error notification
        setSnackMessage('Error updating departments. Please try again.');
        setSnackSeverity('error');
        setOpenSnack(true);
      });
  };

  // Organize departments into a hierarchical structure
  const organizedDepartments = () => {
    // Get top-level departments (level 0 or null parent_id)
    const topLevel = company?.companydepartments?.filter(d => d.level === 0 || !d.parent_id) || [];
    
    // Recursive function to build the department tree
    const buildDepartmentTree = (parentId = null, level = 0) => {
      return company?.companydepartments
        ?.filter(d => d.parent_id === parentId && d.name !== 'All')
        .map(department => ({
          ...department,
          children: buildDepartmentTree(department.id, level + 1)
        })) || [];
    };
    
    return buildDepartmentTree(null, 0);
  };

  // Recursive component to render department hierarchy
  const renderDepartment = (department, depth = 0) => {
    const hasChildren = company?.companydepartments?.some(d => d.parent_id === department.id);
    const isExpanded = expandedDepartments[department.id];
    const isSelected = selectedDepartmentIds.includes(department.id);
    
    // Check if all children are selected
    const childDepartments = company?.companydepartments?.filter(d => d.parent_id === department.id) || [];
    const allChildrenSelected = childDepartments.length > 0 && 
      childDepartments.every(child => selectedDepartmentIds.includes(child.id));
    
    return (
      <SoftBox key={department.id} ml={depth * 3}>
        <SoftBox component="li" display="flex" alignItems="center" py={1} mb={1}>
          <SoftBox mr={2}>
            <SoftAvatar icon={<Icon>account_circle</Icon>} alt="department icon" variant="rounded" shadow="md" />
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow={1}
            mr={3}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {hasChildren && (
                <IconButton size="small" onClick={() => toggleExpand(department.id)}>
                  <Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
              )}
              <SoftTypography variant="button" fontWeight="medium">
                {department.name}
              </SoftTypography>
              <SoftTypography variant="caption" color="text.secondary">
                (Total: {department.employees || '0'} employees)
              </SoftTypography>
            </Stack>
          </SoftBox>
          
          <Checkbox
            checked={isSelected || allChildrenSelected}
            onChange={() => handleToggleDepartment(department.id)}
            color="primary"
          />
          
          {(isSelected || allChildrenSelected) && (
            <SoftBox width="150px" mr={2} sx={{ textAlign: 'right' }}>
              <SoftInput
                placeholder="Project employees"
                value={departmentEmployees[department.id] || ''}
                onChange={(e) => handleEmployeeCountChange(department.id, e.target.value)}
                sx={{ 
                  '& input': { 
                    textAlign: 'right',
                    paddingRight: '8px'
                  }
                }}
              />
            </SoftBox>
          )}
        </SoftBox>
        
        {hasChildren && isExpanded && (
          <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0} pl={3}>
            {company?.companydepartments
              ?.filter(d => d.parent_id === department.id)
              .map(childDept => renderDepartment(childDept, depth + 1))}
          </SoftBox>
        )}
      </SoftBox>
    );
  };

  // Render orphaned departments (departments with parent_id that doesn't exist)
  const renderOrphanedDepartments = () => {
    const orphanedDepartments = company?.companydepartments?.filter(dept => 
      dept.name !== 'All' && 
      dept.parent_id && 
      !company?.companydepartments?.some(p => p.id === dept.parent_id)
    ) || [];

    if (orphanedDepartments.length === 0) return null;

    return (
      <SoftBox mt={3}>
        <SoftTypography variant="h6" fontWeight="medium" mb={2}>
          Orphaned Departments
        </SoftTypography>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {orphanedDepartments.map(dept => {
            const isSelected = selectedDepartmentIds.includes(dept.id);
            
            return (
              <SoftBox key={dept.id} component="li" display="flex" alignItems="center" py={1} mb={1}>
                <SoftBox mr={2}>
                  <SoftAvatar icon={<Icon>warning</Icon>} alt="warning" variant="rounded" shadow="md" bgColor="warning" />
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  flexGrow={1}
                  mr={3}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SoftTypography variant="button" fontWeight="medium">
                      {dept.name}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="warning.main">
                      (Orphaned department)
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text.secondary">
                      (Total: {dept.employees || '0'} employees)
                    </SoftTypography>
                  </Stack>
                </SoftBox>
                
                <Checkbox
                  checked={isSelected}
                  onChange={() => handleToggleDepartment(dept.id)}
                  color="primary"
                />
                
                {isSelected && (
                  <SoftBox width="150px" mr={2} sx={{ textAlign: 'right' }}>
                    <SoftInput
                      placeholder="Site employees"
                      value={departmentEmployees[dept.id] || ''}
                      onChange={(e) => handleEmployeeCountChange(dept.id, e.target.value)}
                      sx={{ 
                        '& input': { 
                          textAlign: 'right',
                          paddingRight: '8px'
                        }
                      }}
                    />
                  </SoftBox>
                )}
              </SoftBox>
            );
          })}
        </SoftBox>
      </SoftBox>
    );
  };

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setOpenSnack(false)}
          severity={snackSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle sx={{ pb: 1 }}>
          Select Departments
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SoftBox mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <SoftTypography variant="body2" color="text.secondary">
              Select departments to include in this journey. For each selected department, you can specify how many employees are part of this project.
            </SoftTypography>
            <SoftButton 
              variant="text" 
              color={selectedDepartmentIds.length === (company?.companydepartments?.filter(d => d.name !== 'All').length || 0) ? "warning" : "primary"}
              onClick={handleSelectAllDepartments}
              size="small"
            >
              {selectedDepartmentIds.length === (company?.companydepartments?.filter(d => d.name !== 'All').length || 0) ? "Deselect All" : "Select All"}
            </SoftButton>
          </SoftBox>
          
          <Paper sx={{ maxHeight: 500, overflow: 'auto', p: 2 }}>
            <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {organizedDepartments().map(department => renderDepartment(department))}
            </SoftBox>
            {renderOrphanedDepartments()}
          </Paper>
        </DialogContent>
        <DialogActions>
          <SoftButton onClick={onClose} color="secondary">
            Cancel
          </SoftButton>
          <SoftButton onClick={handleSaveSelectedDepartments} variant="gradient" color="primary">
            Save
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DepartmentSelector; 