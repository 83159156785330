import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import SoftButton from '../components/SoftButton';
import SoftTypography from '../components/SoftTypography';
import DefaultItem from '../examples/Items/DefaultItem';
import DashboardLayout from '../examples/LayoutContainers/DashboardLayout';

// ----------------------------------------------------------------------

JourneyGuard.propTypes = {
  children: PropTypes.node
};

export default function JourneyGuard({ children }) {
  const navigate = useNavigate();
  const { site } = useSelector((state) => state.site);
  if (!site) {
    return (
      <LoadingScreen />
    );
  }
  if (!site.journeys?.length) {
    return (
      <DashboardLayout>

        <Card sx={{ p: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', height: '50vh' }}>
        <DefaultItem sx={{ my: 2 }} icon="warning" color='warning' title={`No journeys available for ${site.name}`} description="You need to create a journey before you can access this section." />

          <SoftTypography variant="h3" color="error" textGradient sx={{mb: 2}}></SoftTypography>
          <SoftButton variant="contained" color="primary" onClick={() => navigate('/')}>Return to Home</SoftButton>
        </Card>
      </DashboardLayout>
    );
  }

  return <>{children}</>;
}
