import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  Divider,
  Box,
  Stack,
  CardContent,
  Chip,
  Icon,
  alpha,
  styled,
  CircularProgress,
  Alert,
  Button,
  Typography,
  IconButton
} from '@mui/material';
import {
  People,
  Business,
  Insights,
  AspectRatio,
  Chair,
  SupportAgent,
  Domain,
  WorkOutline,
  LocationOn,
  AccountBalance,
  CompareArrows,
  Dashboard,
  Timeline,
  Info,
  Add as AddIcon,
  AutoAwesome as AutoAwesomeIcon,
  Warning,
  CheckCircle,
  Settings,
  Edit,
  Save
} from '@mui/icons-material';
import SoftBox from '../SoftBox';
import SoftTypography from '../SoftTypography';
import SoftButton from '../SoftButton';
import SoftInput from '../SoftInput';
import SiteDialog from '../SiteDialog';
import axios from 'axios';
import { useSelector } from 'react-redux';
import DepartmentSelector from '../DepartmentSelector';
import { getJourney, getSite } from '../../redux/slices/site';
import { useDispatch } from 'react-redux';
import { updateJourney } from '../../redux/slices/site';
import { useSelector as useReduxSelector } from '../../redux/store';
import { getCompanySites } from '../../redux/slices/company';
import { useNavigate } from 'react-router-dom';
import { updateCompany } from '../../redux/slices/company';

// Styled components for project type cards
const StyledCard = styled(Card)(({ theme, selected, hasSelection, currentType, projectTypes, projectType }) => ({
  transition: 'all 0.2s ease-in-out',
  border: selected ? `2px solid ${theme.palette[projectType === 'elevate' ? 'info' : 'primary'].main}` : '1px solid',
  borderColor: selected ? (projectType === 'elevate' ? theme.palette.info.main : theme.palette.primary.main) : theme.palette.grey[200],
  opacity: hasSelection && !selected ? 0.7 : 1,
  transform: selected ? 'scale(1.02)' : 'scale(1)',
  boxShadow: selected ? theme.shadows[3] : theme.shadows[1],
  '&:hover': {
    transform: hasSelection && !selected ? 'scale(1)' : 'scale(1.02)',
    boxShadow: hasSelection && !selected ? theme.shadows[1] : theme.shadows[3],
    borderColor: theme.palette[projectType === 'elevate' ? 'info' : 'primary'].main,
  },
  position: 'relative',
  cursor: hasSelection && 
          currentType && 
          projectTypes[projectType].baseRate < projectTypes[currentType].baseRate && 
          projectType !== currentType ? 'not-allowed' : 'pointer',
}));

const PriceTag = styled(Box)(({ theme, currentType, projectTypes, projectType }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: theme.palette[projectType === 'elevate' ? 'info' : 'primary'].main,
  color: theme.palette.common.white,
  padding: '4px 12px',
  borderBottomLeftRadius: theme.shape.borderRadius,
  fontWeight: 'bold',
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
}));

const PriceSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const PriceCalculation = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
}));

const OriginalPrice = styled(SoftTypography)(({ theme }) => ({
  textDecoration: 'line-through',
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
}));

const FeatureChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '& .MuiChip-icon': {
    marginLeft: theme.spacing(0.5),
  },
}));

function ProjectDialog({ 
  open, 
  onClose, 
  onSubmit, 
  companyId,
  contracts = [],
  sites = [],
  initialSite = null,
  onComplete
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State for multi-step dialog
  const [activeStep, setActiveStep] = useState(0);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedProjectType, setSelectedProjectType] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [newSiteDialogOpen, setNewSiteDialogOpen] = useState(false);
  
  // New state variables for project drivers and AI suggestions
  const [projectDrivers, setProjectDrivers] = useState('');
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [aiResponse, setAiResponse] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('success');
  const [pendingValues, setPendingValues] = useState(new Map());
  const [masterValues, setMasterValues] = useState(new Map());
  const [journey, setJourney] = useState(null);

  // Add new state variables
  const [workModel, setWorkModel] = useState(null);
  const [activityProfile, setActivityProfile] = useState(null);
  const [soundProfile, setSoundProfile] = useState(null);
  const [privacyProfile, setPrivacyProfile] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [surveyMethod, setSurveyMethod] = useState(null);

  const { company } = useSelector((state) => state.company);
  const [openDepartmentSelector, setOpenDepartmentSelector] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', color: 'info' });
  const { goaldomains } = useReduxSelector((state) => state.workplace);

  const fetchUpdatedJourney = async () => {
    if (!journey?.id) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/${journey.id}`);
      setJourney(response.data);
    } catch (error) {
      console.error('Error fetching updated journey:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch updated journey data',
        color: 'error'
      });
    }
  };

  // Initialize selectedSite with initialSite when component mounts
  useEffect(() => {
    if (initialSite) {
      setSelectedSite(initialSite);
    }
  }, [initialSite]);

  // Reset state when dialog opens or initialSite changes
  useEffect(() => {
    if (open) {
      if (initialSite) {
        setSelectedSite(initialSite);
        setActiveStep(1); // Skip to project type selection if site is provided
      } else {
        setSelectedSite(null);
        setActiveStep(0);
      }
      setSelectedProjectType(null);
      setSelectedModules([]);
      setTotalCost(0);
    }
  }, [open, initialSite]);

  // Add effect to update journey when departments change
  useEffect(() => {
    if (journey?.id) {
      const fetchUpdatedJourney = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/${journey.id}`);
          setJourney(response.data);
        } catch (error) {
          console.error('Error fetching updated journey:', error);
        }
      };
      fetchUpdatedJourney();
    }
  }, [journey?.id]);

  // Function to calculate cost based on employee count and project type
  const calculateProjectCost = (employeeCount, projectType) => {
    let cost = 0;
    const multiplier = projectType === 'elevate' ? 3 : 1;

    if (employeeCount <= 50) {
      cost = employeeCount * 40;
    } else if (employeeCount <= 100) {
      cost = 50 * 40 + (employeeCount - 50) * 35;
    } else if (employeeCount <= 200) {
      cost = 50 * 40 + 50 * 35 + (employeeCount - 100) * 30;
    } else if (employeeCount <= 300) {
      cost = 50 * 40 + 50 * 35 + 100 * 30 + (employeeCount - 200) * 20;
    } else if (employeeCount <= 1000) {
      cost = 50 * 40 + 50 * 35 + 100 * 30 + 100 * 20 + (employeeCount - 300) * 10;
    } else {
      cost = 50 * 40 + 50 * 35 + 100 * 30 + 100 * 20 + 700 * 10;
    }

    return cost * multiplier;
  };

  // Calculate price function
  const calculatePrice = (projectType, discount = 0) => {
    const employees = selectedSite?.employees || 0;
    const currentProjectType = selectedProjectType;
    
    // Calculate costs using the project cost function
    const newCost = calculateProjectCost(employees, projectType);
    const currentCost = currentProjectType ? calculateProjectCost(employees, currentProjectType) : 0;
    
    const differentialCost = Math.max(0, newCost - currentCost);
    
    const isUpgrade = currentProjectType !== null && differentialCost > 0;
    const isCurrent = currentProjectType === projectType;
    const isIncluded = currentProjectType && newCost <= currentCost && !isCurrent;
    
    const originalPrice = differentialCost;
    const discountedPrice = (!isUpgrade && discount > 0) ? originalPrice * (1 - discount) : originalPrice;
    
    return {
      original: (!isUpgrade && discount > 0 && originalPrice > 0) ? `€${originalPrice.toLocaleString()}` : null,
      total: isIncluded ? 'Already Included' : (isCurrent ? '' : `€${discountedPrice.toLocaleString()}`),
      calculation: originalPrice > 0 ? `${employees} employees with ${projectType} plan` : null,
      discount: (!isUpgrade && discount > 0 && originalPrice > 0) ? `${discount * 100}% discount applied` : null,
      isUpgrade,
      isCurrent,
      isIncluded,
      rawCost: newCost
    };
  };

  // Function to calculate total cost including modules
  const calculateTotalCost = () => {
    if (!selectedSite || !selectedProjectType) return 0;

    const baseCost = calculateProjectCost(selectedSite.employees, selectedProjectType);
    const modulesCost = selectedModules.length * 1000;

    return baseCost + modulesCost;
  };

  // Function to handle site selection
  const handleSiteSelection = (site) => {
    setSelectedSite(site);
    // Calculate initial cost using express plan
    const baseCost = calculateProjectCost(site.employees, 'express');
    setTotalCost(baseCost);
    setActiveStep(1); // Move to confirmation step
  };

  // Function to handle project type selection
  const handleProjectTypeSelection = (type) => {
    setSelectedProjectType(type);

    // Calculate initial total cost
    if (selectedSite) {
      const baseCost = calculateProjectCost(selectedSite.employees, type);
      setTotalCost(baseCost);
    }

    setActiveStep(2); // Now goes to drivers step instead of module customization
  };

  // Function to handle module selection
  const handleModuleSelection = (module) => {
    let updatedModules;
    if (selectedModules.includes(module)) {
      updatedModules = selectedModules.filter(m => m !== module);
    } else {
      updatedModules = [...selectedModules, module];
    }
    setSelectedModules(updatedModules);

    // Calculate total cost after state update
    setTimeout(() => {
      if (selectedSite && selectedProjectType) {
        const baseCost = calculateProjectCost(selectedSite.employees, selectedProjectType);
        const modulesCost = updatedModules.length * 1000;
        setTotalCost(baseCost + modulesCost);
      }
    }, 0);
  };

  // Function to handle project drivers submission
  const handleProjectDriversSubmit = async () => {
    if (!projectDrivers.trim()) {
      setSnackbarMessage('Please enter project drivers and goals');
      setSnackbarColor('error');
      setOpenSnackbar(true);
      return;
    }

    setIsAiLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/openai/project_ai_analysis`, {
        prompt: projectDrivers,
        site_name: selectedSite?.label,
        employees: selectedSite?.employees,
        sqm: selectedSite?.sqm
      });

      // Parse the nested JSON response
      const jsonStr = response.data.response.replace(/```json\n|\n```/g, '');
      const parsedResponse = JSON.parse(jsonStr);

      // Structure the AI response data
      const aiData = {
        drivers_summary: parsedResponse.drivers,
        project_description: parsedResponse.strategic_description,
        suggested_goals: parsedResponse.goals.map(goal => ({
          id: goal.id,
          label: goal.label,
          objectives: goal.objectives.map(obj => obj.label),
          confidence_score: 85 // Default confidence score for goals
        })),
        suggested_modules: parsedResponse.modules[0].group.map(module => ({
          id: module.id,
          name: module.label,
          description: module.instruction,
          confidence_score: Math.round(module.confidence_score * 100)
        }))
      };

      setAiResponse(aiData);

      // Update journey with AI suggestions
      try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
          drivers_description: aiData.drivers_summary,
          project_description: aiData.project_description,
          suggested_goals: aiData.suggested_goals,
          suggested_modules: aiData.suggested_modules
        });
      } catch (error) {
        console.error('Error updating journey with AI suggestions:', error);
      }

      setActiveStep(3); // Move to AI suggestions review
    } catch (error) {
      console.error('Error processing project drivers:', error);
      setSnackbarMessage(error.response?.data?.error || 'Error processing project drivers');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    } finally {
      setIsAiLoading(false);
    }
  };

  const handleSkipAiAnalysis = () => {
    setActiveStep(4); // Skip to module customization
  };

  const handleApproveProjectSuggestions = () => {
    setMasterValues(new Map(pendingValues));
    setActiveStep(4); // Move to module customization
  };

  const handleRejectProjectSuggestions = () => {
    setActiveStep(2); // Go back to drivers input
  };

  const getConfidenceLevel = (score) => {
    if (score >= 90) return 'verified';
    if (score >= 70) return 'high';
    if (score >= 50) return 'medium';
    return 'low';
  };

  // Update createProject function
  const createProject = async () => {
    if (!selectedSite) {
      setSnackbarMessage('Please select a site first');
      setSnackbarColor('error');
      setOpenSnackbar(true);
      return;
    }

    try {
    const projectData = {
      company_id: companyId,
      label: `${selectedSite.label} - ${selectedProjectType === 'express' ? 'Express' : 'Elevate'} Project`,
      description: `${selectedProjectType === 'express' ? 'Express' : 'Elevate'} project with ${selectedModules.length} additional modules`,
      site_id: selectedSite.id,
      is_active: true,
      budget: totalCost,
      status: 'pending',
      // Additional data for journey creation
      work_model: 100,
      employeePerspective: 100,
      employees: selectedSite.employees,
      sqm: selectedSite.sqm,
      city: selectedSite.city,
      address: selectedSite.address,
      longitude: selectedSite.longitude,
      latitude: selectedSite.latitude,
      new_work_model: 100,
        dimensioning_number: 100,
      country: selectedSite.country,
      path: selectedProjectType === 'express' ? 'light' : 'plus',
      modules: selectedModules,
        total_cost: calculateProjectCost(selectedSite.employees, selectedProjectType),
      };

      const journeyResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/journeys`, projectData);
      const journey = journeyResponse.data;

      setJourney(journey);

      setActiveStep(2); // Move to project drivers
    } catch (error) {
      console.error('Error creating project:', error);
      setSnackbarMessage(error.response?.data?.error || 'Error creating project');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    setSelectedSite(null);
    setSelectedProjectType(null);
    setSelectedModules([]);
    setTotalCost(0);
    onClose();
  };

  // Function to handle creating a new site
  const handleCreateNewSite = (siteData) => {
    try {
      // Select the newly created site and move to the next step
      setSelectedSite(siteData);
      setNewSiteDialogOpen(false);
      setActiveStep(1);
    } catch (error) {
      console.error('Error handling new site:', error);
    }
  };

  // Add new render function for site confirmation with pricing
  const renderSiteConfirmation = () => {
    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Create New Project
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h5" fontWeight="bold">
                      {selectedSite?.name || selectedSite?.label}
                    </SoftTypography>
                    <Chip
                      label={`${selectedSite?.employees || 0} Employees`}
                      color="primary"
                      size="small"
                    />
                  </Stack>
                  <SoftTypography variant="body2" color="text">
                    {selectedSite?.address}, {selectedSite?.city}
                  </SoftTypography>
                  <Divider />
                  <Stack spacing={1}>
                    <SoftTypography variant="h6" fontWeight="medium">
                      Project Cost
                    </SoftTypography>
                    <SoftTypography variant="h4" color="primary" fontWeight="bold">
                      €{totalCost.toLocaleString()}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text.secondary">
                      Base price for Express project type. You can upgrade to Elevate later.
                    </SoftTypography>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                p: 2, 
                borderRadius: 2,
                backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1)
              }}>
                <Info color="info" />
                <SoftTypography variant="body2" color="info.main">
                  After creating the project, you'll be guided through a series of steps to configure your project settings and preferences.
                </SoftTypography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <SoftButton onClick={() => setActiveStep(0)} variant="outlined" color="primary">
            Back
          </SoftButton>
          <SoftButton onClick={createProject} variant="gradient" color="primary">
            Create Project
          </SoftButton>
        </DialogActions>
      </>
    );
  };

  // Render site selection step
  const renderSiteSelection = () => {
    const availableSites = contracts.length > 0 ? contracts : sites;
    
    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Select a Site for Your Project
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <SoftButton 
                variant="gradient" 
                color="success" 
                fullWidth 
                startIcon={<AddIcon />}
                onClick={() => setNewSiteDialogOpen(true)}
                sx={{ mb: 2 }}
              >
                Create New Site
              </SoftButton>
            </Grid>
            {availableSites
              .filter(site => site.status !== 'Journey')
              .map(site => {
                const hasJourney = site.journeys?.length > 0;
                return (
                  <Grid item xs={12} md={6} key={site.id}>
                    <Card
                      sx={{
                        p: 2,
                        cursor: hasJourney ? 'not-allowed' : 'pointer',
                        transition: 'transform 0.2s',
                        opacity: hasJourney ? 0.6 : 1,
                        '&:hover': {
                          transform: hasJourney ? 'none' : 'scale(1.02)',
                          boxShadow: hasJourney ? 1 : 3
                        }
                      }}
                      onClick={() => !hasJourney && handleSiteSelection(site)}
                    >
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                          <SoftTypography variant="h5" fontWeight="bold">
                            {site.name || site.label}
                          </SoftTypography>
                          {hasJourney && (
                            <Chip
                              label="Project Exists"
                              size="small"
                              color="warning"
                            />
                          )}
                        </SoftBox>
                        <SoftTypography variant="body2" color="text">
                          {site.address}, {site.city}
                        </SoftTypography>
                        <SoftBox mt={1}>
                          <Chip
                            label={`${site.employees || 0} Employees`}
                            size="small"
                            color="primary"
                            sx={{ mr: 1 }}
                          />
                          <Chip
                            label={`${site.sqm || 0} sqm`}
                            size="small"
                            color="info"
                          />
                        </SoftBox>
                        {hasJourney && (
                          <SoftTypography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                            This site already has an active project
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <SoftButton onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </SoftButton>
        </DialogActions>
      </>
    );
  };

  // Render project type selection step
  const renderProjectTypeSelection = () => {
    // Define project types
    const projectTypes = {
      express: {
        type: 'express',
        title: "Express",
        description: "Focused approach on space optimization and essential workplace elements",
        price: "Express",
        baseRate: 50,
        discount: 0.10,
        priceDetails: "One-time payment",
        phases: {
          discover: [
            { icon: <People />, label: "Employee Perspective" },
            { icon: <Business />, label: "Management Perspective" },
            { icon: <Insights />, label: "Insights" }
          ],
          define: [
            { icon: <AspectRatio />, label: "Dimensioning" }
          ],
          design: [
            { icon: <Chair />, label: "Work Settings" },
            { icon: <SupportAgent />, label: "Support Areas" },
            { icon: <Domain />, label: "Business Specific" }
          ]
        }
      },
      elevate: {
        type: 'elevate',
        title: "Elevate",
        description: "Comprehensive approach with advanced features and in-depth analysis",
        price: "Premium",
        baseRate: 150,
        discount: 0.25,
        priceDetails: "One-time payment",
        phases: {
          discover: [
            { icon: <People />, label: "Employee Perspective" },
            { icon: <Business />, label: "Management Perspective" },
            { icon: <Insights />, label: "Insights" }
          ],
          define: [
            { icon: <Domain />, label: "Organisation" },
            { icon: <WorkOutline />, label: "Work Model" },
            { icon: <LocationOn />, label: "Location" },
            { icon: <AccountBalance />, label: "Budget" },
            { icon: <AspectRatio />, label: "Dimensioning" },
            { icon: <CompareArrows />, label: "Way of Working" }
          ],
          design: [
            { icon: <Chair />, label: "Work Settings" },
            { icon: <SupportAgent />, label: "Support Areas" },
            { icon: <Domain />, label: "Business Specific" },
            { icon: <Dashboard />, label: "Zoning" }
          ]
        }
      }
    };

    // Create project paths with pricing
    const projectPaths = Object.entries(projectTypes).reduce((acc, [key, path]) => ({
      ...acc,
      [key]: {
        ...path,
        pricing: calculatePrice(key, path.discount)
      }
    }), {});

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Select Project Type
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={3}>
            {Object.entries(projectPaths).map(([key, path]) => (
              <StyledCard
                key={key}
                selected={selectedProjectType === key}
                hasSelection={selectedProjectType !== null}
                currentType={selectedProjectType}
                projectTypes={projectTypes}
                projectType={key}
                onClick={() => handleProjectTypeSelection(key)}
                sx={{ 
                  width: '50%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  position: 'relative',
                  cursor: selectedProjectType && 
                          projectTypes[key].baseRate < projectTypes[selectedProjectType].baseRate && 
                          key !== selectedProjectType ? 'not-allowed' : 'pointer',
                }}
              >
                <PriceTag
                  currentType={selectedProjectType}
                  projectTypes={projectTypes}
                  projectType={key}
                >
                  {path.price}
                </PriceTag>
                
                <CardContent>
                  <Box sx={{ flex: 1 }}>
                    <SoftTypography variant="h5" gutterBottom fontWeight="bold" color={key === 'elevate' ? 'info' : 'primary'}>
                      {path.title}
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" paragraph>
                      {path.description}
                    </SoftTypography>

                    {Object.entries(path.phases).map(([phase, features]) => (
                      <Box key={phase} sx={{ mb: 2 }}>
                        <SoftTypography
                          variant="subtitle2"
                          color="primary"
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            mb: 1
                          }}
                        >
                          <Timeline sx={{ mr: 1, verticalAlign: 'middle' }} />
                          {phase}
                        </SoftTypography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {features.map((feature, index) => (
                            <FeatureChip
                              key={index}
                              icon={feature.icon}
                              label={feature.label}
                              size="small"
                              sx={{
                                ...(selectedProjectType && projectPaths[selectedProjectType]?.phases[phase]?.some(
                                  f => f.label === feature.label
                                ) && {
                                  backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1),
                                  '& .MuiChip-icon': {
                                    color: 'success.main',
                                  }
                                })
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Divider />
                  <PriceSection>
                    {path.pricing.original && (
                      <OriginalPrice>
                        {path.pricing.original}
                      </OriginalPrice>
                    )}
                    {path.pricing.total && (
                      <SoftTypography variant="h4" color={key === 'elevate' ? 'info' : 'primary'} fontWeight="bold">
                        {path.pricing.total}
                      </SoftTypography>
                    )}
                    {!path.pricing.total && (
                      <SoftTypography variant="h4" color={key === 'elevate' ? 'info' : 'primary'} fontWeight="bold">
                        {selectedSite ? `€${path.pricing.rawCost.toLocaleString()}` : '€0'}
                      </SoftTypography>
                    )}

                    {/* Only show price calculation and details if not current path and not already included */}
                    {!path.pricing.isCurrent && !path.pricing.isIncluded && (
                      <>
                        {(path.pricing.calculation || path.pricing.discount) && (
                          <PriceCalculation>
                            <SoftTypography variant="caption" color="text.secondary">
                              {path.pricing.calculation || `Based on ${selectedSite?.employees || 0} employees`}
                            </SoftTypography>
                            {path.pricing.discount && (
                              <SoftTypography 
                                variant="caption" 
                                color="success.main" 
                                fontWeight="medium"
                              >
                                {path.pricing.discount}
                              </SoftTypography>
                            )}
                          </PriceCalculation>
                        )}
                        {path.priceDetails && (
                          <SoftTypography variant="body2" color="text.secondary">
                            {path.priceDetails}
                          </SoftTypography>
                        )}
                      </>
                    )}
                    {path.pricing.isUpgrade && (
                      <SoftTypography variant="caption" color="info.main">
                        Upgrade price from current plan
                      </SoftTypography>
                    )}
                  </PriceSection>
                </CardContent>
              </StyledCard>
            ))}
          </Stack>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1, 
            mt: 3, 
            p: 2, 
            borderRadius: 2,
            backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1)
          }}>
            <Info color="info" />
            <SoftTypography variant="body2" color="info.main">
              You can upgrade your project type at any time during your project, but discounts will only be applied to new purchases. Your progress will be preserved.
            </SoftTypography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <SoftButton onClick={() => setActiveStep(0)} variant="outlined" color="primary">
            Back
          </SoftButton>
          {selectedProjectType && (
            <SoftButton
              onClick={() => {
                // Calculate initial total cost
                if (selectedSite) {
                  const baseCost = calculateProjectCost(selectedSite.employees, selectedProjectType);
                  setTotalCost(baseCost);
                }
                setActiveStep(2);
              }}
              variant="gradient"
              color="primary"
            >
              Continue
            </SoftButton>
          )}
        </DialogActions>
      </>
    );
  };

  // Update renderProjectDrivers function
  const renderProjectDrivers = () => {
    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Project Drivers & Goals
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Describe Your Project
              </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Help us understand your project's drivers and ambitions. What are your main goals? What challenges are you trying to solve?
                    </SoftTypography>
                    <SoftInput
                      multiline
                      rows={6}
                      placeholder="Example: We want to optimize our workspace to support hybrid work, improve collaboration between teams, and create a more engaging office environment that attracts talent. We're particularly focused on enhancing meeting spaces and creating quiet zones for focused work."
                      value={projectDrivers}
                      onChange={(e) => setProjectDrivers(e.target.value)}
                      sx={{ mt: 1 }}
                    />
                  </Box>

                  {isAiLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                      <CircularProgress />
                    </Box>
                  )}

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(1)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton
                      onClick={handleProjectDriversSubmit}
                      variant="gradient"
                      color="primary"
                      disabled={isAiLoading}
                      startIcon={isAiLoading ? <CircularProgress size={20} color="inherit" /> : <AutoAwesomeIcon />}
                    >
                      Analyze with AI
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  // Update the activity profile layout
  const renderActivityProfile = () => {
    const activityProfiles = [
      { id: 'highly_collaborative', label: 'Highly\nCollaborative', description: 'Constant team interaction and group work', value: 'highly_collaborative' },
      { id: 'collaborative', label: 'Collaborative', description: 'Regular collaboration with individual work', value: 'collaborative' },
      { id: 'balanced', label: 'Balanced', description: 'Equal mix of collaborative and individual work', value: 'balanced' },
      { id: 'individual', label: 'Individual', description: 'Primarily individual work with some collaboration', value: 'individual' },
      { id: 'highly_individual', label: 'Highly\nIndividual', description: 'Almost exclusively individual focused work', value: 'highly_individual' }
    ];

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Activity Profile
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Select Your Activity Profile
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Choose the profile that best describes your team's typical work activities.
                    </SoftTypography>
                  </Box>
                  <Grid container spacing={2}>
                    {activityProfiles.map((profile) => (
                      <Grid item xs key={profile.id}>
                        <Card 
                          sx={{ 
                            p: 2, 
                            cursor: 'pointer',
                            border: activityProfile?.id === profile.id ? '2px solid #1A73E8' : '1px solid rgba(0,0,0,0.12)',
                            backgroundColor: activityProfile?.id === profile.id ? alpha('#1A73E8', 0.1) : 'background.paper',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setActivityProfile(profile)}
                        >
                          <Stack spacing={1} alignItems="center">
                            <SoftTypography 
                              variant="h6" 
                              fontWeight="bold" 
                              textAlign="center"
                              sx={{ whiteSpace: 'pre-line' }}
                            >
                              {profile.label}
                            </SoftTypography>
                            <SoftTypography 
                              variant="body2" 
                              color="text.secondary" 
                              textAlign="center"
                              sx={{ fontSize: '0.75rem' }}
                            >
                              {profile.description}
                            </SoftTypography>
                          </Stack>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(5)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton 
                      onClick={handleActivityProfileContinue}
                      variant="gradient" 
                      color="primary"
                      disabled={!activityProfile}
                    >
                      Continue
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  // Update the renderAiSuggestions function to add edit functionality
  const [isEditingDrivers, setIsEditingDrivers] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDrivers, setEditedDrivers] = useState('');
  const [editedDescription, setEditedDescription] = useState('');

  const handleSaveDrivers = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        drivers_description: editedDrivers
      });
      setAiResponse({
        ...aiResponse,
        drivers_summary: editedDrivers
      });
      setIsEditingDrivers(false);
    } catch (error) {
      console.error('Error updating drivers:', error);
      setSnackbarMessage('Error updating drivers');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  const handleSaveDescription = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        project_description: editedDescription
      });
      setAiResponse({
        ...aiResponse,
        project_description: editedDescription
      });
      setIsEditingDescription(false);
    } catch (error) {
      console.error('Error updating description:', error);
      setSnackbarMessage('Error updating description');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  const renderAiSuggestions = () => {
    if (!aiResponse) return null;

    // Match AI suggestions with goaldomains
    const matchedGoals = aiResponse.suggested_goals.map(suggestion => {
      const matchedGoalDomain = goaldomains.find(domain => 
        domain.label.toLowerCase().includes(suggestion.label.toLowerCase()) ||
        suggestion.label.toLowerCase().includes(domain.label.toLowerCase())
      );

      return {
        ...suggestion,
        domainId: matchedGoalDomain?.id,
        objectives: suggestion.objectives.map(objective => {
          const matchedObjective = matchedGoalDomain?.goalgoals?.find(g => 
            g.label.toLowerCase().includes(objective.toLowerCase()) ||
            objective.toLowerCase().includes(g.label.toLowerCase())
          );
          return {
            label: objective,
            id: matchedObjective?.id,
            isMatched: !!matchedObjective
          };
        })
      };
    });

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Project Analysis & Recommendations
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  {/* Project Drivers */}
                  <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                        Project Drivers
                      </SoftTypography>
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          if (isEditingDrivers) {
                            handleSaveDrivers();
                          } else {
                            setEditedDrivers(aiResponse.drivers_summary);
                            setIsEditingDrivers(true);
                          }
                        }}
                      >
                        {isEditingDrivers ? <Save /> : <Edit />}
                      </IconButton>
                    </Stack>
                    <Card sx={{ p: 2, backgroundColor: 'background.paper' }}>
                      {isEditingDrivers ? (
                        <SoftInput
                          multiline
                          rows={4}
                          value={editedDrivers}
                          onChange={(e) => setEditedDrivers(e.target.value)}
                          fullWidth
                        />
                      ) : (
                        <SoftTypography variant="body1">
                          {aiResponse.drivers_summary}
                        </SoftTypography>
                      )}
                    </Card>
                  </Box>

                  {/* Strategic Description */}
                  <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                        Strategic Approach
                      </SoftTypography>
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          if (isEditingDescription) {
                            handleSaveDescription();
                          } else {
                            setEditedDescription(aiResponse.project_description);
                            setIsEditingDescription(true);
                          }
                        }}
                      >
                        {isEditingDescription ? <Save /> : <Edit />}
                      </IconButton>
                    </Stack>
                    <Card sx={{ p: 2, backgroundColor: 'background.paper' }}>
                      {isEditingDescription ? (
                        <SoftInput
                          multiline
                          rows={4}
                          value={editedDescription}
                          onChange={(e) => setEditedDescription(e.target.value)}
                          fullWidth
                        />
                      ) : (
                        <SoftTypography variant="body1">
                          {aiResponse.project_description}
                        </SoftTypography>
                      )}
                    </Card>
                  </Box>

                  {/* Suggested Goals */}
                  {matchedGoals.length > 0 && (
                    <Box>
                      <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                        Recommended Goals
                      </SoftTypography>
                      <Grid container spacing={2}>
                        {matchedGoals.map((goal, index) => {
                          const isGoalActive = journey?.goals?.some(g => 
                            g.ref_id === goal.domainId && 
                            g.variant === "goal" && 
                            g.is_active
                          );

                          return (
                            <Grid item xs={12} key={index}>
                              <Card sx={{ 
                                p: 2, 
                                backgroundColor: 'background.paper',
                                border: isGoalActive ? `2px solid ${alpha('#1A73E8', 0.5)}` : `1px solid ${alpha('#1A73E8', 0.1)}`,
                                cursor: goal.domainId ? 'pointer' : 'default',
                                '&:hover': goal.domainId ? {
                                  borderColor: alpha('#1A73E8', 0.5)
                                } : {}
                              }}
                              onClick={() => goal.domainId && handleGoalSelect(goal.domainId)}
                              >
                                <Stack spacing={2}>
                                  <Box>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                      <Stack direction="row" spacing={1} alignItems="center">
                                        <SoftTypography variant="subtitle1" fontWeight="medium">
                                          {goal.label}
                                        </SoftTypography>
                                        {goal.domainId && (
                                          <Chip
                                            size="small"
                                            label={isGoalActive ? "Selected" : "Click to Select"}
                                            color={isGoalActive ? "success" : "primary"}
                                            variant="outlined"
                                          />
                                        )}
                                      </Stack>
                                      <Chip
                                        size="small"
                                        label={`${goal.confidence_score}%`}
                                        variant="outlined"
                                        color={goal.confidence_score >= 70 ? 'success' : goal.confidence_score >= 50 ? 'warning' : 'error'}
                                      />
                                    </Stack>
                                  </Box>
                                  {goal.objectives?.length > 0 && (
                                    <Box>
                                      <SoftTypography variant="caption" color="text.secondary" gutterBottom>
                                        Key Objectives:
                                      </SoftTypography>
                                      <Stack spacing={1}>
                                        {goal.objectives.map((objective, idx) => {
                                          const isObjectiveActive = journey?.goals?.some(g => 
                                            g.ref_id === objective.id && 
                                            g.variant === "objective" && 
                                            g.is_active
                                          );

                                          return (
                                            <Box 
                                              key={idx} 
                                              sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center',
                                                gap: 1,
                                                pl: 1,
                                                py: 0.5,
                                                cursor: objective.id ? 'pointer' : 'default',
                                                '&:hover': objective.id ? {
                                                  backgroundColor: alpha('#1A73E8', 0.05)
                                                } : {}
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                if (objective.id && goal.domainId) {
                                                  handleObjectiveSelect(goal.domainId, objective.id);
                                                }
                                              }}
                                            >
                                              <SoftTypography variant="body2" component="span">
                                                •
                                              </SoftTypography>
                                              <SoftTypography 
                                                variant="body2"
                                                sx={{ 
                                                  flex: 1,
                                                  color: isObjectiveActive ? 'primary.main' : 'text.primary'
                                                }}
                                              >
                                                {objective.label}
                                              </SoftTypography>
                                              {objective.id && (
                                                <Chip
                                                  size="small"
                                                  label={isObjectiveActive ? "Selected" : "Click to Select"}
                                                  color={isObjectiveActive ? "success" : "primary"}
                                                  variant="outlined"
                                                  sx={{ opacity: 0.7 }}
                                                />
                                              )}
                                            </Box>
                                          );
                                        })}
                                      </Stack>
                                    </Box>
                                  )}
                                </Stack>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  )}

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(2)} variant="text" color="secondary">
                      Rewrite prompt
                    </SoftButton>
                    <SoftButton onClick={() => setActiveStep(4)} variant="gradient" color="primary">
                      Continue
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  // Fix the departments continue button by updating the handler
  const handleDepartmentsContinue = () => {
    if (journey?.departments && journey.departments.length > 0) {
      setActiveStep(10); // Move to survey method step
    }
  };

  // Update the renderDepartments function to use the new handler
  const renderDepartments = () => {
    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Departments
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Select Project Departments
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Choose which departments will be involved in this project.
                    </SoftTypography>
                  </Box>

                  {(!journey?.departments || journey.departments.length === 0) && (
                    <Alert
                      severity="warning"
                      icon={<Warning fontSize="small" />}
                      sx={{
                        '& .MuiAlert-message': {
                          width: '100%',
                          display: 'flex',
                          alignItems: { xs: 'start', sm: 'center' },
                          flexDirection: { xs: 'column', sm: 'row' },
                          gap: 2,
                          py: 1
                        },
                        '& .MuiAlert-icon': {
                          alignItems: 'center',
                          mt: '2px'
                        }
                      }}
                      action={
                        <Button
                          color="warning"
                          variant="contained"
                          size="small"
                          startIcon={<Settings />}
                          onClick={() => setOpenDepartmentSelector(true)}
                          sx={{
                            mt: { xs: 1, sm: 0 },
                            boxShadow: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                              backgroundColor: 'warning.dark'
                            }
                          }}
                        >
                          Configure Departments
                        </Button>
                      }
                    >
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          flex: 1,
                          fontWeight: 500
                        }}
                      >
                        No departments are currently selected for this project. To ensure proper data analysis and reporting, please configure your department settings.
                      </Typography>
                    </Alert>
                  )}

                  {journey?.departments && journey.departments.length > 0 && (
                    <Alert
                      severity="success"
                      icon={<CheckCircle fontSize="small" />}
                      sx={{
                        backgroundColor: 'success.lighter',
                        '& .MuiAlert-message': {
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          py: 0.5
                        },
                        '& .MuiAlert-icon': {
                          alignItems: 'center',
                          color: 'success.main'
                        }
                      }}
                      action={
                        <Button
                          color="success"
                          variant="text"
                          size="small"
                          onClick={() => setOpenDepartmentSelector(true)}
                          startIcon={<Settings />}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'success.lighter'
                            }
                          }}
                        >
                          Adjust
                        </Button>
                      }
                    >
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          flex: 1,
                          fontWeight: 500,
                          color: 'success.darker'
                        }}
                      >
                        {journey.departments.length}/{company?.companydepartments?.length || journey.departments.length} company {journey.departments.length === 1 ? 'department' : 'departments'} selected
                      </Typography>
                    </Alert>
                  )}

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(8)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton 
                      onClick={handleDepartmentsContinue}
                      variant="gradient" 
                      color="primary"
                      disabled={!journey?.departments || journey.departments.length === 0}
                    >
                      Continue
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>

        <DepartmentSelector
          open={openDepartmentSelector}
          onClose={() => setOpenDepartmentSelector(false)}
          journeyId={journey?.id}
          onSuccess={handleDepartmentSelectorSuccess}
        />
      </>
    );
  };

  // Add selectedSurveyMethod state
  const [selectedSurveyMethod, setSelectedSurveyMethod] = useState(null);

  // Update handleSurveyMethodSelect
  const handleSurveyMethodSelect = async (methodId) => {
    try {
      setSelectedSurveyMethod(methodId);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        survey_method: methodId,
        isMidpoint: ['team_leader_only', 'team_leader_employee'].includes(methodId),
        isDeepDive: ['employee_only', 'team_leader_employee'].includes(methodId),
        statusUpgrade: true
      });
      
      // Fetch updated data
      await Promise.all([
        dispatch(getJourney(journey.id)),
        dispatch(getSite(journey.site_id))
      ]);

      // Update company's last site ID
      await dispatch(updateCompany(company.id, { last_site_id: journey.site_id }));

      await fetchUpdatedJourney();

      // Navigate based on survey method
      const baseUrl = window.location.origin;
      const navigatePath = methodId === 'none' 
        ? `/journey/define/${journey.id}`
        : `/journey/discover/${journey.id}`;
      
      window.location.href = baseUrl + navigatePath;
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update survey method',
        color: 'error'
      });
    }
  };

  // Update renderSurveyMethod to use selectedSurveyMethod
  const renderSurveyMethod = () => {
    const surveyMethods = [
      {
        id: 'team_leader_employee',
        label: 'Team Leader & Employee',
        description: 'Collect feedback from both team leaders and employees',
        strength: 'SUPERIOR'
      },
      {
        id: 'employee_only',
        label: 'Employee',
        description: 'Collect feedback from employees only',
        strength: 'STRONG'
      },
      {
        id: 'team_leader_only',
        label: 'Team Leader',
        description: 'Collect feedback from team leaders only',
        strength: 'MID'
      },
      {
        id: 'none',
        label: 'None',
        description: 'Dont collect any feedback, proceed to strategy directly.',
        strength: 'MINIMAL'
      }
    ];

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Survey Method
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Select Survey Method
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Choose how you want to conduct the workplace survey
                    </SoftTypography>
                  </Box>
                  <Grid container spacing={2}>
                    {surveyMethods.map((method) => (
                      <Grid item xs={12} sm={6} md={3} key={method.id}>
                        <Card 
                          sx={{ 
                            p: 2, 
                            cursor: 'pointer',
                            border: selectedSurveyMethod === method.id ? '2px solid #1A73E8' : '1px solid rgba(0,0,0,0.12)',
                            backgroundColor: selectedSurveyMethod === method.id ? alpha('#1A73E8', 0.1) : 'background.paper',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              borderColor: '#1A73E8',
                              transform: 'translateY(-2px)'
                            }
                          }}
                          onClick={() => handleSurveyMethodSelect(method.id)}
                        >
                          <Stack spacing={1} alignItems="center" textAlign="center">
                            <SoftTypography variant="h6" fontWeight="bold">
                              {method.label}
                            </SoftTypography>
                            <Chip 
                              label={method.strength}
                              color={
                                method.strength === 'SUPERIOR' ? 'success' :
                                method.strength === 'STRONG' ? 'primary' :
                                method.strength === 'MID' ? 'warning' : 'error'
                              }
                              size="small"
                              variant="outlined"
                            />
                            <SoftTypography variant="body2" color="text.secondary">
                              {method.description}
                            </SoftTypography>
                          </Stack>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(9)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton 
                      onClick={handleComplete}
                      variant="gradient" 
                      color="primary"
                      disabled={!selectedSurveyMethod}
                    >
                      Complete
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  const handleGoalSelect = async (goalId) => {
    try {
      const existingGoal = journey?.goals?.find(g => g.ref_id === goalId && g.variant === "goal");
      
      if (existingGoal) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/goals/update/${existingGoal.id}`, {
          is_active: !existingGoal.is_active
        });
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/goals/create`, {
          label: goaldomains.find(g => g.id === goalId)?.label,
          variant: "goal",
          journey_id: journey.id,
          ref_id: goalId,
          weight: 3,
          isCritical: false,
          is_active: true
        });
      }
      
      await fetchUpdatedJourney();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update goals',
        color: 'error'
      });
    }
  };

  const handleObjectiveSelect = async (goalId, objectiveId) => {
    try {
      const existingObjective = journey?.goals?.find(
        g => g.ref_id === objectiveId && g.variant === "objective"
      );
      
      if (existingObjective) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/goals/update/${existingObjective.id}`, {
          is_active: !existingObjective.is_active
        });
      } else {
        const parentGoal = goaldomains.find(g => g.id === goalId);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/goals/create`, {
          label: parentGoal?.goalgoals?.find(o => o.id === objectiveId)?.label,
          variant: "objective",
          journey_id: journey.id,
          parent_goal: parentGoal?.label,
          ref_id: objectiveId,
          weight: 3,
          isCritical: false,
          is_active: true
        });
      }
      
      await fetchUpdatedJourney();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update objectives',
        color: 'error'
      });
    }
  };

  // Add handleComplete function
  const handleComplete = async () => {
    try {
      // Update journey with all collected data
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        work_model: workModel.value,
        activity_profile: activityProfile.value,
        sound_profile: soundProfile.value,
        privacy_profile: privacyProfile.value,
        departments: selectedDepartments.map(d => d.id),
        survey_method: selectedSurveyMethod
      });

      // Close dialog
      handleClose();

      // Navigate based on survey method
      const baseUrl = window.location.origin;
      const navigatePath = selectedSurveyMethod === 'none' 
        ? `/journey/define`
        : `/journey/discover`;
      
      window.location.href = baseUrl + navigatePath;

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.error('Error completing project setup:', error);
      setSnackbarMessage(error.response?.data?.error || 'Error completing project setup');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  // Add handler functions
  const handleWorkModelSelect = (model) => {
    setWorkModel(model);
  };

  const handleWorkModelContinue = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        work_model: workModel.value
      });
      setActiveStep(6);
    } catch (error) {
      console.error('Error updating work model:', error);
      setSnackbarMessage(error.response?.data?.error || 'Error updating work model');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  const handleActivityProfileContinue = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        activity_profile: activityProfile.value
      });
      setActiveStep(7);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || 'Error updating activity profile');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  const handleSoundProfileContinue = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        sound_profile: soundProfile.value
      });
      setActiveStep(8);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || 'Error updating sound profile');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  const handlePrivacyProfileContinue = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/journeys/update/${journey.id}`, {
        privacy_profile: privacyProfile.value
      });
      setActiveStep(9);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || 'Error updating privacy profile');
      setSnackbarColor('error');
      setOpenSnackbar(true);
    }
  };

  // Update renderStep function
  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return renderSiteSelection();
      case 1:
        return renderSiteConfirmation();
      case 2:
        return renderProjectDrivers();
      case 3:
        return renderAiSuggestions();
      case 4:
        return renderModuleCustomization();
      case 5:
        return renderWorkModel();
      case 6:
        return renderActivityProfile();
      case 7:
        return renderSoundProfile();
      case 8:
        return renderPrivacyProfile();
      case 9:
        return renderDepartments();
      case 10:
        return renderSurveyMethod();
      default:
        return null;
    }
  };

  // Update DepartmentSelector success handler
  const handleDepartmentSelectorSuccess = async () => {
    setOpenDepartmentSelector(false);
    try {
      // Get all company departments
      const companyDepartments = company?.companydepartments || [];
      
      // Create department selections for all departments
      await Promise.all(companyDepartments.map(dept => 
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/journeys/${journey.id}/departments`, {
          department_id: dept.id
        }).catch(err => console.error(`Error adding department ${dept.id}:`, err))
      ));

      // Fetch updated journey data
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/journeys/${journey.id}`);
      setJourney(response.data);
    } catch (error) {
      console.error('Error fetching updated journey:', error);
      setSnackbar({
        open: true,
        message: 'Error updating departments',
        color: 'error'
      });
    }
  };

  // Add back the missing render functions
  const renderModuleCustomization = () => {
    // Calculate costs for both plans
    const expressCost = calculateProjectCost(selectedSite.employees, 'express');
    const elevateCost = calculateProjectCost(selectedSite.employees, 'elevate');
    const upgradeCost = elevateCost - expressCost;

    // Define all Elevate modules
    const allModules = [
      { label: 'Goals', description: 'Define and track strategic workplace goals and objectives' },
      { label: 'Work Model', description: 'Design and implement flexible working arrangements' },
      { label: 'Office Activities', description: 'Map and optimize office activities and workflows' },
      { label: 'Location', description: 'Analyze and optimize office location strategies' },
      { label: 'Budget', description: 'Plan and manage workplace transformation budgets' }
    ];

    // Get recommended module labels
    const recommendedModuleLabels = aiResponse?.suggested_modules?.map(m => m.name) || [];

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Elevate Modules
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ 
                p: 3, 
                mb: 3, 
                backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1),
                border: '1px solid',
                borderColor: 'info.main'
              }}>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box>
                      <SoftTypography variant="h5" fontWeight="bold" color="info.main">
                        Upgrade to Elevate
                      </SoftTypography>
                      <SoftTypography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                        Access all modules and advanced features
                      </SoftTypography>
                    </Box>
                    <Box textAlign="right">
                      <SoftTypography variant="h4" color="info.main" fontWeight="bold">
                        €{upgradeCost.toLocaleString()}
                      </SoftTypography>
                      <SoftTypography variant="caption" color="text.secondary">
                        One-time upgrade cost
                      </SoftTypography>
                    </Box>
                  </Stack>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {allModules.map((module) => (
                  <Grid item xs={12} md={4} key={module.label}>
                    <Card sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'all 0.2s ease-in-out',
                      border: recommendedModuleLabels.includes(module.label) ? '2px solid' : '1px solid',
                      borderColor: recommendedModuleLabels.includes(module.label) ? 'success.main' : 'divider',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: (theme) => theme.shadows[4]
                      }
                    }}>
                      <Box sx={{ 
                        p: 2,
                        backgroundColor: (theme) => recommendedModuleLabels.includes(module.label) 
                          ? alpha(theme.palette.success.main, 0.1)
                          : alpha(theme.palette.primary.main, 0.05),
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                        <SoftTypography variant="subtitle1" fontWeight="bold">
                          {module.label}
                        </SoftTypography>
                        {recommendedModuleLabels.includes(module.label) && (
                          <Chip
                            label="Recommended"
                            color="success"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      </Box>
                      <Box sx={{ p: 2, flex: 1 }}>
                        <SoftTypography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ lineHeight: 1.6 }}
                        >
                          {module.description}
                        </SoftTypography>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <SoftButton
            onClick={() => setActiveStep(5)}
            variant="outlined"
            color="primary"
          >
            Continue with Express
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            onClick={() => {
              setSelectedProjectType('elevate');
              setTotalCost(elevateCost);
              setActiveStep(5);
            }}
            startIcon={<AutoAwesomeIcon />}
          >
            Upgrade to Elevate
          </SoftButton>
        </DialogActions>
      </>
    );
  };

  const renderWorkModel = () => {
    const workModels = [
      { id: 'office_5_0', label: '5/0', description: '5 days office, 0 days remote', value: 100 },
      { id: 'office_4_1', label: '4/1', description: '4 days office, 1 day remote', value: 80 },
      { id: 'office_3_2', label: '3/2', description: '3 days office, 2 days remote', value: 60 },
      { id: 'office_2_3', label: '2/3', description: '2 days office, 3 days remote', value: 40 },
      { id: 'office_1_4', label: '1/4', description: '1 day office, 4 days remote', value: 20 }
    ];

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Work Model
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Select Your Work Model
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Choose the work model that best describes your organization's approach to office presence.
                    </SoftTypography>
                  </Box>
                  <Grid container spacing={2}>
                    {workModels.map((model) => (
                      <Grid item xs key={model.id}>
                        <Card 
                          sx={{ 
                            p: 2, 
                            cursor: 'pointer',
                            border: workModel?.id === model.id ? '2px solid #1A73E8' : '1px solid rgba(0,0,0,0.12)',
                            backgroundColor: workModel?.id === model.id ? alpha('#1A73E8', 0.1) : 'background.paper',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => handleWorkModelSelect(model)}
                        >
                          <Stack spacing={1} alignItems="center">
                            <SoftTypography variant="h5" fontWeight="bold" textAlign="center">
                              {model.label}
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text.secondary" textAlign="center">
                              {model.description}
                            </SoftTypography>
                          </Stack>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(4)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton 
                      onClick={handleWorkModelContinue}
                      variant="gradient" 
                      color="primary"
                      disabled={!workModel}
                    >
                      Continue
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  const renderSoundProfile = () => {
    const soundProfiles = [
      { id: 'fully_open', label: 'Fully Open', description: 'No acoustic barriers or separations', value: 'fully_open' },
      { id: 'open', label: 'Open', description: 'Minimal acoustic separation', value: 'open' },
      { id: 'balanced', label: 'Balanced', description: 'Mix of open and enclosed spaces', value: 'balanced' },
      { id: 'room', label: 'Room', description: 'Primarily enclosed spaces', value: 'room' },
      { id: 'fully_room', label: 'Fully Room', description: 'Complete acoustic separation', value: 'fully_room' }
    ];

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Sound Profile
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Select Your Sound Profile
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Choose the sound environment that best suits your workspace needs.
                    </SoftTypography>
                  </Box>
                  <Grid container spacing={2}>
                    {soundProfiles.map((profile) => (
                      <Grid item xs key={profile.id}>
                        <Card 
                          sx={{ 
                            p: 2, 
                            cursor: 'pointer',
                            border: soundProfile?.id === profile.id ? '2px solid #1A73E8' : '1px solid rgba(0,0,0,0.12)',
                            backgroundColor: soundProfile?.id === profile.id ? alpha('#1A73E8', 0.1) : 'background.paper',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setSoundProfile(profile)}
                        >
                          <Stack spacing={1} alignItems="center">
                            <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                              {profile.label}
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text.secondary" textAlign="center">
                              {profile.description}
                            </SoftTypography>
                          </Stack>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(6)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton 
                      onClick={handleSoundProfileContinue}
                      variant="gradient" 
                      color="primary"
                      disabled={!soundProfile}
                    >
                      Continue
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  const renderPrivacyProfile = () => {
    const privacyProfiles = [
      { id: 'fully_public', label: 'Fully Public', description: 'Complete visual transparency', value: 'fully_public' },
      { id: 'public', label: 'Public', description: 'High visibility with minimal barriers', value: 'public' },
      { id: 'balanced', label: 'Balanced', description: 'Mix of open and private spaces', value: 'balanced' },
      { id: 'screened', label: 'Screened', description: 'Partial visual barriers', value: 'screened' },
      { id: 'private', label: 'Private', description: 'Complete visual privacy', value: 'private' }
    ];

    return (
      <>
        <DialogTitle>
          <SoftTypography variant="h4" fontWeight="bold">
            Privacy Profile
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, backgroundColor: 'background.default' }}>
                <Stack spacing={3}>
                  <Box>
                    <SoftTypography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                      Select Your Privacy Profile
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text.secondary" gutterBottom>
                      Choose the level of privacy that best suits your workspace requirements.
                    </SoftTypography>
                  </Box>
                  <Grid container spacing={2}>
                    {privacyProfiles.map((profile) => (
                      <Grid item xs key={profile.id}>
                        <Card 
                          sx={{ 
                            p: 2, 
                            cursor: 'pointer',
                            border: privacyProfile?.id === profile.id ? '2px solid #1A73E8' : '1px solid rgba(0,0,0,0.12)',
                            backgroundColor: privacyProfile?.id === profile.id ? alpha('#1A73E8', 0.1) : 'background.paper',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setPrivacyProfile(profile)}
                        >
                          <Stack spacing={1} alignItems="center">
                            <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                              {profile.label}
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text.secondary" textAlign="center">
                              {profile.description}
                            </SoftTypography>
                          </Stack>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <SoftButton onClick={() => setActiveStep(7)} variant="text" color="secondary">
                      Back
                    </SoftButton>
                    <SoftButton 
                      onClick={handlePrivacyProfileContinue}
                      variant="gradient" 
                      color="primary"
                      disabled={!privacyProfile}
                    >
                      Continue
                    </SoftButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        {renderStep()}
      </Dialog>
      <SiteDialog
        open={newSiteDialogOpen}
        onClose={() => setNewSiteDialogOpen(false)}
        onSubmit={handleCreateNewSite}
        companyId={companyId}
        title="Create a New Site"
        submitButtonText="Create Site"
      />
    </>
  );
}

export default ProjectDialog; 