/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Delete from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import LoadingScreen from "../../../components/LoadingScreen";
import SoftAvatar from "../../../components/SoftAvatar";
import SoftBox from "../../../components/SoftBox";
import SoftSelect from "../../../components/SoftSelect";
import SoftTypography from "../../../components/SoftTypography";

function ProfilesList({ title, teamMembers = [], onRoleChange, onSiteChange, onDelete, sites = [] }) {
  console.log('teamMembers profilesList', teamMembers);
  if (!teamMembers) return <LoadingScreen />;
  
  // Role options for the dropdown
  const roleOptions = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
    { value: "Read only", label: "Read only" }
  ];

  // Create site options with "All" as the first option
  const siteOptions = [
    { value: 0, label: "All" },
    ...(sites.map(site => ({ value: site.id, label: site.name || site.label })) || [])
  ];

  const renderProfiles = (teamMembers || []).map((member) => (
    <SoftBox key={member.user_id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <SoftBox mr={2}>
        <SoftAvatar src={member.picture} alt={member.name} variant="rounded" shadow="md" />
      </SoftBox>
      <SoftBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        width="40%"
      >
        <SoftTypography variant="button" fontWeight="medium">
          {member.name}
        </SoftTypography>
        <SoftTypography variant="caption" color="text">
          {member.user_metadata?.role || member.email}
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" alignItems="center" ml="auto">
        <SoftBox mr={1} width="120px">
          <SoftSelect
            size="small"
            defaultValue={roleOptions.find(option => option.value === (member.user_metadata?.internalrole || "User"))}
            options={roleOptions}
            onChange={(option) => onRoleChange && onRoleChange(member.user_id, option.value)}
            placeholder="Role"
          />
        </SoftBox>
        <SoftBox mr={1} width="120px">
          <SoftSelect
            size="small"
            defaultValue={siteOptions.find(option => option.value === (member.user_metadata?.site_id || "all"))}
            options={siteOptions}
            onChange={(option) => onSiteChange && onSiteChange(member.user_id, option.value)}
            placeholder="Site"
          />
        </SoftBox>
        <IconButton 
          color="error" 
          size="small" 
          onClick={() => onDelete && onDelete(member.user_id)}
        >
          <Delete />
        </IconButton>
      </SoftBox>
    </SoftBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ProfilesList
ProfilesList.defaultProps = {
  title: "profiles list",
  teamMembers: [],
  sites: [],
  onRoleChange: () => {},
  onSiteChange: () => {},
  onDelete: () => {}
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string,
  teamMembers: PropTypes.array,
  sites: PropTypes.array,
  onRoleChange: PropTypes.func,
  onSiteChange: PropTypes.func,
  onDelete: PropTypes.func
};

export default ProfilesList;
