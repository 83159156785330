import { Box, Stack, Icon, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import SoftTypography from "../SoftTypography";
import SoftButton from "../SoftButton";
import axios from 'axios';

const TeamMemberList = ({ 
  teamMembers, 
  company, 
  onMemberRemove, 
  onInvitationRevoke,
  showActions = true,
  styles = {} // Allow custom styles to be passed
}) => {
  // Get pending invitations
  const pendingInvitations = company?.invitations?.filter(inv => 
    inv.variant === "team_member" && 
    inv.used_at === null
  ) || [];

  // Transform invitations to match team member structure
  const transformedInvitations = pendingInvitations.map(inv => ({
    email: inv.email,
    email_verified: false,
    name: inv.email.split('@')[0], // Use email username as name
    user_metadata: {
      company_id: inv.company_id,
      role: "team_member",
      isAdmin: false,
      company_verified: false,
      hasCompletedSurvey: false,
      hasCompletedIntro: false
    },
    invitation_id: inv.id // Store the invitation ID for revocation
  }));

  // Combine team members and pending invitations
  const combinedMembers = [
    ...teamMembers,
    ...transformedInvitations
  ];

  // Default styles
  const defaultStyles = {
    teamList: {
      mt: 2,
      p: 2,
      borderRadius: 2,
      bgcolor: 'background.paper',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    teamMemberItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: 1.5,
      borderRadius: 1,
      '&:hover': {
        bgcolor: 'action.hover',
      },
    },
    ...styles
  };

  // Render individual team member
  const renderTeamMember = (member) => (
    <Box
      key={member.invitation_id || member.user_id}
      sx={defaultStyles.teamMemberItem}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Email sx={{ color: member.email_verified ? 'success.main' : 'text.secondary' }} />
        <Stack>
          {member.name && (
            <SoftTypography variant="caption" fontWeight="medium">
              {member.name}
            </SoftTypography>
          )}
          <SoftTypography
            variant="button"
            fontWeight="regular"
            color={member.email_verified ? 'text.primary' : 'text.secondary'}
          >
            {member.email}
            {!member.email_verified && ' (Pending)'}
          </SoftTypography>
        </Stack>
      </Stack>
      {showActions && (
        member.invitation_id ? (
          <SoftButton
            variant="text"
            color="error"
            onClick={() => onInvitationRevoke(member.invitation_id)}
            size="small"
          >
            Revoke
          </SoftButton>
        ) : (
          <SoftButton
            variant="text"
            color="error"
            onClick={() => onMemberRemove(member.user_id)}
            size="small"
          >
            Remove
          </SoftButton>
        )
      )}
    </Box>
  );

  if (combinedMembers.length === 0) {
    return (
      <Box sx={defaultStyles.teamList}>
        <SoftTypography variant="body2" color="text.secondary" textAlign="center">
          No team members found
        </SoftTypography>
      </Box>
    );
  }

  return (
    <Box sx={defaultStyles.teamList}>
      <SoftTypography variant="h6" color="primary" mb={2}>
        Team Members
      </SoftTypography>
      <Stack spacing={1}>
        {combinedMembers.map((member) => renderTeamMember(member))}
      </Stack>
    </Box>
  );
};

export default TeamMemberList; 