import {
  AccountBalance,
  AspectRatio,
  Business,
  Chair,
  CompareArrows,
  Dashboard,
  Domain,
  Groups,
  Info,
  Insights,
  LocationOn,
  SupportAgent,
  Timeline,
  WorkOutline
} from '@mui/icons-material';
import {
  alpha,
  Box,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import SoftButton from './SoftButton';
import SoftTypography from './SoftTypography';

const StyledCard = styled(Card)(({ theme, selected, hasSelection, journey, basePaths, pathKey }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  border: `2px solid ${selected ? (
    journey?.path === pathKey ? theme.palette.success.main : theme.palette.primary.main
  ) : 'transparent'}`,
  backgroundColor: selected ? (
    journey?.path === pathKey ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.primary.main, 0.1)
  ) : theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
  ...(hasSelection && !selected ? {
    '& .MuiTypography-root': {
      color: alpha(theme.palette.text.primary, journey?.path && basePaths[pathKey]?.baseRate > basePaths[journey.path]?.baseRate ? 0.75 : 0.4),
    },
    opacity: journey?.path && basePaths[pathKey]?.baseRate > basePaths[journey.path]?.baseRate ? 0.85 : 0.6,
  } : {}),
  '& .MuiCardContent-root': {
    paddingBottom: `${theme.spacing(5)} !important`,
  }
}));

const FeatureChip = styled(Chip)(({ theme }) => ({
  margin: '4px',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '& .MuiChip-icon': {
    color: theme.palette.primary.main,
  }
}));

const PriceTag = styled(Box)(({ theme, journey, basePaths, pathKey }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  padding: '2px 8px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: journey?.path && (
    pathKey === journey.path || basePaths[pathKey]?.baseRate < basePaths[journey.path]?.baseRate
  ) 
    ? alpha(theme.palette.success.main, 0.1)
    : alpha(theme.palette.primary.main, 0.1),
  color: journey?.path && (
    pathKey === journey.path || basePaths[pathKey]?.baseRate < basePaths[journey.path]?.baseRate
  )
    ? theme.palette.success.main
    : theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '0.875rem'
}));

const PriceSection = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  padding: theme.spacing(0),
  borderRadius: 3,
  backdropFilter: 'blur(8px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(0.5),
  width: '100%',
  zIndex: 1
}));

const PriceCalculation = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  width: '100%'
}));

const OriginalPrice = styled(SoftTypography)(({ theme }) => ({
  textDecoration: 'line-through',
  color: theme.palette.text.secondary,
  fontSize: '0.875rem'
}));

export default function PathSelectionDialog({ open, onClose, onSave, journey }) {
  const [selectedPath, setSelectedPath] = useState(journey?.path || null);
  const [isAdvanced, setIsAdvanced] = useState(journey?.mode === 'advanced');

  useEffect(() => {
    setSelectedPath(journey?.path || null);
    setIsAdvanced(journey?.mode === 'advanced');
  }, [journey]);
  
  // Define base paths first without pricing
  const basePaths = {
   /*  survey: {
      path: 'Survey',
      title: "Survey Only",
      description: "Get insights from your employees through our comprehensive workplace survey",
      price: "Basic",
      baseRate: 25,
      discount: 0,
      priceDetails: "One-time payment",
      phases: {
        discover: [
          { icon: <Groups />, label: "Employee Perspective" }
        ]
      }
    }, */
    light: {
      path: 'Light',
      title: "Express",
      description: "Focused approach on space optimization and essential workplace elements",
      price: "Light",
      baseRate: 50,
      discount: 0.10,
      priceDetails: "One-time payment",
      phases: {
        discover: [
          { icon: <Groups />, label: "Employee Perspective" },
          { icon: <Business />, label: "Management Perspective" },
          { icon: <Insights />, label: "Insights" }
        ],
        define: [
          { icon: <AspectRatio />, label: "Dimensioning" }
        ],
        design: [
          { icon: <Chair />, label: "Work Settings" },
          { icon: <SupportAgent />, label: "Support Areas" },
          { icon: <Domain />, label: "Business Specific" }
        ]
      }
    },
    plus: {
      path: 'Plus',
      title: "Elevate",
      description: "Complete workplace transformation project with comprehensive analysis and implementation",
      price: "Plus",
      baseRate: 150,
      discount: 0.25,
      priceDetails: "One-time payment",
      phases: {
        discover: [
          { icon: <Groups />, label: "Employee Perspective" },
          { icon: <Business />, label: "Management Perspective" },
          { icon: <Insights />, label: "Insights" }
        ],
        define: [
          { icon: <Domain />, label: "Organisation" },
          { icon: <WorkOutline />, label: "Work Model" },
          { icon: <LocationOn />, label: "Location" },
          { icon: <AccountBalance />, label: "Budget" },
          { icon: <AspectRatio />, label: "Dimensioning" },
          { icon: <CompareArrows />, label: "Way of Working" }
        ],
        design: [
          { icon: <Chair />, label: "Work Settings" },
          { icon: <SupportAgent />, label: "Support Areas" },
          { icon: <Domain />, label: "Business Specific" },
          { icon: <Dashboard />, label: "Zoning" },/* 
          { icon: <EjectOutlined />, label: "Sustainability" },
          { icon: <Settings />, label: "Technical Specs" } */
        ]
      }
    }
  };

  // Calculate project cost function (from WpHome.js)
  const calculateProjectCost = (employeeCount, projectType) => {
    let cost = 0;
    const multiplier = projectType === 'elevate' ? 3 : 1;

    if (employeeCount <= 50) {
      cost = employeeCount * 50;
    } else if (employeeCount <= 100) {
      cost = 50 * 50 + (employeeCount - 50) * 40;
    } else if (employeeCount <= 200) {
      cost = 50 * 50 + 50 * 40 + (employeeCount - 100) * 30;
    } else if (employeeCount <= 300) {
      cost = 50 * 50 + 50 * 40 + 100 * 30 + (employeeCount - 200) * 20;
    } else if (employeeCount <= 1000) {
      cost = 50 * 50 + 50 * 40 + 100 * 30 + 100 * 20 + (employeeCount - 300) * 10;
    } else {
      cost = 50 * 50 + 50 * 40 + 100 * 30 + 100 * 20 + 700 * 10;
    }

    return cost * multiplier;
  };

  // Calculate price function
  const calculatePrice = (baseRate, discount = 0, pathKey) => {
    const employees = journey?.employees || 0;
    const currentPath = journey?.path;
    
    // Map path keys to project types for calculateProjectCost
    const pathToProjectType = {
      light: 'standard',
      plus: 'elevate'
    };
    
    // Calculate costs using the new function
    const projectType = pathToProjectType[pathKey] || 'standard';
    const currentProjectType = currentPath ? pathToProjectType[currentPath] : null;
    const newCost = calculateProjectCost(employees, projectType);
    const currentCost = currentPath ? calculateProjectCost(employees, currentProjectType) : 0;
    
    const differentialCost = Math.max(0, newCost - currentCost);
    
    const isUpgrade = currentPath !== null && differentialCost > 0;
    const isCurrent = currentPath === pathKey;
    const isIncluded = currentPath && newCost <= currentCost && !isCurrent;
    
    const originalPrice = differentialCost;
    const discountedPrice = (!isUpgrade && discount > 0) ? originalPrice * (1 - discount) : originalPrice;
    
    return {
      original: (!isUpgrade && discount > 0 && originalPrice > 0) ? `€${originalPrice.toLocaleString()}` : null,
      total: isIncluded ? 'Already Included' : (isCurrent ? '' : `€${discountedPrice.toLocaleString()}`),
      perEmployee: baseRate,
      calculation: originalPrice > 0 ? `${employees} employees with ${projectType} plan` : null,
      discount: (!isUpgrade && discount > 0 && originalPrice > 0) ? `${discount * 100}% discount applied` : null,
      isUpgrade,
      isCurrent,
      isIncluded
    };
  };

  // Create final paths object with pricing
  const paths = Object.entries(basePaths).reduce((acc, [key, path]) => ({
    ...acc,
    [key]: {
      ...path,
      pricing: calculatePrice(path.baseRate, path.discount, key)
    }
  }), {});

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2
        }
      }}
    >
      <DialogTitle>
        <SoftTypography variant="h4" fontWeight="bold" textAlign="center">
          Select Your Transformation Path
        </SoftTypography>
      </DialogTitle>

      <DialogContent>
       
        <Stack direction="row" spacing={3}>
          {Object.entries(paths).map(([key, path]) => (
            <StyledCard
              key={key}
              selected={selectedPath === key}
              hasSelection={selectedPath !== null}
              journey={journey}
              basePaths={basePaths}
              pathKey={key}
              onClick={() => {
               /*  if (!journey?.path || 
                    basePaths[key].baseRate >= (journey?.path ? basePaths[journey.path].baseRate : 0) ||
                    key === journey.path) { */
                  setSelectedPath(key);
                /* } */
              }}
              sx={{ 
                width: '50%', 
                display: 'flex', 
                flexDirection: 'column', 
                position: 'relative',
                cursor: journey?.path && 
                        basePaths[key].baseRate < basePaths[journey.path].baseRate && 
                        key !== journey.path ? 'not-allowed' : 'pointer',
              }}
            >
              <PriceTag
                journey={journey}
                basePaths={basePaths}
                pathKey={key}
              >
                {path.price}
              </PriceTag>
              
              <CardContent>
                <Box sx={{ flex: 1 }}>
                  <SoftTypography variant="h5" gutterBottom fontWeight="bold" color="primary">
                    {path.title}
                  </SoftTypography>
                  <SoftTypography variant="body2" color="text.secondary" paragraph>
                    {path.description}
                  </SoftTypography>

                  {Object.entries(path.phases).map(([phase, features]) => (
                    <Box key={phase} sx={{ mb: 2 }}>
                      <SoftTypography
                        variant="subtitle2"
                        color="primary"
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          mb: 1
                        }}
                      >
                        <Timeline sx={{ mr: 1, verticalAlign: 'middle' }} />
                        {phase}
                      </SoftTypography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {features.map((feature, index) => (
                          <FeatureChip
                            key={index}
                            icon={feature.icon}
                            label={feature.label}
                            size="small"
                            sx={{
                              ...(journey?.path && paths[journey.path]?.phases[phase]?.some(
                                f => f.label === feature.label
                              ) && {
                                backgroundColor: (theme) => alpha(theme.palette.success.main, 0.1),
                                '& .MuiChip-icon': {
                                  color: 'success.main',
                                }
                              })
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
                  <Divider />
                <PriceSection>
                  {path.pricing.original && (
                    <OriginalPrice>
                      {path.pricing.original}
                    </OriginalPrice>
                  )}
                  {path.pricing.total && (
                  <SoftTypography variant="h4" color="primary" fontWeight="bold">
                    {path.pricing.total}
                  </SoftTypography>)}

                  {/* Only show price calculation and details if not current path and not already included */}
                  {!path.pricing.isCurrent && !path.pricing.isIncluded && (
                    <>
                      {(path.pricing.calculation || path.pricing.discount) && (
                        <PriceCalculation>
                          <SoftTypography variant="caption" color="text.secondary">
                            {path.pricing.calculation}
                          </SoftTypography>
                          {path.pricing.discount && (
                            <SoftTypography 
                              variant="caption" 
                              color="success.main" 
                              fontWeight="medium"
                            >
                              {path.pricing.discount}
                            </SoftTypography>
                          )}
                        </PriceCalculation>
                      )}
                      {path.priceDetails && (
                        <SoftTypography variant="body2" color="text.secondary">
                          {path.priceDetails}
                        </SoftTypography>
                      )}
                    </>
                  )}
                  {path.pricing.isUpgrade && (
                    <SoftTypography variant="caption" color="info.main">
                      Upgrade price from current plan
                    </SoftTypography>
                  )}
                </PriceSection>
              </CardContent>
            </StyledCard>
          ))}
        </Stack>


       {/*  <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          {[
            {
              title: "Easy Mode",
              description: "Essential choices with guided setup. Perfect for quick starts and straightforward projects.",
              value: false
            },
            {
              title: "Advanced Mode",
              description: "Full range of options with extensive detail and complete workplace data access. For complex projects.",
              value: true
            }
          ].map((mode) => (
            <StyledCard
              key={mode.title}
              selected={isAdvanced === mode.value}
              hasSelection={true}
              onClick={() => setIsAdvanced(mode.value)}
              sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}
            >
              <CardContent sx={{ flex: 1 }}>
                <SoftTypography variant="h5" gutterBottom fontWeight="bold" color="primary">
                  {mode.title}
                </SoftTypography>
                <SoftTypography variant="body2" color="text.secondary">
                  {mode.description}
                </SoftTypography>
              </CardContent>
            </StyledCard>
          ))}
        </Stack> */}
         <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1, 
          mt: 3, 
          p: 2, 
          borderRadius: 2,
          backgroundColor: (theme) => alpha(theme.palette.info.main, 0.1)
        }}>
          <Info color="info" />
          <SoftTypography variant="body2" color="info.main">
            You can upgrade your path at any time during your project, but discounts will only be applied to new purchases. Your progress will be preserved.
          </SoftTypography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <SoftButton onClick={onClose} variant="outlined" color="primary">
          Cancel
        </SoftButton>
        {selectedPath && (
          (!journey?.path || selectedPath !== journey.path) && (
            <SoftButton
              onClick={() => onSave({ path: selectedPath, mode: isAdvanced ? 'advanced' : 'easy' })}
              variant="gradient"
              color="primary"
            >
              {selectedPath && journey?.path ? 
                (basePaths[selectedPath].baseRate > basePaths[journey.path].baseRate ? 'Upgrade' : 'Select') 
                : 'Purchase'}
            </SoftButton>
          )
        )}
      </DialogActions>
    </Dialog>
  );
} 