import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

const ContractDialog = ({ open, onClose, onSubmit, companyId, sites = [] }) => {
  const [formData, setFormData] = useState({
    site_id: null,
    name: '',
    address: '',
    city: '',
    postal_code: '',
    country: '',
    size: '',
    annual_rent: '',
    expiration_date: '',
    termination_period: '',
  });

  const [selectedSite, setSelectedSite] = useState(null);

  useEffect(() => {
    if (selectedSite) {
      setFormData(prev => ({
        ...prev,
        address: selectedSite.address || '',
        city: selectedSite.city || '',
        postal_code: selectedSite.postal_code || '',
        country: selectedSite.country || '',
        size: selectedSite.size || '',
      }));
    }
  }, [selectedSite]);

  const handleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSiteChange = (selectedOption) => {
    const site = sites.find(s => s.id === selectedOption.value);
    setSelectedSite(site);
    setFormData(prev => ({
      ...prev,
      site_id: selectedOption ? selectedOption.value : null,
      name: selectedOption ? `${site.label} Contract` : '',
    }));
  };

  const handleSubmit = async () => {
    try {
      const contractData = {
        ...formData,
        company_id: companyId,
        size: parseInt(formData.size),
        annual_rent: parseFloat(formData.annual_rent),
        monthly_rent: parseFloat(formData.annual_rent) / 12,
      };

      await onSubmit(contractData);
      onClose();
    } catch (error) {
      console.error('Error creating contract:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          p: 2,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SoftTypography variant="h4" fontWeight="medium">
            Add New Contract
          </SoftTypography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Stack spacing={3}>
            <SoftSelect
              placeholder="Select Site"
              options={sites.map(site => ({
                value: site.id,
                label: site.label
              }))}
              value={selectedSite ? {
                value: selectedSite.id,
                label: selectedSite.label
              } : null}
              onChange={handleSiteChange}
            />

            {selectedSite && (
              <>
                <SoftInput
                  placeholder="Contract Name"
                  value={formData.name}
                  onChange={handleChange('name')}
                  icon={{
                    component: "description",
                    direction: "left"
                  }}
                />

                <SoftTypography variant="h6" fontWeight="medium">
                 Contract Details
                </SoftTypography>

                <SoftInput
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange('address')}
                  icon={{
                    component: "location_on",
                    direction: "left"
                  }}
                />

                <SoftInput
                  placeholder="City"
                  value={formData.city}
                  onChange={handleChange('city')}
                  icon={{
                    component: "location_city",
                    direction: "left"
                  }}
                />

                <SoftInput
                  placeholder="Postal Code"
                  value={formData.postal_code}
                  onChange={handleChange('postal_code')}
                  icon={{
                    component: "mail",
                    direction: "left"
                  }}
                />

                <SoftInput
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange('country')}
                  icon={{
                    component: "public",
                    direction: "left"
                  }}
                />

                <SoftInput
                  placeholder="Size (m²)"
                  value={formData.size}
                  onChange={handleChange('size')}
                  type="number"
                  icon={{
                    component: "straight",
                    direction: "left"
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                  }}
                />

                <SoftInput
                  placeholder="Annual Rent"
                  value={formData.annual_rent}
                  onChange={handleChange('annual_rent')}
                  type="number"
                  icon={{
                    component: "euro",
                    direction: "left"
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />

                <SoftInput
                  placeholder="Expiration Date"
                  value={formData.expiration_date}
                  onChange={handleChange('expiration_date')}
                  type="date"
                  icon={{
                    component: "event",
                    direction: "left"
                  }}
                  InputLabelProps={{ shrink: true }}
                />

                <SoftInput
                  placeholder="Termination Period (months)"
                  value={formData.termination_period}
                  onChange={handleChange('termination_period')}
                  type="number"
                  icon={{
                    component: "schedule",
                    direction: "left"
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">months</InputAdornment>,
                  }}
                />
              </>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <SoftButton onClick={onClose} variant="text" color="dark">
          Cancel
        </SoftButton>
        <SoftButton
          onClick={handleSubmit}
          variant="gradient"
          color="primary"
          disabled={!selectedSite || !formData.name || !formData.size || !formData.annual_rent || !formData.expiration_date || !formData.termination_period}
        >
          Create Contract
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContractDialog; 