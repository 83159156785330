import { Favorite, RocketLaunch, CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import React from 'react';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SupportIcon from '@mui/icons-material/Support';
import AddIcon from '@mui/icons-material/Add';
import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Stack, TextField, Menu, MenuItem, ListItemIcon, ListItemText, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import Slider from '@mui/material/Slider';
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import axios from 'axios';
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { SnackbarProvider } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import 'react-internet-meter/dist/index.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import brandwidth from "./assets/images/logowidth.png";
import brand from "./assets/images/lohow.png";
import theme from "./assets/theme";
import AiAgentChat from './components/_dashboard/AiAgentChat';
import ErrorBoundary from "./components/ErrorBoundary";
import IntroWrapper from './components/IntroDialog/IntroWrapper';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import PasswordChangeWrapper from './components/PasswordChangeWrapper';
import ScrollToTop from './components/ScrollToTop';
import { geoConfig, gridConfig } from './config';
import { CableContext, CableProvider } from './contexts/cable';
import Sidenav from "./examples/Sidenav";
import useAuth from './hooks/useAuth';
import NoInternetConnection from './NoInternet';
import { getAllCompanies, getCompany, getDepartments, getTeamMembers } from "./redux/slices/company";
import { getCompanyContracts, getCompanyJourneys, getCompanySites, getJourney, getProspect, updateContract, updateProperty } from "./redux/slices/site";
import { getAllDeepDives } from "./redux/slices/survey";
import { getActivityprofiles, getAllWorkplaces, getGeneralAreas, getGoaldomains, getMeetings, getMobilityprofiles, getOfficefeatures, getRoles, getSuActivityprofiles, getSuWorkplacedistributions, getWorkplacedistributions, getWorkplaces } from "./redux/slices/workplace";
import Router from './routes';
import routez from './routez';
import { setMiniSidenav, setSidenavHidden, useSoftUIController } from "./softcontext";
import GlobalStyles from './theme/globalStyles';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SiteDialog from './components/SiteDialog';
import ProjectDialog from './components/ProjectDialog';
import TeamMemberList from "./components/TeamMemberList";
import ContractDialog from './components/ContractDialog';
// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
LicenseInfo.setLicenseKey(gridConfig);

// ----------------------------------------------------------------------

function App() {
  console.log('here we go');
  const { isInitialized } = useAuth();
  const { user, login } = useAuth();
  const dispatchreact = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const { journey, contract, contracts, prospect, journeys, sites, site, properties } = useSelector((state) => state.site);
  const { allworkplaces, workplaces, activityprofiles, workplacedistributions, mobilityprofiles, meetings, officefeatures, goaldomains, generalareas, suactivityprofiles, suworkplacedistributions, roles } = useSelector((state) => state.workplace);
  const { company, demouse, companies, teamMembers, isLoading: isCompanyLoading } = useSelector((state) => state.company);
  const { allDeepDives } = useSelector((state) => state.survey);

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const location = useLocation();

  // User profile dialog state
  const [openUserProfileDialog, setOpenUserProfileDialog] = useState(false);
  const [userProfileData, setUserProfileData] = useState({
    firstName: '',
    role: ''
  });

  const hiddenSidenavRoutes = [
    '/login',
    '/signup',
    '/forgot-password',
    '/deepdive/:id',
    '/midpoint/:id',
    '/deepdive/:journey_id/:id',
    '/midpoint/:journey_id/:id',
    '/deepdive',
    '/midpoint',
    '/ad/:journey_id',
    '/program/:journey_id',
    '/strategy/:journey_id',
    '/program/:journey_id?token=:token',
    '/strategy/:journey_id?token=:token',
    '/ad/:journey_id?token=:token',
    '/insights/:journey_id',
    '/insights/:journey_id?token=:token',
    '/su/workplace',
    '/su/student',
  ];

  const shouldShowSidenav = !hiddenSidenavRoutes.some(route => {
    // Split route into path and query parts if it contains a question mark
    const [routePath, routeQuery] = route.split('?');

    // Create pattern for the path part
    const pathPattern = routePath
      .replace(/:[^\s/]+/g, '[^\\s/]+')
      .replace(/\//g, '\\/');

    // Create pattern for the query part if it exists
    const queryPattern = routeQuery
      ? `\\?${routeQuery.replace(/:[^\s/=&]+/g, '[^\\s/=&]+')}`
      : '';

    // Combine patterns and create regex
    const regex = new RegExp(`^${pathPattern}${queryPattern ? `(${queryPattern})?` : ''}$`);

    // Get the full URL path including query parameters
    const fullPath = location.pathname + (location.search || '');

    return regex.test(fullPath);
  });

  useEffect(() => {
    console.log('user', user);
    if (user.id && user?.companyHubspot && !company) {
      console.log('Loading company data...');
      dispatchreact(getCompany(user.companyHubspot));
    }
  }, [user, dispatchreact]);

  useEffect(() => {
    if (demouse) {
      dispatchreact(getCompany(15578996295))
    }
  }, [demouse, dispatchreact]);

  useEffect(() => {
    if (company) {
      console.log('Company loaded, fetching contracts and departments...');
      dispatchreact(getCompanyContracts(company));
      dispatchreact(getCompanySites(company));
      dispatchreact(getDepartments(company.id));

      dispatchreact(getTeamMembers(company.id));
    }
  }, [company, dispatchreact]);

  useEffect(() => {
    if (!contracts.length) return;
    contracts.filter((c) => c.city && c.address && !c.latitude && !c.longitude).forEach(contract => {
      Geocode.fromAddress(`${contract.address}, ${contract.city}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          dispatchreact(updateContract(contract.id, { latitude: lat, longitude: lng }));
        },
        (error, response) => {
          console.log('error', contract, response);
        }
      );
    });
  }, [contracts]);

  useEffect(() => {
    console.log('site useeffect', site);
    if (site?.journeys?.length) {
      dispatchreact(getJourney(site.journeys[0].id));
    }
  }, [site, dispatchreact]);

  useEffect(() => {
    if (!shouldShowSidenav) {
      console.log('setting sidenav hidden');
      setSidenavHidden(dispatch, true);
    } else {
      console.log('setting sidenav visible');
      setSidenavHidden(dispatch, false);
    }
  }, [shouldShowSidenav]);
  useEffect(() => {
    if (!allworkplaces.length) {
      dispatchreact(getAllWorkplaces());
    }
  }, [dispatchreact, allworkplaces]);
  useEffect(() => {
    if (journey?.id && company?.id) {
      console.log('getting workplaces', journey?.id, company?.id);
      dispatchreact(getWorkplaces(journey?.id, company?.id));
    }
  }, [dispatchreact, journey?.id, company?.id]);

  useEffect(() => {
    if (!generalareas.length) {
      dispatchreact(getGeneralAreas());
    }
  }, [dispatchreact, generalareas]);

  useEffect(() => {
    if (!goaldomains.length) {
      dispatchreact(getGoaldomains());
    }
  }, [dispatchreact, goaldomains]);

  useEffect(() => {
    if (!companies?.length && user?.isAdmin) {
      dispatchreact(getAllCompanies());
    }
  }, [dispatchreact, companies, user?.isAdmin]);
  useEffect(() => {
    if (!activityprofiles.length) {
      dispatchreact(getActivityprofiles());
    }
  }, [dispatchreact, activityprofiles]);
  useEffect(() => {
    if (!suactivityprofiles.length) {
      dispatchreact(getSuActivityprofiles());
    }
  }, [dispatchreact, suactivityprofiles]);
  useEffect(() => {
    if (!suworkplacedistributions.length) {
      dispatchreact(getSuWorkplacedistributions());
    }
  }, [dispatchreact, suworkplacedistributions]);

  useEffect(() => {
    if (!roles.length) {
      dispatchreact(getRoles());
    }
  }, [dispatchreact, roles]);


  useEffect(() => {
    if (!allDeepDives.length && user.id) {
      dispatchreact(getAllDeepDives());
    }
  }, [dispatchreact, allDeepDives, user.id]);
  useEffect(() => {
    if (!journeys.length && user?.companyHubspot) {
      console.log('getting journeys');
      dispatchreact(getCompanyJourneys(user.companyHubspot));
    }
  }, [dispatchreact, journeys]);

  useEffect(() => {
    if (!workplacedistributions.length) {
      dispatchreact(getWorkplacedistributions());
    }
  }, [dispatchreact, workplacedistributions]);

  useEffect(() => {
    if (!meetings.length) {
      dispatchreact(getMeetings());
    }
  }, [dispatchreact, meetings]);
  /* 
  useEffect(() => {
    if (!properties.length && user.isAdmin) {
      dispatchreact(getAllProperties());
    }
  }, [dispatchreact, properties, user.isAdmin]); */
  useEffect(() => {
    if (!officefeatures.length) {
      dispatchreact(getOfficefeatures());
    }
  }, [dispatchreact, officefeatures]);

  useEffect(() => {
    if (!mobilityprofiles.length) {
      dispatchreact(getMobilityprofiles());
    }
  }, [dispatchreact, mobilityprofiles]);
  /* useEffect(() => {
    if (mgmtSubmissions || !user.companyHubspot || !journey || !workplaces || !workplaces.length) return;
    setReadyToLoad(true);
  }, [dispatchreact, user, journey, mgmtSubmissions, workplaces]);
 */

  useEffect(() => {
    if (prospect || !user.companyHubspot || !contract.prospects?.length || !workplaces || !workplacedistributions.length || !activityprofiles.length) return;
    // setReadyToLoad(true);
    dispatchreact(getProspect(contract.prospects[0].id, workplaces, activityprofiles, workplacedistributions));
  }, [dispatchreact, user, contract.prospects, prospect, workplaces, workplacedistributions, activityprofiles]);
  /* 
    useEffect(() => {
      if (!readyToLoad || mgmtSubmissions) return;
      dispatchreact(getJourneyMgmtSubmissions(user.companyHubspot, journey.id, workplaces, journey.submission_id));
    }, [dispatchreact, readyToLoad, mgmtSubmissions]); */

  // Add this state to track company changes
  const [companyChangeTimestamp, setCompanyChangeTimestamp] = useState(null);

  // Add this effect to handle company changes and force data refresh


  // Pass this function down to DashboardNavbar
  const handleCompanyChange = () => {
    setCompanyChangeTimestamp(Date.now());
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const cableContext = useContext(CableContext);

  // console.log('channel', cableContext);
  useEffect(() => {
    // Add null check for user object
    if (!user || !user.id || !cableContext?.cable) return;

    const channel = cableContext.cable.subscriptions.create(
      {
        channel: "SurveyChannel",
        user_id: user.id
      },
      {
        connected: () => {
          console.log('connected');
        },
        received: (data) => {
          if (data === 'reload' && user.companyHubspot) {
            dispatchreact(getCompanyJourneys(user.companyHubspot));
          }
        }
      });

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [user, cableContext, dispatchreact]);

  // Add new state for first-time user
  /*   const [showFirstJourney, setShowFirstJourney] = useState(false);
   */
  // Add new effect to check for first-time users
  /* useEffect(() => {
    if (user?.id && journeys?.length === 0) {
      setShowFirstJourney(true);
    }
  }, [user, journeys]); */

  // Add these state variables with the existing ones
  const [openAdvisoryDialog, setOpenAdvisoryDialog] = useState(false);
  const [advisoryMessage, setAdvisoryMessage] = useState('');
  const [requestType, setRequestType] = useState('custom');
  // Add new state for AI chat
  const [openAiChat, setOpenAiChat] = useState(false);
  const [openActionableAiChat, setOpenActionableAiChat] = useState(false);

  // Add these handlers before the return statement
  const handleAdvisoryOpen = () => {
    setOpenAdvisoryDialog(true);
    // Close AI chat if open
    setOpenActionableAiChat(false);
  };

  const handleAdvisoryClose = () => {
    setOpenAdvisoryDialog(false);
    setAdvisoryMessage('');
  };

  const handleActionableAiChatClose = () => {
    setOpenActionableAiChat(false);
  };
  // Add new handlers for AI chat
  const handleAiChatOpen = () => {
    setOpenAiChat(true);
    // Close advisory dialog if open
    setOpenAdvisoryDialog(false);
  };


  const handleAiChatClose = () => {
    setOpenAiChat(false);
  };
  const handleActionableAiChatOpen = () => {
    setOpenActionableAiChat(true);
    // Close advisory dialog if open
    setOpenAdvisoryDialog(false);
  };

  const handleGeocode = () => {
    console.log('geocoding properties');
    properties.filter((p) => p.isWp).filter(property => (!property.latitude || !property.longitude)).slice(0, 100).forEach(property => {
      Geocode.fromAddress(`${property.address}, ${property.kommun}, ${property.country}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          dispatchreact(updateProperty(property.id, { latitude: lat, longitude: lng }));
        },
      );
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const advisoryPackages = [
    {
      id: 'payg',
      title: 'Pay As You Go',
      description: 'Purchase adviosy hours as you need them',
      isCustom: true
    },
    {
      id: 'starter',
      title: 'Survey Insights Workshop',
      hours: 5,
      price: '€1.000',
      description: 'Basic advisory support for getting started'
    },
    {
      id: 'professional',
      title: 'Goal Setting & Work Model Workshop',
      hours: 10,
      price: '€2.000',
      description: 'Comprehensive support for medium-sized projects'
    },
    {
      id: 'enterprise',
      title: 'Work settings & Support areas',
      hours: 20,
      price: '€4.000',
      description: 'Full-scale advisory support for complex implementations'
    }
  ];

  const handleAdvisorySubmit = () => {
    const requestData = {
      type: requestType,
      message: requestType === 'custom' ? advisoryMessage : null,
      package: requestType !== 'custom' && requestType !== 'payg'
        ? advisoryPackages.find(pkg => pkg.id === requestType)
        : null,
      customHours: requestType === 'payg' ? customHours : null,
      totalAmount: requestType === 'payg' ? customHours * HOURLY_RATE : null
    };

    console.log('Advisory request:', requestData);
    handleAdvisoryClose();
    setRequestType('custom');
    setCustomHours(5); // Reset hours
  };

  // Add new state variables
  const [customHours, setCustomHours] = useState(5);
  const HOURLY_RATE = 180; // €180 per hour

  // Check if user profile is incomplete and show dialog
  useEffect(() => {
    if (user.id && ((!user.firstName && !user.customName) || !user.role)) {
      setOpenUserProfileDialog(true);
      setUserProfileData({
        firstName: user.firstName || user.customName || '',
        role: user.role || ''
      });
    }
  }, [user]);

  // Handle user profile dialog close
  const handleUserProfileClose = () => {
    setOpenUserProfileDialog(false);
  };

  // Handle user profile form input changes
  const handleUserProfileChange = (event) => {
    const { name, value } = event.target;
    setUserProfileData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle user profile form submission
  const handleUserProfileSubmit = async () => {
    if (userProfileData.firstName.trim() === '') {
      return; // Don't submit if firstName is empty
    }

    try {
      // Update user profile using the updateUser function
      const url = `${process.env.REACT_APP_BACKEND_URL}/companies/updateuser`;
      const response = await axios.put(url, {
        user_id: user.id,
        first_name: userProfileData.firstName,
        role: userProfileData.role
      });

      console.log('User profile updated:', response.data);

      // Close the dialog
      setOpenUserProfileDialog(false);

      // Refresh user session to get updated user data
      await login();
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  // Add a loading indicator for company data
  const renderContent = () => {
    console.log('user', user);
    if (!isInitialized) {
      return <LoadingScreen />;
    }

    if (user.id && user?.companyHubspot && isCompanyLoading && !company) {
      return <LoadingScreen message="Loading company data..." />;
    }

    if (!activityprofiles.length || !allworkplaces.length || !workplacedistributions.length) {
      return <LoadingScreen message="Loading fundamental data..." />;
    }

    return (
      <>
        {(user.id || demouse) && shouldShowSidenav && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandwidth={brandwidth}
              brandName="Workplacer.ai"
              routes={!journey ? routez.slice(0, 3) : journey?.path === 'plus' ? routez : routez.slice(0, 8)}
              company_id={user.companyHubspot}
              company_name={company?.name}
              teamMembers={teamMembers}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {/*    <JourneyWrapper> */}
        <Router
          onCompanyChange={handleCompanyChange}
        /* showFirstJourney={showFirstJourney} */
        />

        {/* </JourneyWrapper> */}
        {(user.id || demouse) && !shouldShowSidenav && (
          <Fab
            color="primary"
            variant="extended"
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 24,
              zIndex: 1000
            }}
            onClick={() => {
              window.open('https://workplacer.ai', '_blank');
            }}
          >
            <Favorite sx={{ mr: 1 }} />
            Made with Workplacer.ai
          </Fab>
        )}
        {(user.id || demouse) && shouldShowSidenav && (
          <>
            {/* SpeedDial - rightmost */}
            <SpeedDial
              ariaLabel="Create new items"
              sx={{
                position: 'fixed',
                bottom: 24,
                right: 24,
                zIndex: 1000,
                '& .MuiFab-root': {
                  bgcolor: 'primary.focus',
                  '&:hover': {
                    bgcolor: 'primary.focus'
                  }
                },
                '& .MuiSpeedDialAction-staticTooltipLabel': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  color: 'white'
                },
                '& .MuiSpeedDialAction-fab': {
                  bgcolor: 'white.focus',
                  '&:hover': {
                    bgcolor: 'white.focus'
                  }
                }
              }}
              icon={<SpeedDialIcon sx={{ color: 'white' }} />}
              onOpen={handleSpeedDialOpen}
              onClose={handleSpeedDialClose}
              open={openSpeedDial}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  FabProps={{
                    sx: {
                      bgcolor: 'white.focus',
                      '&:hover': {
                        bgcolor: 'white.focus'
                      }
                    }
                  }}
                  icon={React.cloneElement(action.icon, { sx: { color: 'white' } })}
                  tooltipTitle={action.name}
                  onClick={() => handleSpeedDialAction(action.name)}
                />
              ))}
            </SpeedDial>

            {/* Request Advisory */}
            <Fab
              variant="extended"
              sx={{
                position: 'fixed',
                bottom: 24,
                right: 90,
                zIndex: 1000
              }}
              onClick={handleAdvisoryOpen}
            >
              <img src={brand} alt="Advisory" style={{ width: 24, height: 24, marginRight: 4 }} />
              Advisory
            </Fab>

            {/* Advisory AI */}
          {/*   {journey.path === 'plus' && (
              <Fab
                color="success"
                variant="extended"
                sx={{
                  position: 'fixed',
                  bottom: 24,
                  right: 280,
                  zIndex: 1000
                }}
                onClick={handleAiChatOpen}
              >
                <SmartToyIcon sx={{ mr: 0.5 }} />
                Advisory AI
              </Fab>
            )} */}

            {/* AI Assistant */}
            <Fab
              color="secondary"
              variant="extended"
              sx={{
                position: 'fixed',
                bottom: 24,
                right: journey.path === 'plus' ? 230 : 230,
                zIndex: 1000
              }}
              onClick={handleAiChatOpen}
            >
              <img src={brand} alt="Project AI" style={{ width: 24, height: 24, marginRight: 4 }} />
              Project AI
            </Fab>

            {/* Geocode button - only show if needed */}
            {user.isAdmin && properties.filter((p) => p.isWp).filter(property => (!property.latitude || !property.longitude)).length > 0 && (
              <Fab
                color="primary"
                variant="extended"
                sx={{
                  position: 'fixed',
                  bottom: 24,
                  right: journey.path === 'plus' ? 560 : 420,
                  zIndex: 1000
                }}
                onClick={handleGeocode}
              >
                <SupportIcon sx={{ mr: 1 }} />
                Geocode
              </Fab>
            )}

            {/* Rest of the existing components */}
            {shouldShowSidenav && openAiChat && (
              <AiAgentChat
                open={openAiChat}
                onClose={handleAiChatClose}
              />
            )}
            {shouldShowSidenav && openActionableAiChat && (
              <AiAgentChat
                open={openActionableAiChat}
                onClose={handleActionableAiChatClose}
              />
            )}
            <Dialog
              open={openAdvisoryDialog}
              onClose={handleAdvisoryClose}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>
                <SoftTypography variant="h4" fontWeight="medium">
                  Request Advisory Support
                </SoftTypography>
              </DialogTitle>
              <DialogContent>
                <SoftBox p={2}>
                  <SoftTypography variant="h6" fontWeight="regular" mb={2}>
                    Choose a package or make a custom request
                  </SoftTypography>

                  <Stack spacing={2} mb={3}>
                    {advisoryPackages.map((pkg) => (
                      <SoftBox
                        key={pkg.id}
                        p={2}
                        border={1}
                        borderRadius="lg"
                        borderColor={requestType === pkg.id ? 'primary.main' : 'grey.300'}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { borderColor: 'primary.main' },
                          transition: 'all 0.2s'
                        }}
                        onClick={() => setRequestType(pkg.id)}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Stack spacing={1}>
                            <SoftTypography variant="h6" fontWeight="medium">
                              {pkg.title}
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text">
                              {pkg.description}
                            </SoftTypography>
                          </Stack>
                          {!pkg.isCustom && (
                            <Stack alignItems="flex-end">
                              <SoftTypography variant="h6" color="primary">
                                {pkg.price}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                {pkg.hours} hours
                              </SoftTypography>
                            </Stack>
                          )}
                        </Stack>

                        {pkg.isCustom && requestType === 'payg' && (
                          <SoftBox mt={3}>
                            <Stack spacing={2}>
                              <Stack direction="row" justifyContent="space-between">
                                <SoftTypography variant="body2" color="text">
                                  Select Hours
                                </SoftTypography>
                                <SoftTypography variant="h6" color="primary">
                                  {formatCurrency(customHours * HOURLY_RATE)}
                                </SoftTypography>
                              </Stack>
                              <Slider
                                value={customHours}
                                onChange={(e, newValue) => setCustomHours(newValue)}
                                min={2}
                                max={40}
                                step={1}
                                marks={[
                                  { value: 2, label: '2h' },
                                  { value: 20, label: '20h' },
                                  { value: 40, label: '40h' },
                                ]}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value}h`}
                              />
                              <Stack direction="row" justifyContent="space-between">
                                <SoftTypography variant="caption" color="text">
                                  Rate: {formatCurrency(HOURLY_RATE)}/hour
                                </SoftTypography>
                                <SoftTypography variant="caption" color="text">
                                  Total: {customHours} hours
                                </SoftTypography>
                              </Stack>
                            </Stack>
                          </SoftBox>
                        )}
                      </SoftBox>
                    ))}

                    <SoftBox
                      p={2}
                      border={1}
                      borderRadius="lg"
                      borderColor={requestType === 'custom' ? 'primary.main' : 'grey.300'}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { borderColor: 'primary.main' },
                        transition: 'all 0.2s'
                      }}
                      onClick={() => setRequestType('custom')}
                    >
                      <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                        Custom Request
                      </SoftTypography>
                      {requestType === 'custom' && (
                        <TextField
                          autoFocus
                          margin="dense"
                          label="How can we help you?"
                          type="text"
                          fullWidth
                          multiline
                          rows={4}
                          value={advisoryMessage}
                          onChange={(e) => setAdvisoryMessage(e.target.value)}
                        />
                      )}
                    </SoftBox>
                  </Stack>
                </SoftBox>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <SoftButton onClick={handleAdvisoryClose} variant="text" color="dark">
                  Cancel
                </SoftButton>
                <SoftButton
                  onClick={handleAdvisorySubmit}
                  variant="gradient"
                  color="primary"
                  disabled={requestType === 'custom' && !advisoryMessage.trim()}
                >
                  Submit Request
                </SoftButton>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  };

  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const handleSpeedDialOpen = () => {
    setOpenSpeedDial(true);
  };

  const handleSpeedDialClose = () => {
    setOpenSpeedDial(false);
  };

  const actions = [
    { icon: <BusinessIcon />, name: 'Add Site' },
    { icon: <RocketLaunch />, name: 'Start Project' },
    { icon: <DescriptionIcon />, name: 'Add Contract' },
    { icon: <PersonAddIcon />, name: 'Invite Team Member' },/* 
    { icon: <GroupAddIcon />, name: 'New Department' }, */
  ];

  const [openSiteDialog, setOpenSiteDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [newlyCreatedSite, setNewlyCreatedSite] = useState(null);

  const handleCreateSite = async (siteData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sites`, siteData);
      const newSite = response.data;
      
      // Refresh sites list
      if (company) {
        dispatchreact(getCompanySites(company));
      }
      
      setOpenSiteDialog(false);
      setNewlyCreatedSite(newSite);
      
      // Ask if user wants to create a project for this site
      if (window.confirm('Site created successfully! Would you like to create a project for this site?')) {
        setOpenProjectDialog(true);
      }
    } catch (error) {
      console.error('Error creating site:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleCreateProject = async (projectData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/projects`, projectData);
      
      // Refresh journeys list
      if (user?.companyHubspot) {
        dispatchreact(getCompanyJourneys(user.companyHubspot));
      }
      
      setOpenProjectDialog(false);
      setNewlyCreatedSite(null);
    } catch (error) {
      console.error('Error creating project:', error);
      // You might want to show an error message to the user here
    }
  };

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [isInviting, setIsInviting] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [inviteError, setInviteError] = useState('');

  // Add handleInvite function
  const handleInvite = async () => {
    if (!emailInput) return;

    setIsInviting(true);
    setInviteError('');

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/invitations/invite`, {
        email: emailInput,
        company_id: company.id,
        company_name: company.name,
        sender_email: user.email,
        message: 'You have been invited to join the team.',
        variant: 'team_member'
      });

      setInviteSuccess(true);
      setEmailInput('');
      setTimeout(() => setInviteSuccess(false), 3000);
      
      // Refresh team members list
      if (company) {
        dispatchreact(getTeamMembers(company.id));
      }
    } catch (error) {
      setInviteError(error.response?.data?.message || 'Failed to send invitation');
    } finally {
      setIsInviting(false);
    }
  };

  // Add handleRevokeInvitation function
  const handleRevokeInvitation = async (invitationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/invitations/${invitationId}`);
      // Refresh team members list
      if (company) {
        dispatchreact(getTeamMembers(company.id));
      }
    } catch (error) {
      console.error('Failed to revoke invitation:', error);
    }
  };

  // Add handleRemoveTeamMember function
  const handleRemoveTeamMember = async (memberId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/invitations/removeuser/${memberId}`);
      // Refresh team members list
      if (company) {
        dispatchreact(getTeamMembers(company.id));
      }
    } catch (error) {
      console.error('Failed to remove team member:', error);
    }
  };

  // Add new state for contract dialog
  const [openContractDialog, setOpenContractDialog] = useState(false);

  // Add handleCreateContract function
  const handleCreateContract = async (contractData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contracts`, contractData);
      
      // Refresh contracts list
      if (company) {
        dispatchreact(getCompanyContracts(company));
      }
      
      setOpenContractDialog(false);
    } catch (error) {
      console.error('Error creating contract:', error);
    }
  };

  // Update handleSpeedDialAction
  const handleSpeedDialAction = (action) => {
    handleSpeedDialClose();
    
    switch (action) {
      case 'Add Site':
        setOpenSiteDialog(true);
        break;
      case 'Start Project':
        setOpenProjectDialog(true);
        break;
      case 'Add Contract':
        setOpenContractDialog(true);
        break;
      case 'Invite Team Member':
        setShowInviteDialog(true);
        break;
      case 'New Department':
        // Implement department creation
        console.log('Create new department');
        break;
      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CableProvider>
          <PasswordChangeWrapper>
            <>
              {/*  <TestError /> */}

              <CssBaseline />
              <GlobalStyles />
              <ProgressBarStyle />
              <ScrollToTop />
              <SnackbarProvider />
              <NoInternetConnection>
                <IntroWrapper />
                {renderContent()}
              </NoInternetConnection>

              {/* User Profile Dialog */}
              <Dialog
                open={openUserProfileDialog}
                onClose={handleUserProfileClose}
                maxWidth="sm"
                fullWidth
              >
                <DialogContent>
                  <SoftBox p={2}>
                    <SoftBox display="flex" flexDirection="column" alignItems="center" mb={1}>
                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="80px"
                        height="80px"
                        borderRadius="50%"
                        color="primary"
                        mb={2}
                      >
                        <Favorite fontSize="large" />
                      </SoftBox>
                      <SoftTypography variant="h4" fontWeight="medium" textAlign="center">
                        Tell us a little bit more about yourself
                      </SoftTypography>
                      <SoftTypography variant="body2" fontWeight="regular" color="text" textAlign="center" mt={1}>
                        Please provide some additional information to complete your profile
                      </SoftTypography>
                    </SoftBox>

                    <Stack spacing={3}>
                      {/* First Name - Editable */}
                      <SoftBox mb={1}>
                        <SoftInput
                          placeholder="First Name"
                          name="firstName"
                          value={userProfileData.firstName}
                          onChange={handleUserProfileChange}
                          required
                          icon={{
                            component: 'person',
                            direction: "left"
                          }}
                        />
                      </SoftBox>

                      {/* Role - Editable */}
                      <SoftBox mb={1}>
                        <SoftInput
                          placeholder="Your Role (e.g. Facility Manager, HR Director)"
                          name="role"
                          value={userProfileData.role}
                          onChange={handleUserProfileChange}
                          icon={{
                            component: 'work',
                            direction: "left"
                          }}
                        />
                      </SoftBox>

                      {/* Email - Locked */}
                      <SoftBox mb={1}>
                        <SoftInput
                          placeholder="Email"
                          value={user?.email || ''}
                          disabled
                          icon={{
                            component: 'email',
                            direction: "left"
                          }}
                        />
                      </SoftBox>

                      {/* Company - Locked */}
                      <SoftBox mb={1}>
                        <SoftInput
                          placeholder="Company"
                          value={user?.companyName || ''}
                          disabled
                          icon={{
                            component: 'business',
                            direction: "left"
                          }}
                        />
                      </SoftBox>
                    </Stack>

                    <SoftBox mt={4} display="flex" justifyContent="center">
                      <SoftButton
                        variant="gradient"
                        color="success"
                        onClick={handleUserProfileSubmit}
                        disabled={!userProfileData.firstName.trim()}
                        fullWidth
                      >
                        Save Profile
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </DialogContent>
              </Dialog>

              {/* Add SiteDialog */}
              <SiteDialog
                open={openSiteDialog}
                onClose={() => setOpenSiteDialog(false)}
                onSubmit={handleCreateSite}
                companyId={company?.id}
              />
              
              {/* Add ProjectDialog */}
              <ProjectDialog
                open={openProjectDialog}
                onClose={() => setOpenProjectDialog(false)}
                onSubmit={handleCreateProject}
                companyId={company?.id}
                sites={sites}
                initialSite={newlyCreatedSite}
              />

              {/* Add Team Member Invite Dialog */}
              <Dialog
                open={showInviteDialog}
                onClose={() => setShowInviteDialog(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>
                  <SoftBox px={2} pt={2}>
                    <SoftTypography variant="h4" fontWeight="medium">
                      Invite Team Members
                    </SoftTypography>
                  </SoftBox>
                </DialogTitle>
                <DialogContent>
                  <Stack spacing={2}>
                    <SoftBox>
                      <SoftInput
                        placeholder="Enter team member's email"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        error={!!inviteError}
                        type="email"
                        icon={{
                          component: "email",
                          direction: "left"
                        }}
                      />
                      {inviteError && (
                        <SoftTypography variant="caption" color="error" mt={1}>
                          {inviteError}
                        </SoftTypography>
                      )}
                    </SoftBox>

                    <SoftButton
                      variant="gradient"
                      color="primary"
                      onClick={handleInvite}
                      disabled={isInviting || !emailInput}
                      fullWidth
                    >
                      {isInviting ? 'Sending Invitation...' : 'Send Invitation'}
                    </SoftButton>

                    {inviteSuccess && (
                      <SoftBox
                        bgcolor="success.light"
                        p={2}
                        borderRadius={2}
                        display="flex"
                        alignItems="center"
                        gap={1}
                      >
                        <CheckCircleIcon sx={{ color: 'success.main' }} />
                        <SoftTypography variant="button" color="success">
                          Invitation sent successfully!
                        </SoftTypography>
                      </SoftBox>
                    )}

                    <TeamMemberList
                      teamMembers={teamMembers}
                      company={company}
                      onMemberRemove={handleRemoveTeamMember}
                      onInvitationRevoke={handleRevokeInvitation}
                    />
                  </Stack>
                </DialogContent>
              </Dialog>

              {/* Add ContractDialog */}
              <ContractDialog
                open={openContractDialog}
                onClose={() => setOpenContractDialog(false)}
                onSubmit={handleCreateContract}
                companyId={company?.id}
                sites={sites}
              />
            </>
          </PasswordChangeWrapper>
        </CableProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
