import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Typography, IconButton, Icon, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuPopover from '../../components/MenuPopover';
import MyAvatar from '../../components/MyAvatar';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import SoftTypography from '../../components/SoftTypography';
import CompanyAvatar from '../../components/CompanyAvatar';
import SoftButton from '../../components/SoftButton';
import BusinessContext from '../../pages/dashboard/intro/BusinessContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover({ company, journey }) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [openBusinessContext, setOpenBusinessContext] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenBusinessContext = () => {
    setOpenBusinessContext(true);
    handleClose();
  };

  const handleCloseBusinessContext = () => {
    setOpenBusinessContext(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha('#fff', 0.72)
            }
          })
        }}
      >
        <CompanyAvatar company={company} />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 350 }}>
        {user.internalrole === "Admin" && (
          <>
            <Stack direction="row" spacing={1} sx={{ alignContent: 'center', alignItems: 'center', my: 1.5, px: 2.5 }}>
              <Icon
                sx={{
                  padding: 0,
                  width: 44,
                  height: 44,
                  borderRadius: '50%',
                }}
              >
                <CompanyAvatar company={company} />
              </Icon>
              <Box sx={{ my: 0, pr: 2.5 }}>
                <Typography variant="subtitle1" noWrap>
                  {company?.name}
                </Typography>
              </Box>
            </Stack>

            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Link to="/profile/company" onClick={handleClose}>
                <SoftTypography variant="h5" fontWeight="medium" sx={{ my: 2 }}>
                  Company Profile
                </SoftTypography>
              </Link>
              {company && !company.mission && (
                <SoftButton
                  variant="contained"
                  color="warning"
                  onClick={handleOpenBusinessContext}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <Icon>warning</Icon>&nbsp;
                  Complete Business Profile
                </SoftButton>
              )}
            </Box>
          </>
        )}
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1} sx={{ alignContent: 'center', alignItems: 'center', my: 1.5, px: 2.5 }}>
          <Icon
            sx={{
              padding: 0,
              width: 44,
              height: 44,
              borderRadius: '50%',
            }}
          >
            <MyAvatar />
          </Icon>
          <Box sx={{ my: 0, pr: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user.email}
            </Typography>
          </Box>
        </Stack>

        <Box sx={{ my: 1.5, px: 2.5 }}>
          {user?.isAdmin && (
            <>
              <Link to="/admin" onClick={handleClose}>
                <SoftTypography variant="h5" fontWeight="medium" sx={{ my: 2 }}>
                  Admin
                </SoftTypography>
              </Link>
              <Divider />
            </>
          )}
          <Link to="/profile/overview" onClick={handleClose}>
            <SoftTypography variant="h5" fontWeight="medium" sx={{ my: 2 }}>
              Profile
            </SoftTypography>
          </Link>
        </Box>
        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <SoftButton fullWidth color="error" variant="outlined" onClick={handleLogout}>
            Logout
          </SoftButton>
        </Box>
      </MenuPopover>

      <Dialog
        open={openBusinessContext}
        onClose={handleCloseBusinessContext}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <BusinessContext />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBusinessContext} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
