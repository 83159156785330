/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useCallback } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Card, Typography, Box, Button, IconButton, Stack, Icon, Dialog, DialogTitle, DialogContent, Popover } from "@mui/material";
import { Add, AddCircle, Close, Help, QuestionMark, Email } from "@mui/icons-material";
import { keyframes } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/Auth0Context';

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";
import SidenavCard from "./SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
import { sidenavInviteDialog } from './styles/sidenav';

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "../../softcontext";
import SidenavMain from "./SidenavMain";
import { useSelector } from "react-redux";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import H3w from "../../components/_dashboard/H3w";
import useAuth from "../../hooks/useAuth";
import TeamMemberList from "../../components/TeamMemberList";

// Add this keyframe animation
const waveAnimation = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

function Sidenav({ color, brandwidth, brand, brandName, routes, company_id, company_name, teamMembers, ...rest }) {
  const { demouse, company } = useSelector((state) => state.company);
  const [openCollapse, setOpenCollapse] = useState(false);
  const { user, authcheck } = useContext(AuthContext);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const theme = useTheme();
  const styles = sidenavInviteDialog(theme);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [isInviting, setIsInviting] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [inviteError, setInviteError] = useState('');
  const [teamMembersNew, setTeamMembersNew] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRoute, setActiveRoute] = useState(null);
  const [expandTimeout, setExpandTimeout] = useState(null);
  const [showPopover, setShowPopover] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (miniSidenav) {
      // Clear any existing timeout
      if (expandTimeout) {
        clearTimeout(expandTimeout);
      }
      // Set new timeout for 300ms delay
      const timeout = setTimeout(() => {
        setMiniSidenav(dispatch, false);
      }, 300);
      setExpandTimeout(timeout);
    }
  }, [miniSidenav, dispatch, expandTimeout]);

  const handleMouseLeave = useCallback(() => {
    // Clear any pending expansion
    if (expandTimeout) {
      clearTimeout(expandTimeout);
      setExpandTimeout(null);
    }
    if (!miniSidenav) {
      setMiniSidenav(dispatch, true);
    }
  }, [miniSidenav, dispatch, expandTimeout]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (expandTimeout) {
        clearTimeout(expandTimeout);
      }
    };
  }, [expandTimeout]);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    console.log('Current teamMembers:', teamMembers);
    console.log('Current company:', company);

    // Get pending invitations
    const pendingInvitations = company?.invitations?.filter(inv => 
      inv.variant === "team_member" && 
      inv.used_at === null
    ) || [];

    // Transform invitations to match team member structure
    const transformedInvitations = pendingInvitations.map(inv => ({
      email: inv.email,
      email_verified: false,
      name: inv.email.split('@')[0], // Use email username as name
      user_metadata: {
        company_id: inv.company_id,
        role: "team_member",
        isAdmin: false,
        company_verified: false,
        hasCompletedSurvey: false,
        hasCompletedIntro: false
      },
      invitation_id: inv.id // Store the invitation ID for revocation
    }));

    // Combine team members and pending invitations
    const combinedMembers = [
      ...teamMembers,
      ...transformedInvitations
    ];

    setTeamMembersNew(combinedMembers);
  }, [teamMembers, company]);

  // Add function to handle invitation revocation
  const handleRevokeInvitation = async (invitationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/invitations/${invitationId}`);
      // Update the teamMembersNew state to remove the revoked invitation
      setTeamMembersNew(prev => prev.filter(member => member.invitation_id !== invitationId));
    } catch (error) {
      console.error('Failed to revoke invitation:', error);
    }
  };

  // Modify the team member list rendering to handle both types
  const renderTeamMember = (member) => (
    <Box
      key={member.invitation_id || member.user_id}
      sx={styles.teamMemberItem}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Email sx={{ color: member.email_verified ? 'success.main' : 'text.secondary' }} />
        <Stack>
          {member.name && (
            <SoftTypography variant="caption" fontWeight="medium">
              {member.name}
            </SoftTypography>
          )}
          <SoftTypography
            variant="button"
            fontWeight="regular"
            color={member.email_verified ? 'text.primary' : 'text.secondary'}
          >
            {member.email}
            {!member.email_verified && ' (Pending)'}
          </SoftTypography>
        </Stack>
      </Stack>
      {member.invitation_id ? (
        <SoftButton
          variant="text"
          color="error"
          onClick={() => handleRevokeInvitation(member.invitation_id)}
          size="small"
        >
          Revoke
        </SoftButton>
      ) : (
        <SoftButton
          variant="text"
          color="error"
          onClick={() => handleRemoveTeamMember(member.user_id)}
          size="small"
        >
          Remove
        </SoftButton>
      )}
    </Box>
  );

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, true);/* window.innerWidth < 1200 */
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Add useEffect to check if this is the first visit
  useEffect(() => {
    const hasSeenNavTour = localStorage.getItem('hasSeenNavTour');
    /* if (!hasSeenNavTour) {
      setRunTour(true);
      localStorage.setItem('hasSeenNavTour', 'true');
    } */
  }, []);

  const isDemoMode = location.pathname.startsWith('/demo');
  const prefixDemoPath = (path) => {
    return isDemoMode && path ? `/demo${path}` : path;
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={prefixDemoPath(href)}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={prefixDemoPath(route)} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={prefixDemoPath(route) === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={prefixDemoPath(href)}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={prefixDemoPath(route)} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Generate tour steps from routes
  const generateSteps = () => {
    const steps = [
      {
        target: '.sidenav-logo',
        content: 'Welcome to Workplacer! This is your navigation menu that will guide you through the workplace transformation journey.',
        disableBeacon: true,
      }
    ];

    routes.forEach((route) => {
      if (route.type === "title") {
        steps.push({
          target: `.nav-section-${route.key}`,
          content: `${route.title} section: Here you'll find tools and features related to ${route.title.toLowerCase()}.`,
        });
      } else if (route.type === "direct") {
        steps.push({
          target: `.nav-item-${route.key}`,
          content: getRouteDescription(route),
        });
      }
    });

    return steps;
  };

  // Helper function to generate meaningful descriptions for each route
  const getRouteDescription = (route) => {
    const descriptions = {
      portfoliooverview: "Get a bird's eye view of your entire workplace portfolio.",
      "portfolioworkplace-strategy": "Define and manage your workplace strategy across all locations.",
      prospectprospect: "Start your workplace transformation journey by creating a new prospect.",
      surveysurvey: "Gather insights from your employees through customizable surveys.",
      journeyunderstand: "Analyze and understand your current workplace situation.",
      journeystrategize: "Develop strategies based on collected data and insights.",
      journeyprogram: "Create detailed programs for your workplace transformation.",
      journeysolution: "Explore and compare different workplace solutions.",
      journeymarketplacer: "Connect with marketplace providers for implementation.",
      // Add descriptions for other routes as needed
    };

    return descriptions[route.key] || `${route.name}: Navigate to ${route.name.toLowerCase()} section.`;
  };


  const handleInvite = async () => {
    if (!emailInput) return;

    setIsInviting(true);
    setInviteError('');

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/invitations/invite`, {
        email: emailInput,
        company_id: company_id,
        company_name: company_name,
        sender_email: user.email,
        message: 'You have been invited to join the team.',
        variant: 'team_member'
      });

      setInviteSuccess(true);
      setEmailInput('');
      setTimeout(() => setInviteSuccess(false), 3000);
      setTeamMembersNew(prev => [...prev, {
        email: emailInput,
        variant: 'team_member'
      }]);
      console.log('teamMembersNew', teamMembersNew);
    } catch (error) {
      setInviteError(error.response?.data?.message || 'Failed to send invitation');
    } finally {
      setIsInviting(false);
    }
  };

  const handleRemoveTeamMember = async (memberId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/invitations/removeuser/${memberId}`);
      setTeamMembersNew(prev => prev.filter(member => member.id !== memberId));
    } catch (error) {
      console.error('Failed to remove team member:', error);
    }
  };
  
  const handlePopoverOpen = (event, route) => {
    if (route.description || route.image) {
      setAnchorEl(event.currentTarget);
      setActiveRoute(route);
      setTimeout(() => {
        setShowPopover(true);
      }, 1200);
    }
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
    setAnchorEl(null);
    setActiveRoute(null);
  };

  const open = Boolean(anchorEl) && showPopover;

  const handleHowItWorks = async () => {
    try {
      // Update user profile with hasCompletedIntro flag
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/companies/updateuser`, {
        user_id: user.id,
        hasCompletedIntro: false,
        firstName: user.firstName,
        role: user.role
      });

      if (!response.data.success) {
        console.error('Failed to update user profile:', response.data.error);
        return;
      }
      
      // Force a token refresh and context update
      const auth0Client = window.auth0Client;
      if (auth0Client) {
        try {
          await auth0Client.getTokenSilently({ cacheMode: 'off' });
          await authcheck();
        } catch (error) {
          console.error('Failed to refresh auth session:', error);
        }
      }
    } catch (error) {
      console.error('Failed to reset intro status:', error.response?.data || error);
    }
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, completed, name, icon, title, collapse, noCollapse, key, href, route, isSwitch, isFuture, isNotForDemo, image, description }) => {
      let returnValue;
      if (demouse && isNotForDemo) return null;
      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Box
              key={key}
              onMouseEnter={(e) => handlePopoverOpen(e, { name, image, description })}
              onMouseLeave={handlePopoverClose}
            >
              <NavLink to={prefixDemoPath(href)}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  open={openCollapse === key}
                  isSwitch={isSwitch}
                  isFuture={isFuture}
                  onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            </Box>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={prefixDemoPath(route)} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
                isSwitch={isSwitch}
                isFuture={isFuture}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              isSwitch={isSwitch}
              isFuture={isFuture}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "direct") {
        if (href) {
          if (isFuture) {
            returnValue = (
              <Box
                key={key}
                onMouseEnter={(e) => handlePopoverOpen(e, { name, image, description })}
                onMouseLeave={handlePopoverClose}
              >
                <SidenavMain
                  name={name}
                  icon={icon}
                  active={key === `${collapseName}${itemName}`}
                  noCollapse={noCollapse}
                  isSwitch={isSwitch}
                  isFuture={isFuture}
                  completed={completed}
                />
              </Box>
            );
          } else {
            returnValue = (
              <Box
                key={key}
                onMouseEnter={(e) => handlePopoverOpen(e, { name, image, description })}
                onMouseLeave={handlePopoverClose}
              >
                <NavLink to={prefixDemoPath(href)}>
                  <SidenavMain
                    name={name}
                    icon={icon}
                    active={key === "home"? collapseName === "" : key === `${collapseName}${itemName}`}
                    noCollapse={noCollapse}
                    isSwitch={isSwitch}
                    isFuture={isFuture}
                    completed={completed}
                  />
                </NavLink>
              </Box>
            );
          }
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={prefixDemoPath(route)} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title" && !miniSidenav) {
        returnValue = (
          <SoftTypography
            key={key}
            className={`nav-section-${key}`}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            color="white"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );

  // Modify the CustomTooltip component to position on the right
  const CustomTooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    size,
    isLastStep,
  }) => (
    <Card
      elevation={4}
      sx={{
        position: 'fixed',
        top: 75,
        left: '280px',
        width: 'auto',
        maxWidth: '400px',
        minWidth: '320px',
        p: 5,
        pt: 8,
        backgroundColor: 'white',
        borderRadius: '12px',
        zIndex: 10001,
        transition: 'all 0.3s ease',
        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        overflow: 'visible',
        ml: 4,
        transform: 'none',
      }}
    >
      {skipProps && (
        <SoftButton
          {...skipProps}
          variant="text"
          size="small"
          color="primary"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            minWidth: 'auto',
          }}
        >
          <Close />
        </SoftButton>
      )}

      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          color: 'text.secondary',
        }}
      >
        {`${index + 1}/${size}`}
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          top: -60,
          left: 30,
          width: 90,
          height: 90,
          backgroundImage: 'url(/static/new/help.webp)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: 10002,
          transformOrigin: 'bottom center',
          animation: (index === 0 || isLastStep) ? `${waveAnimation} 1.5s ease-in-out` : 'none',
        }}
      />

      <Typography
        variant="body1"
        sx={{
          mb: 4,
          lineHeight: 1.6,
          color: 'text.primary',
          fontSize: '1rem',
        }}
      >
        {step.content}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {index > 0 && (
          <SoftButton {...backProps} variant="outlined" color="primary">
            Back
          </SoftButton>
        )}
        <SoftButton {...primaryProps} variant="gradient" color="primary" sx={{ ml: 'auto' }}>
          {isLastStep ? 'Done' : 'Next'}
        </SoftButton>
      </Box>
    </Card>
  );

  return (
    <>

      <Popover
        sx={{
          pointerEvents: 'none',
          '& .MuiPopover-paper': {
            marginLeft: '20px',
            maxWidth: '280px',
            width: '100%'
          }
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{
          paper: {
            elevation: 0
          }
        }}
      >
        {activeRoute && (
          <Card
            sx={{
              overflow: 'hidden',
              boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
              borderRadius: '12px',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 12px 48px rgba(0,0,0,0.15)',
              },
            }}
          >
            {activeRoute.image && (
              <Box
                sx={{
                  width: '100%',
                  height: 140,
                  backgroundImage: `url(${activeRoute.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            )}
            <Box sx={{ p: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'primary.main',
                }}
              >
                {activeRoute.name}
              </Typography>
              {activeRoute.description && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    lineHeight: 1.6,
                  }}
                >
                  {activeRoute.description}
                </Typography>
              )}
            </Box>
          </Card>
        )}
      </Popover>

      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, miniSidenav }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SoftBox pt={3} pb={0} px={4} textAlign="center" className="sidenav-logo">

          <SoftBox component={NavLink} to={prefixDemoPath("/")} display="flex" alignItems="center">
            {miniSidenav ? (
              <SoftBox component="img" src={brand} alt="Workplacer.ai Logo" width="2rem" />
            ) : (
              <SoftBox component="img" src={brandwidth} alt="Workplacer.ai Logo" width="120%" />
            )}
          </SoftBox>
        </SoftBox>
        <Divider />
        <List>{renderRoutes}</List>

        <SoftBox p={2} mt="auto">
          {miniSidenav ? (
            <Box display="flex" justifyContent="center">
              <IconButton
                onClick={handleHowItWorks}
                sx={{ color: 'primary.main' }}
              >
                <Help />
              </IconButton>
              <IconButton
                onClick={() => setShowInviteDialog(true)}
                sx={{ color: 'primary.main' }}
              >
                <AddCircle />
              </IconButton>
            </Box>
          ) : (
            <Stack spacing={1}>
              <SoftButton
                variant="gradient"
                color="secondary"
                fullWidth
                onClick={() => setShowInviteDialog(true)}
                startIcon={<Add />}
              >
                Invite team
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="primary"
                fullWidth
                onClick={handleHowItWorks}
                startIcon={<QuestionMark />}
              >
                Tutorial
              </SoftButton>
            </Stack>
          )}
        </SoftBox>
      </SidenavRoot>

      <Dialog
        sx={styles.dialog}
        open={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
      >
        <DialogTitle>
          <SoftBox px={2} pt={2}>
            <H3w color="primary">
              Invite Team Members
            </H3w>
          </SoftBox>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Stack spacing={2}>
            <SoftBox>
              <SoftInput
                sx={styles.inviteInput}
                placeholder="Enter team member's email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                error={!!inviteError}
                type="email"
                icon={{
                  component: "email",
                  direction: "left"
                }}
              />
              {inviteError && (
                <SoftTypography sx={styles.errorText}>
                  {inviteError}
                </SoftTypography>
              )}
            </SoftBox>

            <SoftButton
              variant="gradient"
              color="primary"
              onClick={handleInvite}
              disabled={isInviting || !emailInput}
              fullWidth
            >
              {isInviting ? 'Sending Invitation...' : 'Send Invitation'}
            </SoftButton>

            {inviteSuccess && (
              <SoftBox
                bgcolor="success.light"
                p={2}
                borderRadius={2}
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Icon sx={{ color: 'success.main' }}>check_circle</Icon>
                <SoftTypography variant="button" color="success">
                  Invitation sent successfully!
                </SoftTypography>
              </SoftBox>
            )}

            {teamMembersNew.length > 0 && (
              <TeamMemberList
                teamMembers={teamMembers}
                company={company}
                onMemberRemove={handleRemoveTeamMember}
                onInvitationRevoke={handleRevokeInvitation}
                styles={styles.teamList}
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
