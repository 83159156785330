import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NorthIcon from '@mui/icons-material/North';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Box, Collapse, Fade, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { styled, useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SoftButton from '../../components/SoftButton';
import SoftInput from '../../components/SoftInput';
import SoftTypography from '../../components/SoftTypography';
import useLocales from '../../hooks/useLocales';
import { callOpenAI } from './callopenai';
import { useDispatch, useSelector } from 'react-redux';
import { updateJourney, getCompanyJourneys } from '../../redux/slices/site';
import { updateCompany } from '../../redux/slices/company';
import axios from 'axios';
import { getCompanySites } from '../../redux/slices/site';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme, hasMessages }) => ({
  position: 'fixed',
  bottom: 90, // Position above the FAB
  right: 24,
  zIndex: 1001, // Higher than FAB buttons
  width: hasMessages ? '600px' : '400px', // Expand width when there are messages
  height: '600px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
  background: theme.palette.mode === 'dark' ?
    'linear-gradient(180deg, #000000 0%, #1a1a1a 100%)' :
    'linear-gradient(180deg, #F0EEEB 0%,rgba(240, 238, 235, 0.95) 100%)',
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
  transform: 'translateY(0)',
}));

const ChatHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  backgroundColor: '#5E064F',
  color: 'white',
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  display: 'flex',
  flexDirection: isUser ? 'row-reverse' : 'row',
  alignItems: 'flex-start',
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  width: '100%',
  '& > .MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: isUser ? 'flex-end' : 'flex-start',
    maxWidth: '85%'
  }
}));

const MessageContent = styled(Box)(({ theme, isUser }) => ({
  maxWidth: '85%',
  padding: theme.spacing(2),
  borderRadius: '16px',
  background: isUser ?
    'linear-gradient(135deg, #DE63A1 0%, #d44d91 100%)' : // User gradient
    'linear-gradient(135deg, #5E064F 0%, #4a0340 100%)',  // AI gradient
  color: '#ffffff',
  wordBreak: 'break-word',
  boxShadow: `0 2px 8px ${isUser ? 'rgba(222, 99, 161, 0.2)' : 'rgba(94, 6, 79, 0.2)'}`,
  '& .MuiTypography-root': {
    color: 'rgba(255, 255, 255, 0.95)',
    fontSize: '0.9rem',
    lineHeight: 1.6,
    letterSpacing: '0.01em'
  }
}));

const MessageHeader = styled(Box)(({ isUser }) => ({
  marginBottom: '8px',
  fontSize: '0.9rem',
  color: '#3c3c3c',
  fontWeight: '500',
  textAlign: isUser ? 'right' : 'left'
}));

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
  width: '100%'
});

const TypingIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
  '& .dot': {
    width: '6px',
    height: '6px',
    backgroundColor: '#5E064F',
    borderRadius: '50%',
    opacity: 0.8
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column-reverse',
  height: 'calc(100% - 130px)', // Adjust for header and input container
}));

const InputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
}));

const CONVERSATION_STARTERS = [
  "What is a good Work Model for my company?",
  "How do I determine if the current office is fit for purpose?",
  "How should we reason when deciding where to locate our office?",
];

export default function AiAgentChat({ open, onClose }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const theme = useTheme();
  const { translate } = useLocales();
  const [autoScroll, setAutoScroll] = useState(true);
  const [isGeneratingMessage, setIsGeneratingMessage] = useState(false);
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);
  const { journey, site } = useSelector((state) => state.site);

  useEffect(() => {
    if (chatContainerRef.current && autoScroll) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, autoScroll]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 50;
    setAutoScroll(isAtBottom);
  };

  async function main(message) {
    setIsGeneratingMessage(true);
    try {
      const completion = await callOpenAI(message);
      console.log('completion', completion);
      setMessages(prev => [...prev, { 
        text: completion.response, 
        path: completion.path, 
        questiontype: completion.questiontype,
        suggestions: completion.suggestions || [],
        isUser: false 
      }]);
    } catch (error) {
      console.error('Error generating recommendations:', error);
      setMessages(prev => [...prev, { text: "I'm sorry, I encountered an error processing your request. Please try again later.", isUser: false }]);
    } finally {
      setIsGeneratingMessage(false);
    }
  }

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    setAutoScroll(true);
    const userMessage = input.trim();
    setMessages(prev => [...prev, { text: userMessage, isUser: true }]);
    setInput('');
    setIsLoading(true);

    try {
      main(userMessage);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearChat = () => {
    setMessages([]);
  };

  const handleApproval = (approved, suggestions, messageIndex) => {
    console.log('handleApproval', approved, suggestions, messageIndex);
    if (!approved) {
      const actionText = "Changes rejected";
      const newMessage = {
        text: actionText,
        isUser: false,
        isActionResponse: true
      };
      setMessages(prev => {
        const newMessages = [...prev];
        newMessages.splice(messageIndex + 1, 0, newMessage);
        return newMessages;
      });
      return;
    }

    // Process each suggestion
    suggestions.forEach(categoryObj => {
      const [category, items] = Object.entries(categoryObj)[0];
      items.forEach(async (item) => {
        const [objectType, field] = item.object.split('.');
        const params = { [field]: item.value };
        
        setIsLoading(true);
        try {
          if (objectType === 'journey') {
            await dispatch(updateJourney(journey.id, params));
            dispatch(getCompanyJourneys(company.id));
          } else if (objectType === 'company') {
            await dispatch(updateCompany(company.id, params));
          } else if (objectType === 'site') {
            // Handle site updates through the API directly
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/sites/update/${site.id}`, params);
            // Refresh sites after update
            dispatch(getCompanySites(company));
          }
          
          const actionText = `Changes applied: ${item.label} set to ${item.value}`;
          const newMessage = {
            text: actionText,
            isUser: false,
            isActionResponse: true
          };
          setMessages(prev => {
            const newMessages = [...prev];
            newMessages.splice(messageIndex + 1, 0, newMessage);
            return newMessages;
          });
        } catch (error) {
          console.error('Error applying changes:', error);
          const errorMessage = {
            text: `Error applying changes: ${error.message}`,
            isUser: false,
            isActionResponse: true
          };
          setMessages(prev => {
            const newMessages = [...prev];
            newMessages.splice(messageIndex + 1, 0, errorMessage);
            return newMessages;
          });
        } finally {
          setIsLoading(false);
        }
      });
    });
  };

  const renderMessage = (message, index) => (
    <MessageBubble key={index} isUser={message.isUser}>
      <Avatar
        sx={{
          width: 28,
          height: 28,
          bgcolor: message.isUser ? 'rgba(222, 99, 161, 0.2)' : 'transparent'
        }}
      >
        {message.isUser ? (
          <PersonIcon sx={{ color: '#DE63A1', fontSize: '1rem' }} />
        ) : (
          <Box
            component="img"
            src="/static/lohow.png"
            alt="Workplacer AI"
            sx={{ width: '100%', height: '100%' }}
          />
        )}
      </Avatar>

      <Box sx={{ maxWidth: '100%', width: '100%' }}>
        <MessageHeader isUser={message.isUser}>
          {message.isUser ? 'You' : 'Workplacer AI'}
        </MessageHeader>
        <MessageContent isUser={message.isUser}>
          {/* For action response messages (approval/rejection confirmations) */}
          {message.isActionResponse ? (
            <SoftTypography 
              variant="body2" 
              sx={{ 
                fontStyle: 'italic',
                color: message.text.includes('approved') ? 'rgba(0, 255, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)'
              }}
            >
              {message.text}
            </SoftTypography>
          ) : (
            <>
              {/* Show text response for all message types */}
              {(message.isUser || message.text) && (
                <SoftTypography variant="body2">
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </SoftTypography>
              )}

              {/* For type 2 and 3 and question type - show suggestions */}
              {!message.isUser && (message.questiontype === "populate" || message.questiontype === "combination" || message.questiontype === "question") && message.suggestions && message.suggestions.length > 0 && (
                <Box sx={{ mt: message.text ? 2 : 0 }}>
                  <SoftTypography
                    variant="subtitle2"
                    sx={{
                      color: 'rgba(255, 255, 255, 0.9)',
                      mb: 1,
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    {message.questiontype === "populate" ? '💡 Proposed Changes' : 
                     message.questiontype === "question" ? '🎯 Available Options' :
                     '💡 Related Information'}
                  </SoftTypography>

                  {/* Show approval buttons only for type 2 */}
                  {message.questiontype === "populate" && (
                    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                      <SoftButton
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => handleApproval(true, message.suggestions, index)}
                        sx={{
                          backgroundColor: 'rgba(0, 255, 0, 0.2)',
                          '&:hover': { backgroundColor: 'rgba(0, 255, 0, 0.3)' }
                        }}
                      >
                        Approve Changes
                      </SoftButton>
                      <SoftButton
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleApproval(false, message.suggestions, index)}
                        sx={{
                          backgroundColor: 'rgba(255, 0, 0, 0.2)',
                          '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.3)' }
                        }}
                      >
                        Reject Changes
                      </SoftButton>
                    </Stack>
                  )}

                  <Stack spacing={2}>
                    {message.suggestions.map((categoryObj, idx) => {
                      const [category, items] = Object.entries(categoryObj)[0];
                      return (
                        <Box key={idx}>
                          <SoftTypography
                            variant="caption"
                            sx={{
                              color: 'rgba(255, 255, 255, 0.7)',
                              mb: 1,
                              display: 'block',
                              textTransform: 'capitalize'
                            }}
                          >
                            {category.replace(/_/g, ' ')}
                          </SoftTypography>
                          <Stack spacing={1}>
                            {items.map((item, itemIdx) => (
                              <SoftButton
                                key={`${idx}-${itemIdx}`}
                                variant="outlined"
                                color="white"
                                size="small"
                                onClick={() => {
                                  // Add click handler for both combination and question types
                                  if (message.questiontype === "combination" || message.questiontype === "question") {
                                    const query = `Tell me more about ${item.label}`;
                                    setMessages(prev => [...prev, { text: query, isUser: true }]);
                                    main(query);
                                  }
                                }}
                                sx={{
                                  borderColor: 'rgba(255, 255, 255, 0.3)',
                                  color: 'white',
                                  justifyContent: 'space-between',
                                  textAlign: 'left',
                                  p: 1,
                                  cursor: message.questiontype === "populate" ? 'default' : 'pointer',
                                  '&:hover': message.questiontype === "populate" ? {} : {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    borderColor: 'rgba(255, 255, 255, 0.5)',
                                  }
                                }}
                              >
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                  <SoftTypography variant="caption" sx={{ fontWeight: 600 }}>
                                    {item.label}
                                  </SoftTypography>
                                  {item.path && (
                                    <SoftTypography variant="caption" sx={{ fontSize: '0.7rem', color: 'rgba(255, 255, 255, 0.7)' }}>
                                      {item.path}
                                    </SoftTypography>
                                  )}
                                </Box>
                                {item.value && message.questiontype !== "question" && (
                                  <SoftTypography 
                                    variant="caption" 
                                    sx={{ 
                                      ml: 2,
                                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                      padding: '2px 8px',
                                      borderRadius: '12px',
                                      fontSize: '0.7rem'
                                    }}
                                  >
                                    {item.value}
                                  </SoftTypography>
                                )}
                              </SoftButton>
                            ))}
                          </Stack>
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>
              )}
            </>
          )}
        </MessageContent>
      </Box>
    </MessageBubble>
  );

  return (
    <Fade in={open} timeout={300}>
      <RootStyle hasMessages={messages.length > 0}>
        <ChatHeader>
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: 'transparent'
              }}
            >
              <Box
                component="img"
                src="/static/lohow.png"
                alt="Workplacer AI"
                sx={{ width: '100%', height: '100%' }}
              />
            </Avatar>
            <SoftTypography variant="h6" color="white">
              Workplacer AI Assistant
            </SoftTypography>
          </Stack>
          <SoftButton
            variant="text"
            color="white"
            onClick={onClose}
            sx={{ minWidth: 'auto', p: 0.5 }}
          >
            <CloseIcon />
          </SoftButton>
        </ChatHeader>

        <Box sx={{ 
          p: 1.5, 
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(255, 255, 255, 0.05)'
        }}>
          <SoftTypography 
            variant="caption" 
            sx={{ 
              fontStyle: 'italic',
              color: 'rgba(0, 0, 0, 0.6)',
              display: 'block',
              textAlign: 'center',
              fontSize: '0.75rem',
              lineHeight: 1.4
            }}
          >
            You are now interacting with an AI agent. Responses are to be seen as supportive and you are still responsible for deciding what is best for your organization.
          </SoftTypography>
        </Box>

        <ChatContainer ref={chatContainerRef} onScroll={handleScroll}>
          <Stack spacing={2}>
            {messages.map((message, index) => renderMessage(message, index))}
            {isGeneratingMessage && (
              <MessageBubble isUser={false}>
                <Avatar
                  sx={{
                    width: 28,
                    height: 28,
                    bgcolor: 'transparent'
                  }}
                >
                  <Box
                    component="img"
                    src="/static/lohow.png"
                    alt="Workplacer AI"
                    sx={{ width: '100%', height: '100%' }}
                  />
                </Avatar>
                <Box>
                  <MessageHeader isUser={false}>
                    Workplacer AI
                  </MessageHeader>
                  <TypingIndicator>
                    {[0, 1, 2].map((i) => (
                      <motion.div
                        key={i}
                        className="dot"
                        animate={{
                          y: ['0%', '-50%', '0%'],
                        }}
                        transition={{
                          duration: 0.8,
                          repeat: Infinity,
                          delay: i * 0.2,
                          ease: "easeInOut"
                        }}
                      />
                    ))}
                  </TypingIndicator>
                </Box>
              </MessageBubble>
            )}
            {messages.length === 0 && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <SoftTypography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Hi there! How can I help you today?
                </SoftTypography>
                <Stack spacing={1}>
                  {CONVERSATION_STARTERS.map((starter, index) => (
                    <SoftButton
                      key={index}
                      variant="outlined"
                      color="primary"
                      size="small"
                      sx={{
                        whiteSpace: 'normal',
                        lineHeight: 1.2,
                        p: 1,
                        textAlign: 'left',
                        justifyContent: 'flex-start'
                      }}
                      onClick={() => {
                        setMessages(prev => [...prev, { text: starter, isUser: true }]);
                        main(starter);
                      }}
                    >
                      {starter}
                    </SoftButton>
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
          {isGeneratingMessage && (
            <LoadingContainer>
              <motion.div
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <Box
                  component="img"
                  src="/static/lohow.png"
                  alt="Loading..."
                  sx={{ width: 30, height: 30 }}
                />
              </motion.div>
            </LoadingContainer>
          )}
        </ChatContainer>

        <InputContainer>
          <Stack direction="row" spacing={1}>
            <SoftInput
              placeholder="Ask Workplacer anything..."
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              disabled={isLoading}
              size="small"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '& .MuiInputBase-input': {
                  fontSize: '0.9rem',
                },
              }}
            />
            <SoftButton
              variant="gradient"
              color="primary"
              onClick={handleSendMessage}
              disabled={isLoading}
              sx={{
                minWidth: '40px',
                height: '40px',
                p: 0,
                borderRadius: '8px'
              }}
            >
              <NorthIcon fontSize="small" />
            </SoftButton>
            {messages.length > 0 && (
              <SoftButton
                variant="outlined"
                color="error"
                onClick={handleClearChat}
                sx={{
                  minWidth: '40px',
                  height: '40px',
                  p: 0,
                  borderRadius: '8px'
                }}
              >
                <CloseIcon fontSize="small" />
              </SoftButton>
            )}
          </Stack>
        </InputContainer>
      </RootStyle>
    </Fade>
  );
} 