import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import PasswordChange from './PasswordChange';

export default function PasswordChangeWrapper({ children }) {
  const { user } = useAuth();
  const [showPasswordChange, setShowPasswordChange] = useState(false);

  useEffect(() => {
    if (user?.requiresPasswordChange) {
      setShowPasswordChange(true);
    }
  }, [user?.requiresPasswordChange]);

  const handlePasswordChangeComplete = () => {
    setShowPasswordChange(false);
  };

  if (showPasswordChange) {
    return <PasswordChange onComplete={handlePasswordChangeComplete} />;
  }

  return children;
} 