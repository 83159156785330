// hooks
import useAuth from '../hooks/useAuth';
//
import createAvatar from '../utils/createAvatar';

import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const theme = useTheme();

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.displayName}
      color="primary"
      {...other}
    >
      {user?.displayName && theme?.palette?.primary?.main && 
        createAvatar(user.displayName, theme.palette.primary.main).name}
    </Avatar>
  );
}
